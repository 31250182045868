<template>
  <div class="w-90 pt-9 min-vh-100">
    <div
      class="w-100 notifications-cont pt-4 pb-11 flex-column d-flex justify-content-center align-items-center"
    >
      <div
        class="d-flex w-100 justify-content-center border-bottom align-items-center"
      >
        <div
          class="d-flex w-90 justify-content-between flex-lg-row flex-column align-items-center"
        >
          <div class="text-center text-lg-left">
            <h1 class="font-24">
              {{ $t("adminNotificationsAllNotifications") }}
            </h1>
            <p class="fs-18 text-muted font-weight-light">
              {{ $t("adminNotificationsNewOrders") }}
            </p>
          </div>
          <div
            class="d-flex width-25 justify-content-between align-items-center"
          >
            <button 
              :class="!read ? 'notifications-btn btn-admin-top text-white' : 'btn-admin-top border btn'"
              @click="statusHandler(false)">
              {{ $t("adminNotificationsUnRead") }}
            </button>
            <button 
              :class="read? 'notifications-btn btn-admin-top text-white' : 'btn-admin-top border btn'" 
              @click="statusHandler(true)">
              {{ $t("adminNotificationsRead") }}
            </button>
          </div>
        </div>
      </div>
      <Circle2 v-if="isWaiting" class="mt-5"></Circle2>
      <button v-else
        class="w-100 notification-hover admin-notification-unread border-bottom py-15 d-flex justify-content-center align-items-center"
        v-for="(notify, index) in notifies" :key="index" @click="showModal(index, notify.id)"
      >
        <div
          class="d-flex justify-content-between w-90 flex-column flex-sm-row align-items-center"
        >
          <div class="w-90">
            <!-- <h1 class="fs-18 font-weight-normal">{{notify.subject}}</h1> -->
            <div
              class="width-25 d-flex justify-content-between align-items-center"
            >
              <p class="mb-0 font-weight-lighter">1 minute ago</p>
              <button @click="showModal(index, notify.id)"><p class="text-primary mb-0">
                {{ $t("adminNotificationsViewOrder") }}
              </p></button>
            </div>
          </div>
          <div v-if="!read">
            <button class="admin-dismiss-btn text-white mt-4 mt-lg-0">
              {{ $t("adminNotificationsDismiss") }}
            </button>
          </div>
        </div>
      </button>
    </div>
    <b-modal
      ref="notificationModal"
      centered
      hide-footer
      content-class="content-class"
      header-class="header-class"
      hide-header
      size="lg"
      hide-header-close
    >
      <div v-if="detail != null">
        <p style="font-weight: 600;">{{detail.subject}}</p>
        <div class="modal-orders">
          <p>{{detail.description}}</p>
          <div
            class="d-flex justify-content-end align-items-center pt-9 pt-lg-5"
          >
            <div
              class="d-flex justify-content-around align-items-center w-xl-35"
            >
              <button
                class="order-color-c7 btn outline-none border rounded-pill modal-order-btn"
                @click="hideModal"
              >
                {{ $t("cancel") }}
              </button>                
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import AdminPopUp from "../admin/popUp";
import debounce from 'debounce'
import {Circle2} from 'vue-loading-spinner'
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: "AdminNotifications",
  data(){
    return {
    };
  },
  components:{
      Circle2
  },
  beforeCreate(){
    if(localStorage.getItem('adminInfo') == null){
      this.$router.replace({ path: "/" }).catch(() => {});
    }
  },
  computed:{
    ...mapGetters('notify', ['notifies', "detail", "read", "isWaiting"]),
    ...mapGetters(["newNotify"]),
    // ...mapGetters("notification", ["notification", ]),

  },
  methods: {
    ...mapActions('notify', ['getAllNotify', "getonenotificationData", "postdismissData"]),
    ...mapMutations("notify", ["setRead"]),
    ...mapMutations(["setNewNotify"]),

    handleClose() {
    },
    statusHandler(state){
      this.setRead(state);
      this.getAllNotify();
    },
    hideModal() {
      this.$refs.notificationModal.hide()
    },
    showModal(index,id) {
      this.getonenotificationData(id).then(() => {
        this.$refs.notificationModal.show();
        if(!this.read) this.dismiss(id);
      });
    },
    dismiss(id) {
      this.postdismissData(id).then(() => {
        var t = this.newNotify - 1;
        this.setNewNotify(t >= 0 ? t : 0 );
        this.getAllNotify();
      });
    },
  },
  mounted(){
    localStorage.removeItem('quickFilterType');
    localStorage.removeItem('quickFilterText');
    this.statusHandler(false);
  }
};
</script>
<style scoped>
.admin-dismiss-btn {
  background: #f12b2c;
  border-radius: 8px;
  width: 74px;
  height: 24px;
}
.admin-notification-unread {
  border-left: 4px solid #245aab;
}
.btn-admin-top {
  height: 38px !important;
  width: 124px;
}
.notifications-btn {
  background: #29cc97;
  border: 1px solid #29cc97;
  border-radius: 5px;
}
.notifications-cont {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
}
.notification-hover:hover{
  background: #dedede;
}
</style>