import http from '@/api/http';

export function addNewRole(param){
    return http.post('/admin/addNewRole', param);
}

export function getPermissionDetails(){
    return http.get('/admin/getPermissionDetails');
}

export function selectRole(param){
    return http.post('/admin/selectRole', param);
}

export function duplicateRole(param){
    return http.post('/admin/duplicateRole', param);
}

export function deleteRole(param){
    return http.post('/admin/deleteRole', param);
}

export function addEmployeeToRole(param) {
    return http.post('/admin/addEmployeeToRole', param);
}

export function deleteEmployeeFromRole(param) {
    return http.post('/admin/deleteEmployeeFromRole', param);
}

export function updateRolevalue(param) {
    return http.post('/admin/updateRolevalue', param);
}