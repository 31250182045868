<template>
  <div v-if="alert === 420" class=" d-flex mt-4 mt-lg-0 align-items-center mb-3 width-100">
    <svg
      width="33"
      height="29"
      viewBox="0 0 33 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.1172 24.6172L18.9922 1.8125C18.0078 0.117188 15.4375 0.0625 14.4531 1.8125L1.32812 24.6172C0.34375 26.3125 1.60156 28.5 3.625 28.5H29.8203C31.8438 28.5 33.1016 26.3672 32.1172 24.6172ZM16.75 19.8594C18.1172 19.8594 19.2656 21.0078 19.2656 22.375C19.2656 23.7969 18.1172 24.8906 16.75 24.8906C15.3281 24.8906 14.2344 23.7969 14.2344 22.375C14.2344 21.0078 15.3281 19.8594 16.75 19.8594ZM14.3438 10.8359C14.2891 10.4531 14.6172 10.125 15 10.125H18.4453C18.8281 10.125 19.1562 10.4531 19.1016 10.8359L18.7188 18.2734C18.6641 18.6562 18.3906 18.875 18.0625 18.875H15.3828C15.0547 18.875 14.7812 18.6562 14.7266 18.2734L14.3438 10.8359Z"
        fill="#FF3A67"
      />
    </svg>

    <div class="ml-4">
      <p class="mb-0">{{ $t("invalidEmail") }}</p>
      <p class="font-weight-lighter mb-0">{{ $t("contactKeerki") }}</p>
    </div>
  </div>
  <div
    v-else-if="alert === 421"
    class="d-flex mt-4 mt-lg-0 align-items-center mb-3"
  >
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.9375C6.50781 0.9375 0.4375 7.00781 0.4375 14.5C0.4375 21.9922 6.50781 28.0625 14 28.0625C21.4922 28.0625 27.5625 21.9922 27.5625 14.5C27.5625 7.00781 21.4922 0.9375 14 0.9375ZM19.0312 18.0547L17.9375 19.4219C17.7734 19.6406 17.5 19.8047 17.2266 19.8047C17.0625 19.8047 16.8438 19.6953 16.7344 19.5859L13.0703 16.8516C12.5781 16.5234 12.25 15.7578 12.25 15.1562V6.625C12.25 6.1875 12.6328 5.75 13.125 5.75H14.875C15.3125 5.75 15.75 6.1875 15.75 6.625V14.5L18.9219 16.8516C19.0859 17.0156 19.25 17.2891 19.25 17.5078C19.25 17.7266 19.1406 17.9453 19.0312 18.0547Z"
        fill="#F8A72D"
      />
    </svg>

    <div class="ml-4">
      <p class="mb-0">
        {{ $t("sessionExpire") }}
      </p>
    </div>
  </div>
  <div
    v-else-if="alert === 422"
    class="d-flex mt-4 mt-lg-0 align-items-center mb-3"
  >
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.9375C6.50781 0.9375 0.4375 7.0625 0.4375 14.5C0.4375 21.9922 6.50781 28.0625 14 28.0625C21.4375 28.0625 27.5625 21.9922 27.5625 14.5C27.5625 7.0625 21.4375 0.9375 14 0.9375ZM14 6.95312C15.2578 6.95312 16.2969 7.99219 16.2969 9.25C16.2969 10.5625 15.2578 11.5469 14 11.5469C12.6875 11.5469 11.7031 10.5625 11.7031 9.25C11.7031 7.99219 12.6875 6.95312 14 6.95312ZM17.0625 20.8438C17.0625 21.2266 16.7344 21.5 16.4062 21.5H11.5938C11.2109 21.5 10.9375 21.2266 10.9375 20.8438V19.5312C10.9375 19.2031 11.2109 18.875 11.5938 18.875H12.25V15.375H11.5938C11.2109 15.375 10.9375 15.1016 10.9375 14.7188V13.4062C10.9375 13.0781 11.2109 12.75 11.5938 12.75H15.0938C15.4219 12.75 15.75 13.0781 15.75 13.4062V18.875H16.4062C16.7344 18.875 17.0625 19.2031 17.0625 19.5312V20.8438Z"
        fill="#0074BD"
      />
    </svg>

    <div class="ml-4">
      <p class="mb-0">
        {{ $t("loginToOtherAccount") }}
      </p>
    </div>
  </div>
  <div
    v-else-if="alert === 423"
    class="d-flex mt-4 mt-lg-0 align-items-center mb-3"
  >
    <svg
      width="33"
      height="29"
      viewBox="0 0 33 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.1172 24.6172L18.9922 1.8125C18.0078 0.117188 15.4375 0.0625 14.4531 1.8125L1.32812 24.6172C0.34375 26.3125 1.60156 28.5 3.625 28.5H29.8203C31.8438 28.5 33.1016 26.3672 32.1172 24.6172ZM16.75 19.8594C18.1172 19.8594 19.2656 21.0078 19.2656 22.375C19.2656 23.7969 18.1172 24.8906 16.75 24.8906C15.3281 24.8906 14.2344 23.7969 14.2344 22.375C14.2344 21.0078 15.3281 19.8594 16.75 19.8594ZM14.3438 10.8359C14.2891 10.4531 14.6172 10.125 15 10.125H18.4453C18.8281 10.125 19.1562 10.4531 19.1016 10.8359L18.7188 18.2734C18.6641 18.6562 18.3906 18.875 18.0625 18.875H15.3828C15.0547 18.875 14.7812 18.6562 14.7266 18.2734L14.3438 10.8359Z"
        fill="#F8A72D"
      />
    </svg>

    <div class="ml-4">
      <p class="mb-0">
        {{ $t("technicalDifficulty") }}
      </p>
    </div>
  </div>
  <div
    v-else-if="alert === 424"
    class="d-flex mt-4 mt-lg-0 align-items-center mb-3"
  >
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5625 14.5C27.5625 7.0625 21.4375 0.9375 14 0.9375C6.50781 0.9375 0.4375 7.0625 0.4375 14.5C0.4375 21.9922 6.50781 28.0625 14 28.0625C21.4375 28.0625 27.5625 21.9922 27.5625 14.5ZM12.4141 21.7188C12.0859 22.0469 11.4844 22.0469 11.1562 21.7188L5.46875 16.0312C5.14062 15.7031 5.14062 15.1016 5.46875 14.7734L6.72656 13.5703C7.05469 13.1875 7.60156 13.1875 7.92969 13.5703L11.8125 17.3984L20.0156 9.19531C20.3438 8.8125 20.8906 8.8125 21.2188 9.19531L22.4766 10.3984C22.8047 10.7266 22.8047 11.3281 22.4766 11.6562L12.4141 21.7188Z"
        fill="#29A87C"
      />
    </svg>

    <div class="ml-4">
      <p class="mb-0">{{ $t("logoutSuccessful") }}</p>
    </div>
  </div>
  <div
    v-else-if="alert === 426"
    class="d-flex mt-4 mt-lg-0 align-items-center mb-3"
  >
    <svg
      width="33"
      height="29"
      viewBox="0 0 33 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.1172 24.6172L18.9922 1.8125C18.0078 0.117188 15.4375 0.0625 14.4531 1.8125L1.32812 24.6172C0.34375 26.3125 1.60156 28.5 3.625 28.5H29.8203C31.8438 28.5 33.1016 26.3672 32.1172 24.6172ZM16.75 19.8594C18.1172 19.8594 19.2656 21.0078 19.2656 22.375C19.2656 23.7969 18.1172 24.8906 16.75 24.8906C15.3281 24.8906 14.2344 23.7969 14.2344 22.375C14.2344 21.0078 15.3281 19.8594 16.75 19.8594ZM14.3438 10.8359C14.2891 10.4531 14.6172 10.125 15 10.125H18.4453C18.8281 10.125 19.1562 10.4531 19.1016 10.8359L18.7188 18.2734C18.6641 18.6562 18.3906 18.875 18.0625 18.875H15.3828C15.0547 18.875 14.7812 18.6562 14.7266 18.2734L14.3438 10.8359Z"
        fill="#FF3A67"
      />
    </svg>

    <div class="ml-4">
      <p class="mb-0">{{ $t("accountDeactivated") }}</p>
      <p class="font-weight-lighter mb-0">{{ $t("contactKeerki") }}</p>
    </div>
  </div>
  <div
    v-else-if="alert === 427"
    class="d-flex mt-4 mt-lg-0 align-items-center mb-3"
  >
    <svg
      width="33"
      height="29"
      viewBox="0 0 33 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.1172 24.6172L18.9922 1.8125C18.0078 0.117188 15.4375 0.0625 14.4531 1.8125L1.32812 24.6172C0.34375 26.3125 1.60156 28.5 3.625 28.5H29.8203C31.8438 28.5 33.1016 26.3672 32.1172 24.6172ZM16.75 19.8594C18.1172 19.8594 19.2656 21.0078 19.2656 22.375C19.2656 23.7969 18.1172 24.8906 16.75 24.8906C15.3281 24.8906 14.2344 23.7969 14.2344 22.375C14.2344 21.0078 15.3281 19.8594 16.75 19.8594ZM14.3438 10.8359C14.2891 10.4531 14.6172 10.125 15 10.125H18.4453C18.8281 10.125 19.1562 10.4531 19.1016 10.8359L18.7188 18.2734C18.6641 18.6562 18.3906 18.875 18.0625 18.875H15.3828C15.0547 18.875 14.7812 18.6562 14.7266 18.2734L14.3438 10.8359Z"
        fill="#FF3A67"
      />
    </svg>

    <div class="ml-4">
      <p class="mb-0">{{ $t("accountSuspended") }}</p>
      <p class="font-weight-lighter mb-0">{{ $t("contactKeerki") }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminCustomAlert",
  props: {
    alert: {
      type: Number,
      default: 1,
    },

  },
};
</script>