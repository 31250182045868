<template>
  <Circle2 v-if="isWaiting" class="mt-8"></Circle2>
  <div v-else
    class="w-90 d-flex justify-content-center align-items-center flex-column pt-5"
    dir="auto"
  >
    <div
      class="w-100 client-details-card pb-1 d-flex flex-column justify-content-center align-items-center"
    >
      <div
        class="w-100 d-flex justify-content-center flex-column align-items-center"
      >
        <table class="table table-borderless table-responsive-sm">
          <tbody>
            <tr class="border-bottom">
              <td class="border-right p-lg-4">
                <div class="font-weight-bold">
                  <span class="text-muted font-weight-light">{{
                    $t("clientDetailsName")
                  }}</span>
                  <input
                    :value="selected.contactperson"
                    id="edit_contactperson"
                    class="outline-none px-2 font-weight-bold"
                    type="text"
                    :disabled="isDisabled"
                  />
                </div>
              </td>
              <td class="p-lg-4">
                <div class="font-weight-bold">
                  <span class="font-weight-light text-muted">{{
                    $t("clientDetailsAddress")
                  }}</span>
                  <input
                    :value="selected.street"
                    id="edit_street"
                    class="outline-none px-2 font-weight-bold"
                    type="text"
                    :disabled="isDisabled"
                  />
                </div>
              </td>
            </tr>
            <tr class="border-bottom">
              <td class="border-right p-lg-4">
                <div class="font-weight-bold">
                  <span class="font-weight-light text-muted">{{
                    $t("clientDetailsCompanyName")
                  }}</span>
                  <input
                    :value="selected.companyname"
                    id="edit_companyname"
                    class="outline-none px-2 font-weight-bold"
                    type="text"
                    :disabled="isDisabled"
                  />
                </div>
              </td>
              <td class="p-lg-4">
                <div class="font-weight-bold">
                  <span class="font-weight-light text-muted">{{
                    $t("clientDetailsEmail")
                  }}</span>
                  <input
                    :value="selected.email"
                    id="edit_email"
                    class="outline-none px-2 font-weight-bold"
                    type="email"
                    :disabled="isDisabled"
                  />
                </div>
              </td>
            </tr>
            <tr class="border-bottom">
              <td class="border-right p-lg-4">
                <div class="font-weight-bold">
                  <span class="text-muted font-weight-light">{{
                    $t("clientDetailsPhone")
                  }}</span>
                  <input
                    :value="selected.phone"
                    id="edit_phone"
                    class="outline-none px-2 font-weight-bold"
                    type="number"
                    :disabled="isDisabled"
                  />
                </div>
              </td>
              <td class="p-lg-4">
                <div class="font-weight-bold">
                  <span class="font-weight-light text-muted">{{
                    $t("clientDetailsWhatsapp")
                  }}</span>
                  <input
                    :value="selected.whatsapp"
                    id="edit_whatsapp"
                    class="outline-none px-2 font-weight-bold"
                    type="number"
                    :disabled="isDisabled"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-90 d-flex align-items-center my-4">
        <b-modal
          id="reset-modal"
          centered
          hide-footer
          header-class="header-class"
          hide-header
          hide-header-close
        >
          <div
            class="d-flex flex-column w-100 justify-content-center align-items-center p-3"
          >
            <div class="w-100">
              <h1 class="fs-16">{{ $t("updateData") }}</h1>
            </div>
            <div class="d-flex justify-content-end w-100 mt-3">
              <button
                class="rounded-lg btn modal-logout bg-white color-c7 outline-none"
                @click="resetCompanyInfo"
              >
                {{ $t("companyInfoReset") }}
              </button>
              <button
                class="rounded-lg btn btn-primary outline-none ml-2"
                @click="saveCompanyInfo"
              >
                {{ $t("companySave") }}
              </button>
            </div>
          </div>
        </b-modal>
        <button
          v-if="isDisabled"
          class="btn p-0 mb-0 outline-none d-flex align-items-center justify-content-around"
          @click="editHandler"
        >
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 4H3C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V20C1 20.5304 1.21071 21.0391 1.58579 21.4142C1.96086 21.7893 2.46957 22 3 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V13"
              stroke="#FFB800"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.5 2.49998C17.8978 2.10216 18.4374 1.87866 19 1.87866C19.5626 1.87866 20.1022 2.10216 20.5 2.49998C20.8978 2.89781 21.1213 3.43737 21.1213 3.99998C21.1213 4.56259 20.8978 5.10216 20.5 5.49998L11 15L7 16L8 12L17.5 2.49998Z"
              stroke="#FFB800"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="mb-0 w-100">{{ $t("adminOrdersDetailsEdit") }}</p>
        </button>
        <button
          v-else
          class="btn btn-primary mb-0 outline-none d-flex align-items-center justify-content-around"
          @click="saveHandler"
        >
          {{ $t("companySave") }}
        </button>
      </div>
    </div>
    <div
      class="d-flex justify-content-center w-100 flex-column align-items-center mt-5"
    >
      <div
        class="w-90 d-flex justify-content-between flex-column flex-lg-row py-4"
      >
        <div class="mt-5 w-25">
          <div class="width-50">
            <p class="border-bottom mb-15 fs-22 text-muted">
              {{ $t("adminOrdersDetailsStatus") }}
            </p>
            <CustomAlert
              :alert="200"
              :type="`${$t(this.selected.label)}`"
              :color="this.selected.color"
            />
          </div>
        </div>
        <div
          class="width-50 flex-column d-flex justify-content-center align-items-center pt-5 pt-lg-0"
        >
          <p class="font-24">
            <span class="font-weight-light">{{
              $t("adminOrdersDetailsOrderNumber")
            }}</span>
            <u>{{selected.ordernumber}}</u>
          </p>
          <div
            class="file-cont w-100 d-flex justify-content-center align-items-center"
          >
            <div v-if="!selected || !selected.ordernumber" style="width: 100%; height: 600px"></div>
            <img  v-else :src="thumbUrl + selected.ordernumber  + '.png?time=' + Date()" style="width: 100%; height: 600px">
          </div>
          <div
            class="w-100 d-flex justify-content-around pt-4 align-items-center"
          >
            <div class="d-flex">
              <button class="outline-none fs-14 d-flex align-items-center"
                @click="onOrderEdit"
              >
                <p class="mb-0 text-muted">{{ $t("adminOrdersDetailsEdit") }}</p>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  class="mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16663 3.33336H3.33329C2.89127 3.33336 2.46734 3.50895 2.15478 3.82151C1.84222 4.13407 1.66663 4.558 1.66663 5.00003V16.6667C1.66663 17.1087 1.84222 17.5326 2.15478 17.8452C2.46734 18.1578 2.89127 18.3334 3.33329 18.3334H15C15.442 18.3334 15.8659 18.1578 16.1785 17.8452C16.491 17.5326 16.6666 17.1087 16.6666 16.6667V10.8334"
                    stroke="#678AAA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.4166 2.08333C15.7481 1.75181 16.1978 1.56557 16.6666 1.56557C17.1355 1.56557 17.5851 1.75181 17.9166 2.08333C18.2481 2.41485 18.4344 2.86449 18.4344 3.33333C18.4344 3.80218 18.2481 4.25181 17.9166 4.58333L9.99996 12.5L6.66663 13.3333L7.49996 10L15.4166 2.08333Z"
                    stroke="#678AAA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="d-flex">
              <button class="outline-none fs-14 d-flex align-items-center"
                @click="download(selected.ordernumber, selected.type)"
              >
                <p class="mb-0 text-muted">
                  {{ $t("adminOrdersDetailsDownload") }}
                </p>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  class="mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
                    stroke="#678AAA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.83337 8.33336L10 12.5L14.1667 8.33336"
                    stroke="#678AAA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 12.5V2.5"
                    stroke="#678AAA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="d-flex align-items-center">
              <button class="outline-none fs-14 d-flex align-items-center"
                @click="pdfView(selected.ordernumber, selected.type)"
              >
                <p class="mb-0 text-muted">{{ $t("adminOrdersDetailsView") }}</p>
                <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                class="mx-2"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.833313 8.00003C0.833313 8.00003 4.16665 1.33336 9.99998 1.33336C15.8333 1.33336 19.1666 8.00003 19.1666 8.00003C19.1666 8.00003 15.8333 14.6667 9.99998 14.6667C4.16665 14.6667 0.833313 8.00003 0.833313 8.00003Z"
                  stroke="#678AAA"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z"
                  stroke="#678AAA"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-5 w-xl-25">
          <div class="w-100 d-flex justify-content-end">
            <div
              class="border-bottom mb-15 fs-22 text-muted d-flex align-items-center pb-2"
            >
              <button class="outline-none">
                <svg
                  width="6"
                  height="23"
                  viewBox="0 0 6 23"
                  fill="none"
                  class="mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.83333 5.66667C4.39167 5.66667 5.66667 4.39167 5.66667 2.83333C5.66667 1.275 4.39167 0 2.83333 0C1.275 0 0 1.275 0 2.83333C0 4.39167 1.275 5.66667 2.83333 5.66667ZM2.83333 8.5C1.275 8.5 0 9.775 0 11.3333C0 12.8917 1.275 14.1667 2.83333 14.1667C4.39167 14.1667 5.66667 12.8917 5.66667 11.3333C5.66667 9.775 4.39167 8.5 2.83333 8.5ZM2.83333 17C1.275 17 0 18.275 0 19.8333C0 21.3917 1.275 22.6667 2.83333 22.6667C4.39167 22.6667 5.66667 21.3917 5.66667 19.8333C5.66667 18.275 4.39167 17 2.83333 17Z"
                    fill="#C5C7CD"
                  />
                </svg>
              </button>
              <p class="mb-0">{{ $t("adminOrdersDetailsTags") }}</p>
            </div>
          </div>
          <div class="w-100 px-2 d-flex justify-content-end align-item-center">
            <div
              class="bg-white px-4 w-100 rounded-lg border py-2 d-flex justify-content-between"
            >
              <div v-if="selected.tags != null" class="d-flex flex-wrap">
                <button
                  v-for="(item, index) in selected.tags"
                  :key="index"
                  class="tags-btn px-2 mx-1 outline-none mt-2"
                >
                  {{ item }}
                  <button
                    class="px-1 text-muted opacity-50 outline-none"
                    @click="deleteTag(index)"
                  >
                    X
                  </button>
                </button>
                <input
                  class="outline-none mt-2 px-2 w-50"
                  placeholder="Add new tag"
                  @keypress="tagsInputHandler"
                />
              </div>
              <div v-else>
                <p class="mb-0">Categories</p>
              </div>
              <button class="outline-none" @click="addData">
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L6 6.5L11 1.5"
                    stroke="#A0A4A8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end">
            <div
              class="border-bottom mt-15 fs-22 text-muted d-flex align-items-center pb-2"
            >
              <p class="mb-0">{{ $t("adminOrdersDetailsRating") }}</p>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end">
            <b-form-rating
              id="rating-lg-no-border"
              color="#FFB800"
              v-model="ratingValue"
              @change="OnChangeRating"
              no-border
              size="lg"
            ></b-form-rating>
          </div>
        </div>
      </div>
    </div>
    <div
      class="buttons-cont d-flex justify-content-center py-5 align-items-center flex-row flex-xl-column w-75-resp mt-5"
    >
      <div
        class="w-90 d-flex flex-column flex-xl-row justify-content-around align-items-center"
      >
        <button class="btn box-buttons button-color2C text-white rounded-pill" @click="onClickReceived">
          {{ $t("adminOrdersDetailsRecived") }}
        </button>
        <b-modal
          id="transfer-modal"
          centered
          hide-footer
          header-class="header-class2"
          no-stacking
          dir="auto"
        >
          <div class="w-100">
            <div dir="auto">
              <p class="d-none">{{ $t("clientsSearchFor") }}</p>
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                class="position-absolute mt-20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8906 13.5742L10.582 10.2656C10.5 10.2109 10.418 10.1562 10.3359 10.1562H9.98047C10.8281 9.17188 11.375 7.85938 11.375 6.4375C11.375 3.32031 8.80469 0.75 5.6875 0.75C2.54297 0.75 0 3.32031 0 6.4375C0 9.58203 2.54297 12.125 5.6875 12.125C7.10938 12.125 8.39453 11.6055 9.40625 10.7578V11.1133C9.40625 11.1953 9.43359 11.2773 9.48828 11.3594L12.7969 14.668C12.9336 14.8047 13.1523 14.8047 13.2617 14.668L13.8906 14.0391C14.0273 13.9297 14.0273 13.7109 13.8906 13.5742ZM5.6875 10.8125C3.25391 10.8125 1.3125 8.87109 1.3125 6.4375C1.3125 4.03125 3.25391 2.0625 5.6875 2.0625C8.09375 2.0625 10.0625 4.03125 10.0625 6.4375C10.0625 8.87109 8.09375 10.8125 5.6875 10.8125Z"
                  fill="#747474"
                />
              </svg>

              <input
                class="w-100 pt-4 pb-2 px-15 border-bottom outline-none"
                :placeholder="`${$t('clientsSearchFor')}`"
                v-model="searchKey"
                @input="employeeSearch()"
              />
            </div>
            <div class="overflow-scroll h-300 mt-3">
              <button v-for="item in employeeList" :key="item.id"
                class="hover-btn d-flex justify-content-around w-100 align-items-center"
                @click="onClickTransfer(item.id)"
              >
                <div class="p-1 rounded-circle border">
                  <img
                    :src="avatarUrl + item.profile_photo_path"
                    class="rounded-circle"
                    width="50"
                    height="50"
                  />
                </div>
                <div class="mx-2">
                  <p class="mb-0">{{item ? item.name : ''}} (Employee)</p>
                  <p
                    class="mb-0 font-weight-light d-flex justify-content-center align-items-center w-75 text-white button-color2A4"
                  >
                    {{item ? item.role : ''}}
                  </p>
                </div>
             </button>
            </div>
          </div>
        </b-modal>
        <button
          class="btn box-buttons button-color2C mt-4 mt-xl-0 text-white rounded-pill"
          @click="onOpenTransferModal"
        >
          {{ $t("adminOrdersDetailsTransfer") }}
        </button>
        <button
          class="btn box-buttons button-color2C mt-4 mt-xl-0 text-white rounded-pill"
          @click="onClickPayNow"
        >
          {{ $t("adminOrdersDetailsPayNow") }}
        </button>
        <b-modal
          id="question-modal"
          centered
          hide-footer
          content-class="content-class"
          header-class="header-class"
          hide-header
          size="lg"
          hide-header-close
        >
          <div dir="auto">
            <p>
              {{ $t("adminOrdersDetailsYourEnquiry") }}
            </p>
            <div class="modal-orders p-2">
              <input
                class="bg-transparent w-100 outline-none"
                :placeholder="`${$t('adminOrdersDetailsWriteYourQuestion')}`"
              />
              <div
                class="d-flex justify-content-end align-items-center pt-9 pt-lg-5"
              >
                <div
                  class="d-flex justify-content-around align-items-center w-xl-35"
                >
                  <button
                    class="order-color-c7 btn outline-none border rounded-pill modal-order-btn"
                    @click="$bvModal.hide('question-modal')"
                  >
                    {{ $t("adminOrdersDetailsIgnore") }}
                  </button>

                  <button
                    class="btn-primary btn outline-none border rounded-pill modal-order-btn"
                    @click="onClickQuestion"
                  >
                    {{ $t("adminOrdersDetailsSubmit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <button
          class="btn box-buttons button-color2C mt-4 mt-xl-0 text-white rounded-pill"
          @click="onOpenQuestionModal"
        >
          {{ $t("adminOrdersDetailsQuestion") }}
        </button>
        <button
          class="btn box-buttons button-color29 mt-4 mt-xl-0 text-white rounded-pill"
          @click="onClickDone"
        >
          {{ $t("adminOrdersDetailsDone") }}
        </button>
      </div>
      <div
        class="w-90 d-flex flex-column pt-xl-5 flex-xl-row justify-content-around align-items-center"
      >
        <b-modal
          id="sms-modal"
          centered
          hide-footer
          content-class="content-class"
          header-class="header-class"
          hide-header
          size="lg"
          hide-header-close
        >
          <div dir="auto">
            <p>
              {{ $t("adminOrdersDetailsReasonForCancelling") }}
              <span class="text-muted">{{
                $t("adminOrdersDetailsMustProvideReason")
              }}</span>
            </p>
            <div class="modal-orders p-2">
              <input
                class="bg-transparent w-100 outline-none"
                :placeholder="`${$t('adminOrdersDetailsWriteYourText')}`"
                id="smsMessage"
              />
              <div
                class="d-flex justify-content-end align-items-center pt-9 pt-lg-5"
              >
                <div
                  class="d-flex justify-content-around align-items-center w-xl-35"
                >
                  <button
                    class="order-color-c7 btn outline-none border rounded-pill modal-order-btn"
                    @click="$bvModal.hide('sms-modal')"
                  >
                    {{ $t("adminOrdersDetailsIgnore") }}
                  </button>
                  <button
                    class="btn-primary btn outline-none border rounded-pill modal-order-btn"
                    @click="onClickSendSMS"
                  >
                    {{ $t("adminOrdersDetailsSubmit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          id="cancel-modal"
          centered
          hide-footer
          content-class="content-class"
          header-class="header-class"
          hide-header
          size="lg"
          hide-header-close
        >
          <div dir="auto">
            <p>
              {{ $t("adminOrdersDetailsReasonForCancelling") }}
              <span class="text-muted">{{
                $t("adminOrdersDetailsMustProvideReason")
              }}</span>
            </p>
            <div class="modal-orders p-2">
              <input
                class="bg-transparent w-100 outline-none"
                :placeholder="`${$t('adminOrdersDetailsWriteYourText')}`"
                id="cancelMessage"
              />
              <div
                class="d-flex justify-content-end align-items-center pt-9 pt-lg-5"
              >
                <div
                  class="d-flex justify-content-around align-items-center w-xl-35"
                >
                  <button
                    class="order-color-c7 btn outline-none border rounded-pill modal-order-btn"
                    @click="$bvModal.hide('cancel-modal')"
                  >
                    {{ $t("adminOrdersDetailsIgnore") }}
                  </button>

                  <button
                    class="btn-primary btn outline-none border rounded-pill modal-order-btn"
                    @click="onClickCancel"
                    
                  >
                    {{ $t("adminOrdersDetailsSubmit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <button
          class="btn box-buttons button-colorf1 text-white rounded-pill"
          @click="onOpenCancelModal"
        >
          {{ $t("adminOrdersDetailsCancel") }}
        </button>
        <button
          class="btn box-buttons button-color2C mt-4 mt-xl-0 text-white rounded-pill"
          @click="onOpenSMSModal"
        >
          {{ $t("adminOrdersDetailsSendSms") }}
        </button>
        <button
          class="btn box-buttons button-color6f mt-4 mt-xl-0 text-white rounded-pill"
          @click="onClickWaiting"
        >
          {{ $t("adminOrdersDetailsWaiting") }}
        </button>
        <button
          class="btn box-buttons button-color2C mt-4 mt-xl-0 text-white rounded-pill"
          @click="onClickShipped"
        >
          {{ $t("adminOrdersDetailsShipped") }}
        </button>
        <button
          class="btn box-buttons button-colorfe mt-4 mt-xl-0 text-white rounded-pill"
          @click="onClickInprogress"
        >
          {{ $t("adminOrdersDetailsInprogress") }}
        </button>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-end align-items-center mt-11 mb-5">
      <button
        class="pop-over-message outline-none rounded-circle d-flex justify-content-center align-items-center"
        id="popover-order-message"
      >
        <b-popover
          target="popover-order-message"
          triggers="hover"
          placement="top"
        >
          <p class="w-100 font-weight-bold">
            {{ $t("adminOrdersDetailsInregardWithThisOrder")
            }}<u class="text-primary">{{selected.ordernumber}}</u>
          </p>
          <p>
            {{ $t("adminOrdersDetailsAnyIssue") }}
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <button class="btn rounded-pill btn-transparent border">
              {{ $t("adminOrdersDetailsCancel") }}
            </button>
            <button
              class="btn rounded-pill button-color2C text-white border ml-0"
              v-b-modal.send-message-modal
            >
              {{ $t("adminOrdersDetailsSendAMessage") }}
            </button>
          </div>
        </b-popover>
        <b-modal
          id="send-message-modal"
          centered
          hide-footer
          content-class="content-class"
          header-class="header-class"
          hide-header
          size="lg"
          hide-header-close
        >
          <div>
            <p>{{ $t("adminOrdersEnquiry") }}</p>
            <div class="modal-orders p-2">
              <input
                id="quickMessage"
                class="bg-transparent w-100 outline-none"
                :placeholder="`${$t('adminOrdersDetailsWriteQuestion')}`"
              />
              <div
                class="d-flex justify-content-end align-items-center pt-9 pt-lg-5"
              >
                <div
                  class="d-flex justify-content-around align-items-center w-xl-35"
                >
                  <button
                    class="order-color-c7 btn outline-none border rounded-pill modal-order-btn"
                    @click="$bvModal.hide('send-message-modal')"
                  >
                    {{ $t("adminOrdersDetailsCancel") }}
                  </button>

                  <button
                    class="btn-primary btn outline-none border rounded-pill modal-order-btn"
                    @click="sendQuickMessage"
                  >
                    {{ $t("adminOrdersDetailsSubmit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 0C8.73239 0 0 8.25363 0 19.4007C0 25.2317 2.39034 30.27 6.28169 33.7509C6.60765 34.0447 6.80483 34.4511 6.82093 34.8898L6.92958 38.4472C6.9658 39.582 8.13682 40.3184 9.17505 39.8637L13.1429 38.1132C13.4809 37.9643 13.8551 37.9361 14.2093 38.0327C16.0322 38.5357 17.9759 38.8013 20 38.8013C31.2676 38.8013 40 30.5477 40 19.4007C40 8.25363 31.2676 0 20 0Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="20"
              y1="0"
              x2="20"
              y2="40.0005"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2C5282" />
              <stop offset="1" stop-color="#2C5282" />
            </linearGradient>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import CustomAlert from "../customAlert";
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {Circle2} from 'vue-loading-spinner';

export default {
  name: "AdminOrdersDetailed",
  components: {
    CustomAlert,
    Circle2
  },

  mounted(){
    this.getOrderDetails(localStorage.selected).then(()=>{
      if(!this.selected) {
        this.$router.push({ path: "/dashboard/orders" }).catch(() => {});
        Vue.$toast.error('Can\'t read data!', {})
      }
      this.employeeList = this.employees;
    });
  },
  computed:{
    ...mapGetters('order', ['selected', 'orders', 'isWaiting', 'isOrderWaiting', 'error', "employees"]),
  },
  methods: {
    ...mapActions('order', ['getOrderDetails', 'updateTags', 'updateRating', 'getDownloadPDF', 'orderSMS', 'changeStatus']),
    ...mapActions("message", ["contactClient"]),
    ...mapMutations('order', ['setSelected']),
    editHandler(){
      this.isDisabled = false;
    },
    resetCompanyInfo(){
      this.$bvModal.hide('reset-modal');
      this.isDisabled = true;
      localStorage.editState = 0;
    },
    saveCompanyInfo(){
      if(localStorage.getItem('copyFlag') === null || localStorage.getItem('copyFlag') == 0){
        this.$store.dispatch('order/updateOrderCompanyInfo',{
          id: localStorage.selected,
          email: document.getElementById('edit_email').value,
          contactperson: document.getElementById('edit_contactperson').value,
          street: document.getElementById('edit_street').value,
          companyname: document.getElementById('edit_companyname').value,
          phone: document.getElementById('edit_phone').value,
          whatsapp: document.getElementById('edit_whatsapp').value
        }).then(()=>{
          if(this.$store.state.error.validations === 200){
            this.$bvModal.hide('reset-modal');
            this.isDisabled = true;
            localStorage.editState = 0;
            this.$router.push({ path: "/dashboard/orders" }).catch(() => {});
          }
        });
      }else if(localStorage.getItem('copyFlag') == 1){
        this.$store.dispatch('order/copyOrderCompanyInfo',{
          id: localStorage.selected,
          email: document.getElementById('edit_email').value,
          contactperson: document.getElementById('edit_contactperson').value,
          street: document.getElementById('edit_street').value,
          companyname: document.getElementById('edit_companyname').value,
          phone: document.getElementById('edit_phone').value,
          whatsapp: document.getElementById('edit_whatsapp').value,
        }).then(()=>{
          if(this.$store.state.error.validations === 200){
            this.$bvModal.hide('reset-modal');
            this.isDisabled = true;
            localStorage.editState = 0;
            this.$router.push({ path: "/dashboard/orders" }).catch(() => {});
          }
        });
      }
    },
    saveHandler() {
      this.$bvModal.show('reset-modal')
    },
    tagsInputHandler(e) {
      this.id++;
      if (e.keyCode === 13) {
        if(e.target.value.length == 0) return;
        if(this.selected.tags.length > 1){
          Vue.$toast.warning("You can input only 2 tags in maximum.");
          e.target.value = "";
          return;
        }

        let d = this.selected;
        d.tags.push(e.target.value);
        this.setSelected(d);
        this.updateTags({id: this.selected.id, tags: d.tags.join([','])});
        e.target.value = "";
      }
    },
    addData() {
      if (this.selected.tags.length == 0) {
        let d = this.selected;
        d.tags = [];
        this.setSelected(d);
      }
    },
    deleteTag(index) {
      this.selected.tags.splice(index, 1);
      this.updateTags({id: this.selected.id, tags: this.selected.tags.join([','])});
    },
    OnChangeRating(e){
      this.updateRating({id: this.selected.id, rating: this.ratingValue});
    },
    download(ordernumber, type){
      if(type == 1){
        this.data.ordernumber = ordernumber;
        this.data.type = 'quote';
        this.getDownloadPDF(this.data).then(() => {
        });
      }else if(type == 2){
        this.data.ordernumber = ordernumber;
        this.data.type = 'service';
        this.getDownloadPDF(this.data).then(() => {
        });
      }else if(type == 3){
        this.data.ordernumber = ordernumber;
        this.data.type = 'ship';
        this.getDownloadPDF(this.data).then(() => {
        });
      }
    },

    pdfView(ordernumber, type){   
      if(type == 1){
        window.open(process.env.VUE_APP_ASSETS+'pdf/'+ordernumber+'.pdf', '_blank');
      }else if(type == 2){
        window.open(process.env.VUE_APP_ASSETS+'pdf/'+ordernumber+'.pdf', '_blank');
      }else if(type == 3){
        window.open(process.env.VUE_APP_ASSETS+'pdf/'+ordernumber+'.pdf', '_blank');
      }
    },

    onOrderEdit(e){
      localStorage.setItem('ordernumber', this.selected.ordernumber);
      localStorage.setItem('ordertype', this.selected.type);
      this.changeStatus({id:this.selected.id, status:3}).then(()=>{
        let tp = this.selected;
        tp.status_name = "IN PROGRESS";
        tp.status = 3;
        this.setSelected(tp);
      });
      if(this.selected.type == 1){
        this.$router.push({ path: "/dashboard/quote-order-edit" }).catch(() => {});
      }else if(this.selected.type == 2){
        this.$router.push({ path: "/dashboard/service-order-edit" }).catch(() => {});
      }else if(this.selected.type == 3){
        this.$router.push({ path: "/dashboard/shipping-order-edit" }).catch(() => {});
      }
    },
    onClickReceived(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      if(this.selected.status == 1 ){
        Vue.$toast.warning('This order is cancelled.', {})
        return;
      }
      if(this.selected.status != 4){
        Vue.$toast.warning('This button is diabled in current step!', {})
        return;
      }
      var message = ``;      
      if(localStorage.currentLanguage == 'en'){       
        message = `【述迪国际】 Hello `+this.selected.name+`,
Payment has been received successfully. Request Number `+this.selected.ordernumber+`
We thank you for your trust in us.`
      } else if(localStorage.currentLanguage == 'ch') {        
        message = `【述迪国际】 你好`+this.selected.name+`，
付款已成功收到。 请求编号`+this.selected.ordernumber+`
感谢您对我们的信任。`
      } else if(localStorage.currentLanguage == 'ar') {           
        message = `【述迪国际】 مرحبا `+this.selected.name+` ،
لقد تم استلام المبلغ بنجاح. طلب رقم `+this.selected.ordernumber+`
نشكرك على ثقتك بنا.`
      }
      this.orderSMS({
        'id':this.selected.userid,
        'type':"Received",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : message
      })
      Vue.$toast.success('Successed!', {})
    },
    onClickPayNow(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      if(this.selected.status == 1 ){
        Vue.$toast.warning('This order is cancelled.', {})
        return;
      }
      if(this.selected.status != 3){
        Vue.$toast.warning('This button is diabled in current step!', {})
        return;
      }
      var message = ``;      
      if(localStorage.currentLanguage == 'en'){       
        message = `【述迪国际】 Hello `+this.selected.name+`,
A quotation number `+this.selected.ordernumber+` has been issued, and you can view it on the Kerky website now, and in the absence of any notes, you can pay directly based on the information at the bottom of the quotation page.
Thank you for your cooperation`
      } else if(localStorage.currentLanguage == 'ch') {        
        message = `【述迪国际】 你好`+this.selected.name+`，
发出了报价单`+this.selected.ordernumber+`，您现在可以在Kerky网站上查看它，并且在没有任何注释的情况下，您可以根据报价页面底部的信息直接付款。
谢谢您的合作`
      } else if(localStorage.currentLanguage == 'ar') {           
        message = `【述迪国际】 مرحبا `+this.selected.name+`،
لقد تم صدور عرض سعر رقم `+this.selected.ordernumber+` يمكنكم الاطلاع عليه على موقع كيركي الان، وفي حال عدم وجود أي ملاحظات يمكنكم الدفع مباشرة بناءا على المعلومات الموجودة في اسفل  صفحة عرض السعر.
شاكرين لكم حسن تعاونكم `
      }
      this.orderSMS({
        'id':this.selected.id,
        'type': "PayNow",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : message
      })
      Vue.$toast.success('Successed!', {
      })
    },
    onClickDone(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      if(this.selected.status == 1 ){
        Vue.$toast.warning('This order is cancelled.', {})
        return;
      }
      if(this.selected.status != 6 ){
        Vue.$toast.warning('This button is diabled in current step!', {})
        return;
      }
      // this.orderDone();
      var param = {"id":this.selected.id, 'userid':this.selected.userid};
      this.orderDone(param);
      Vue.$toast.success('Successed!', {
        // optional options Object
      });
    },
    onOpenSMSModal(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      this.$bvModal.show("sms-modal");
    },
    onClickSendSMS(e){
      var message = document.getElementById('smsMessage').value;      
      if(localStorage.currentLanguage == 'en'){       
        message = `【述迪国际】 Hello `+this.selected.name+`,
This message is from an employee of China-Saudi Corporation
  `+message
      } else if(localStorage.currentLanguage == 'ch') {        
        message = `【述迪国际】 你好`+this.selected.name+`，
该消息来自中国舒迪公司的一名员工
`+message
      } else if(localStorage.currentLanguage == 'ar') {           
        message = `【述迪国际】 مرحبا `+this.selected.name+`،
هذه الرسالة من احد موظفي شركة شودي_ الصين
 `+message
      }
      this.orderSMS({
        'id':this.selected.id,
        'ordernumber':this.selected.ordernumber,
        'type': "sendSMS",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : message
      })
      this.$bvModal.hide("sms-modal");
      Vue.$toast.success('Successed!', {
      })
    },
    onOpenCancelModal(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      this.$bvModal.show("cancel-modal");
    },
    onClickCancel(e){
      var message = document.getElementById('cancelMessage').value;      
      if(localStorage.currentLanguage == 'en'){       
        message = `【述迪国际】Hello `+this.selected.name+`, We inform you that your order number `+this.selected.ordernumber+` has been canceled Due to: `+message
      } else if(localStorage.currentLanguage == 'ch') {        
        message = `【述迪国际】 你好`+this.selected.name+`，
我们通知您，您的订单号`+this.selected.ordernumber+`已被取消
由于：`+message
      } else if(localStorage.currentLanguage == 'ar') {           
        message = `【述迪国际】 مرحبا `+this.selected.name+` ،
نفيدكم علما بأن طلبكم رقم `+this.selected.ordernumber+` تم الغاءه
بسبب:  `+message        
      }      
      this.orderSMS({
        'id':this.selected.userid,
        'ordernumber':this.selected.ordernumber,
        'type':"Cancelled",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : message
      }).then(()=>{
        this.$bvModal.hide("cancel-modal");
        Vue.$toast.success('Successed!', {});
      });
    },
    onClickWaiting(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      if(this.selected.status == 1 ){
        Vue.$toast.warning('This order is cancelled.', {})
        return;
      }
      if(this.selected.status != 3){
        Vue.$toast.warning('This button is diabled in current step!', {})
        return;
      }
      var message = ``;
      if(localStorage.currentLanguage == 'en'){       
        message = `【述迪国际】 Hello `+this.selected.name+`,
Your order number `+this.selected.ordernumber+` is about to be shipped, please confirm the following data to be approved:
1- The full name of the recipient of the goods in English
2- The commercial registry number or the identity number
3- The importer's number for the institution or company
4- The recipient's full address in English
5- The place of receiving the goods
6- The value of the goods on the invoice
`+JSON.parse(localStorage.getItem('adminInfo')).email
      } else if(localStorage.currentLanguage == 'ch') {        
        message = `【述迪国际】 你好`+this.selected.name+`，
您的订单号`+this.selected.ordernumber+`即将发货，请确认以下数据待批准：
1-英文收货人的全名
2-商业注册号或身份证号
3-机构或公司的进口商编号
4-收件人的完整英文地址
5-收货地点
6-发票上的货物价值
请将此信息与申请号一起快速发送至电子邮件。
`+JSON.parse(localStorage.getItem('adminInfo')).email
      } else if(localStorage.currentLanguage == 'ar') {           
        message = `【述迪国际】 مرحبا `+this.selected.name+` ،
طلبكم رقم `+this.selected.ordernumber+` على وشك الشحن، يرجى من سعادتكم التأكد من البيانات التالية حتى يتم اعتمادها:
1-اسم مستلم البضاعة كاملا بالانجليزي
2-رقم السجل التجاري او رقم الهوية 
3-رقم المستورد للمؤسسة او والشركة
4-عنوان المستلم كاملا بالانجليزي
5-مكان استلام البضاعة
6-قيمة البضاعة في الفاتورة
نرجو المسارعة بارسال هذه البيانات على الايميل مرفقا معها رقم الطلب.
`+JSON.parse(localStorage.getItem('adminInfo')).email        
      }
      // console.log(this.selected.userid);
      // return;
      this.orderSMS({
        'id':this.selected.userid,
        'ordernumber':this.selected.ordernumber,
        'type': "Waiting",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : message
      }).then(()=>{
        Vue.$toast.success('Successed!', {});
      });
    },
    onClickShipped(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      if(this.selected.status == 1 ){
        Vue.$toast.warning('This order is cancelled.', {})
        return;
      }
      if(this.selected.status == 1 ){
        Vue.$toast.warning('This order is cancelled.', {})
        return;
      }
      if(this.selected.status != 5){
        Vue.$toast.warning('This button is diabled in current step!', {})
        return;
      }
      var message = ``;
      if(localStorage.currentLanguage == 'en'){       
        message = `【述迪国际】 Hi `+this.selected.name+` ,
Your order number `+this.selected.name+` has been shipped. For more inquiries and obtaining the required documents, please contact us via e-mail. Thank you
`+JSON.parse(localStorage.getItem('adminInfo')).email
      } else if(localStorage.currentLanguage == 'ch') {        
        message = `【述迪国际】 你好`+this.selected.name+` ，
您的订单号`+this.selected.ordernumber+`已寄出，有关更多查询和获取所需文件的信息，请通过电子邮件与我们联系。 谢谢
`+JSON.parse(localStorage.getItem('adminInfo')).email
      } else if(localStorage.currentLanguage == 'ar') {           
        message = `【述迪国际】 مرحبا `+this.selected.name+`،
تم شحن طلبكم رقم `+this.selected.ordernumber+` لمزيد من الاستفسارات والحصول على المستندات المطلوبة يرجى التواصل معنا عبر البريد الاليكتروني. وشكرا
`+JSON.parse(localStorage.getItem('adminInfo')).email
      }      
      this.orderSMS({
        'id':this.selected.userid,
        'ordernumber':this.selected.ordernumber,
        'type':"Shipped",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : message
      }).then(()=>{
        Vue.$toast.success('Successed!', {});
      });
    },
    onClickInprogress(e){
      if(this.selected.tags == null || this.selected.tags.length == 0){
        Vue.$toast.warning('Tags empty!', {})
        return;
      }
      if(this.selected.status == 1 ){
        Vue.$toast.warning('This order is cancelled.', {})
        return;
      }
      if(this.selected.status != 3){
        Vue.$toast.warning('This button is diabled in current step!', {})
        return;
      }
      this.orderSMS({
        'id':this.selected.userid,
        'ordernumber':this.selected.ordernumber,
        'type': "InProgress",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : "Click  Inprogress"
      }).then(()=>{
        Vue.$toast.success('Successed!', {});
      });
    },
    onOpenQuestionModal(e){
      // if(this.selected.tags == null || this.selected.tags.length == 0){
      //   Vue.$toast.warning('Tags empty!', {})
      //   return;
      // }
      this.$bvModal.show("question-modal");
      // if(this.selected.tags == null || this.selected.tags.length == 0){
      //   Vue.$toast.warning('Tags empty!', {})
      //   return;
      // }      
    },
    onClickQuestion(e){
      this.orderSMS({
        'id':this.selected.userid,
        'ordernumber':this.selected.ordernumber,
        'type': "Question",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : "Click  Question"
      }).then(()=>{
        Vue.$toast.success('Successed!', {});
      });
    },
    onOpenTransferModal(e){
      // if(this.selected.tags == null || this.selected.tags.length == 0){
      //   Vue.$toast.warning('Tags empty!', {})
      //   return;
      // }
      // if(this.selected.status == 1 ){
      //   Vue.$toast.warning('This order is cancelled.', {})
      //   return;
      // }
      // if(this.selected.status != 3){
      //   Vue.$toast.warning('This button is diabled in current step!', {})
      //   return;
      // }
      this.$bvModal.show("transfer-modal");
    },
    onClickTransfer(id){
      var message = ``;      
      if(localStorage.currentLanguage == 'en'){       
        message = `【述迪国际】 Hello `+this.selected.name+`,
There is a new order from `+this.selected.country+` that needs a quotation. please check. Thank you`
      } else if(localStorage.currentLanguage == 'ch') {        
        message = `【述迪国际】 你好`+this.selected.name+`,
来自`+this.selected.country+`有新的订单需要报价。请查收。谢谢`
      } else if(localStorage.currentLanguage == 'ar') {           
        message = `【述迪国际】 أهلا بك`+this.selected.name+`,
يوجد طلب جديد من `+this.selected.country+` لعرض أسعار. يرجى المراجعة. شكرا لك`
      }
      this.$bvModal.hide("transfer-modal");
      this.orderSMS({
        'id':id,
        'ordernumber':this.selected.ordernumber,
        'type': "Transfer",
        'phone' : this.selected.countryCode + this.selected.phone,
        'email' : this.selected.email,
        'message' : message
      }).then(()=>{
        if(this.error == 200){
          Vue.$toast.success('Successed!', {});
          this.$router.push({ path: "/dashboard/orders" }).catch(() => {});
        }else return Vue.$toast.error('Failed!', {});
      });
    },

    sendQuickMessage(e){
      var formData = new FormData();
      formData.append('from_id', JSON.parse(localStorage.getItem('adminInfo')).id);
      formData.append('to_id', this.selected.userid);
      formData.append('subject', 'Question for Order-' + this.selected.ordernumber);
      formData.append('ordernumber', this.selected.ordernumber);
      formData.append('message', document.getElementById('quickMessage').value);

      this.contactClient(formData).then(()=>{
        this.$bvModal.hide('send-message-modal');
        Vue.$toast.success('Chat for Order-' + this.selected.ordernumber + "  is created!", {})
      });
    },

    employeeSearch(){
      var tp = [];
      for(var i = 0; i < this.employees.length; i ++){
        var name = this.employees[i].name.toLowerCase(); 
        if(name.includes(this.searchKey.toLowerCase)) tp.push(this.employees[i]);
      }
      this.employeeList = tp;
    }
  },
  data() {
    return {
      ratingValue: 0,
      id: 3,
      isDisabled: localStorage.editState == 0? true : false,
      tags: [],
      data: {
        ordernumber: '',
        type: ''
      },
      thumbUrl: process.env.VUE_APP_ASSETS + "thumb/",
      avatarUrl: process.env.VUE_APP_ASSETS + 'avatars/',
      employeeList: [],
      searchKey : ''
    };
  },
};
</script>

<style>
.w-xl-35 {
  width: 100%;
}
.order-color-c7 {
  background: #c7c9ca;
}
.modal-order-btn {
  min-width: 85px !important;
}
.modal-orders {
  min-height: 100px;

  background: #f2f3f7;
  border-radius: 6px;
}
.pop-over-message {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  width: 62px;
  height: 62px;
}
.w-xl-25 {
  width: 100%;
}

.button-colorfe {
  background: #fec400 !important;
}
.button-color6f {
  background: #6fa6f6 !important;
}
.box-buttons {
  min-width: 144px !important;
  height: 45px !important;
}
.button-color29 {
  background: #29cc97 !important;
}
.button-color2C {
  background: #2c5282 !important;
}
.button-color2A4 {
  background: #2a4eaa !important;
  border-radius: 4px;
}
.button-colorf1 {
  background: #f12b2c !important;
}
.buttons-cont {
  min-height: 191px;
  background: #f7fafc;
  border: 2px solid #bee3f8;
  border-radius: 16px;
}
#rating-lg-no-border {
  background: transparent !important;
}
.tags-btn {
  background: #f3f3f3;
  border: 1.5px solid #a2a2a2;
  border-radius: 5px;
  height: 32px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #616161;
}
.file-cont {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  height: 624px;
}
.fs-22 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.4px;
}
.client-details-card {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
}
.w-75-resp {
  width: 100%;
}
@media (max-width: 1200px) and (min-width: 992px) {
  .w-75-resp {
    width: 75%;
  }
  .w-xl-25 {
    width: 25%;
  }
  .w-xl-35 {
    width: 33%;
  }
}
@media (min-width: 1200px) {
  .w-75-resp {
    width: 75%;
  }
  .w-xl-25 {
    width: 25%;
  }
  .w-xl-35 {
    width: 33%;
  }
}

button.hover-btn:hover{
  background-color: #8290A555; 
  border-radius: 7px;
}
</style>