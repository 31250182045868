import http from '@/api/http';

export function login(email, password){
    return http.post('/admin/login', {
        email : email,
        password : password
    });
}

export function getInitData(){
    return http.get('/admin/initData/' + JSON.parse(localStorage.getItem('adminInfo')).id);
}

