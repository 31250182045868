import http from '@/api/http';

export function updateAccount(param){
    return http.post('/admin/updateAccount', param);
}

export function updateAvatar(param){
    return http.post('/admin/updateAvatar', param,
        {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }
    );
}

export function updatePassword(param){
    return http.post('/admin/updatePassword', param);
}

export function updateEmployee(param){
    return http.post('/admin/updateEmployee', param);
}

export function addNewEmployee(param){
    return http.post('/admin/addEmployee', param);
}

export function addNewClient(param){
    return http.post('/admin/addNewClient', param);
}

export function getAllEmployees(){
    return http.get('/admin/employees');
}

export function getAllClients(){
    return http.get('/admin/clients');
}

export function getSettingData(){
    return http.get('/admin/getSettingData');
}

export function activeAccount(param){
    return http.post('/admin/activeAccount', param);
}

export function suspendAccount(param){
    return http.post('/admin/suspendAccount', param);
}

export function sendEmail(param){
    return http.post('/admin/sendEmailToAll', param);
}

export function remindEmailLogin(param){
    return http.post('/admin/remindEmailLogin', param);
}

export function changeSessionTime(param){
    return http.get('/admin/changeSessionTime/' + param);
}
