<template>
  <div class="w-100 d-flex justify-content-center align-items-center">
    <b-form 
      class="w-70 pt-11 pb-11 px-4 d-flex flex-column justify-content-center align-items-center"
      dir="auto"   
      @submit="onSubmit"
    > 
      <button
        class="d-flex pb-5 align-self-start outline-none d-flex align-items-center justify-content-center"
        @click="$router.push('/dashboard/orders-detailed')"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8627 3.225L13.3794 1.75L5.1377 10L13.3877 18.25L14.8627 16.775L8.0877 10L14.8627 3.225Z"
            fill="#8692A6"
          />
        </svg>
        <p class="font-weight-lighter ml-3 mb-0">{{ $t("back") }}</p>
      </button>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 text-right border-3 font-24 py-2"
      >
        {{ $t("companyInfo") }}
      </h1>
      <h1 v-else class="w-100 text-left border-3 font-24 py-2">
        {{ $t("companyInfo") }}
      </h1>
      <input
        type="text"
        v-model="form.companyname"
        class="input-form px-lg-5 px-3 mt-15"
        :placeholder="`${$t('companyInfoName')}`"
        required
      />
      <div class="d-flex flex-lg-row flex-column justify-content-between w-100">
        <input
          type="text"
          v-model="form.country"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoCountry')}`"
          required
        />
        <input
          type="text"
          v-model="form.contactperson"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoContactPerson')}`"
          required
        />
      </div>
      <div class="d-flex flex-lg-row flex-column justify-content-between w-100">
        <input
          type="text"
          v-model="form.city"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoCity')}`"
          required
        />
        <input
          type="text"
          v-model="form.province"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoProvince')}`"
          required
        />
      </div>
      <div class="d-flex flex-lg-row flex-column justify-content-between w-100">
        <input
          type="number"
          v-model="form.postcode"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoPostCode')}`"
          required
        />
        <input
          type="text"
          v-model="form.street"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoDist')}`"
          required
        />
      </div>
      <div class="d-flex flex-lg-row flex-column justify-content-between w-100">
        <input
          type="number"
          v-model="form.phone"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoPhone')}`"
          required
        />
        <input
          type="email"
          v-model="form.email"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('companyInfoEmail')}`"
          required
        />
      </div>
      <div class="w-100" dir="ltr">
        <div @dragover.prevent @drop.prevent>
          <div
            class="d-flex justify-content-center align-items-center mt-3 w-100 form-file-input px-2 flex-column"
            @dragleave="fileDragOut"
            @dragover="fileDragIn"
            @drop="handleFileDrop"
          >
            <div
              class="w-100 file-wrapper d-flex justify-content-center align-items-center flex-column flex-lg-row"
            >
              <input
                type="file"
                name="file-input"
                id="logo"
                @change="handleFileInput"
              />
              <span>
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.87738 23C4.02337 23.0013 2.35136 21.8828 1.64169 20.1665C0.932015 18.4503 1.3246 16.4747 2.63622 15.1617L9.1187 8.66718C9.71831 8.08691 10.6714 8.09521 11.2609 8.68583C11.8503 9.27646 11.8586 10.2315 11.2795 10.8323L4.79699 17.327C4.20459 17.9256 4.20657 18.8914 4.80141 19.4876C5.39625 20.0838 6.36016 20.0861 6.95777 19.4927L15.6012 10.8321C16.461 9.97088 16.9441 8.8026 16.9441 7.58441C16.9441 6.36621 16.461 5.19793 15.6012 4.33669C13.7876 2.60073 10.9323 2.60073 9.1187 4.33669L2.63622 10.8315C2.25383 11.235 1.68312 11.3988 1.14553 11.2595C0.607945 11.1202 0.188084 10.6996 0.0488551 10.161C-0.0903737 9.62238 0.0729435 9.05048 0.47544 8.66718L6.95777 2.17098C9.97972 -0.723659 14.7397 -0.723659 17.7617 2.17098C19.1948 3.60628 20 5.55339 20 7.58372C20 9.61404 19.1948 11.5612 17.7617 12.9965L9.1187 21.6571C8.25928 22.5186 7.09298 23.0018 5.87738 23Z"
                    fill="#047BAE"
                  />
                </svg>
              </span>
              <h1 class="font-24 text-center mb-0 mx-lg-4">
                <span class="text-primary">{{ $t("companyInfoAddLogo") }}</span>
                {{ $t("companyInfoDropFile") }}
              </h1>
            </div>
            <ul class="file-list">
              <li v-for="(file, index) in files" :key="index">
                {{ file.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 border-3 text-right font-24 py-2 mt-15"
      >
        {{ $t("shippingTo") }}
      </h1>
      <h1 v-else class="w-100 border-3 text-left font-24 py-2 mt-15">
        {{ $t("shippingTo") }}
      </h1>

      <div class="d-flex flex-lg-row flex-column justify-content-between w-100">
        <input
          type="text"
          v-model="form.ship_port"
          class="input-form px-3 px-lg-5 mt-15 w-48"
          :placeholder="`${$t('shippingInfoPort')}`"
        />
        <input
          type="text"
          v-model="form.ship_country"
          class="input-form px-3 px-lg-5 mt-15 w-48"
          :placeholder="`${$t('shippingInfoCountry')}`"
        />
      </div>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 border-3 text-right font-24 py-2 mt-15"
      >
        {{ $t("shippingInfo") }}
      </h1>
      <h1 v-else class="w-100 border-3 text-left font-24 py-2 mt-15">
        {{ $t("shippingInfo") }}
      </h1>
      <div
        class="d-flex w-100 justify-content-center align-items-center flex-lg-row flex-column mt-5"
      >
        <b-button
          class="w-100 py-4 border-26"
          type="button"
          :pressed="form.currency == 'SAR' ? true : false"
          variant="outline-primary"
          @click="currencyHandler('SAR')"
        >
          {{ $t("currencySAR") }}
        </b-button>
        <b-button
          :pressed="form.currency == 'AED' ? true : false"
          variant="outline-primary"
          class="w-100 py-4 border-26"
          type="button"
          @click="currencyHandler('AED')"
        >
          {{ $t("currencyAED") }}
        </b-button>

        <b-button
          class="w-100 btn btn-outline-primary py-4 border-26"
          type="button"
          :pressed="form.currency == 'USD' ? true : false"
          variant="outline-primary"
          @click="currencyHandler('USD')"
        >
          {{ $t("currencyUSD") }}
        </b-button>
        <b-button
          class="w-100 btn btn-outline-primary py-4 border-26"
          type="button"
          :pressed="form.currency == 'EURO' ? true : false"
          variant="outline-primary"
          @click="currencyHandler('EURO')"
        >
          {{ $t("currencyEURO") }}
        </b-button>
      </div>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 border-3 text-right font-24 py-2 mt-15"
      >
        {{ $t("aboutProducts") }}
      </h1>
      <h1 v-else class="w-100 border-3 text-left font-24 py-2 mt-15">
        {{ $t("aboutProducts") }}
      </h1>
      <div class="w-100" v-for="(item, index) in form.products" :key="index">
        <div
          class="d-flex flex-lg-row flex-column justify-content-between w-100"
          dir="auto"
        >
          <p class="d-none mb-0 postion-absolute">{{ $t("rtlHelper") }}</p>
          <input
            type="text"
            v-model="item.unit_price"
            class="input-form px-3 px-lg-5 w-32 mt-15"
            :placeholder="`${$t('productUnitPrice')}`"
          />

          <input
            type="text"
            v-model="item.quantity"
            class="input-form px-3 px-lg-5 mt-15 w-32"
            :placeholder="`${$t('productQuantity')}`"
          />

          <input
            type="text"
            v-model="item.item"
            class="input-form px-3 px-lg-5 mt-15 w-32"
            :placeholder="`${$t('productItem')}`"
          />
        </div>
        <textarea
          v-model="item.description"
          class="input-text-area py-5 px-3 px-lg-5 mt-15"
          :placeholder="`${$t('productDescription')}`"
        ></textarea>
        <hr/>
      </div>
      <div class="d-flex align-self-start mt-15 ml-5" dir="ltr">
        <button
          class="bg-danger text-white rounded-button d-flex outline-none justify-content-center align-items-center"
          type="button"
          @click="removeProducts"
        >
          -
        </button>
        <button
          class="bg-primary ml-2 text-white rounded-button outline-none d-flex justify-content-center align-items-center"
          type="button"
          @click="addProducts"
        >
          +
        </button>
      </div>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 border-3 text-right font-24 py-2 mt-15"
      >
        {{ $t("remarks") }}
      </h1>
      <h1 v-else class="w-100 border-3 text-left font-24 py-2 mt-15">
        {{ $t("remarks") }}
      </h1>
      <div class="d-flex flex-lg-row flex-column justify-content-between w-100">
        <input
          type="text"
          v-model="form.remarks_other"
          class="input-form px-3 px-lg-5 mt-3 w-48"
          :placeholder="`${$t('remarksOther')}`"
        />
        <select
          class="form-control input-form px-3 px-lg-5 mt-3 w-48"
          id="tradingTerm"
          v-model="form.remarks_trading_term"

        >
          <option value="" disabled hidden>{{ $t("remarksTradingTerm") }}</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 border-3 text-right font-24 py-2 mt-15"
      >
        {{ $t("paymentTerm") }}
      </h1>
      <h1 v-else class="w-100 border-3 text-left font-24 py-2 mt-15">
        {{ $t("paymentTerm") }}
      </h1>
      <div
        class="d-flex w-100 justify-content-center align-items-center flex-lg-row flex-column mt-4"
        dir="ltr"
      >
        <b-button
          class="w-100 py-4 border-26"
          type="button"
          :pressed="form.payment_term == 'LC' ? true :false "
          variant="outline-primary"
          @click="paymentHandler('LC')"
        >
          {{ $t("paymentTermLC") }}
        </b-button>
        <b-button
          class="w-100 py-4 border-26"
          type="button"
          :pressed="form.payment_term == 'TT' ? true :false "
          variant="outline-primary"
          @click="paymentHandler('TT')"
        >
          {{ $t("paymentTermTT") }}
        </b-button>
      </div>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 border-3 text-right font-24 py-2 mt-15"
      >
        {{ $t("shippingMethod") }}
      </h1>
      <h1 v-else class="w-100 border-3 text-left font-24 py-2 mt-15">
        {{ $t("shippingMethod") }}
      </h1>
      <div
        class="d-flex w-100 justify-content-center align-items-center flex-lg-row flex-column mt-4"
        dir="ltr"
      >
        <b-button
          class="w-100 py-4 border-26"
          type="button"
          :pressed="form.shipping_method == 'AIR' ? true : false"
          variant="outline-primary"
          @click="shippingHandler('AIR')"
        >
          {{ $t("shippingMethodByAir") }}
        </b-button>
        <b-button
          class="w-100 py-4 border-26"
          type="button"
          :pressed="form.shipping_method == 'SEA' ? true : false"
          variant="outline-primary"
          @click="shippingHandler('SEA')"
        >
          {{ $t("shippingMethodBySea") }}
        </b-button>
      </div>
      <h1
        v-if="this.$i18n.locale == 'ar'"
        class="w-100 border-3 text-right font-24 py-2 mt-15"
      >
        {{ $t("deliveryTime") }}
      </h1>
      <h1 v-else class="w-100 border-3 text-left font-24 py-2 mt-15">
        {{ $t("deliveryTime") }}
      </h1>
      <div
        class="d-flex w-100 justify-content-center align-items-center flex-lg-row flex-column mt-4"
        dir="ltr"
      >
        <b-button
          class="w-100 py-4 border-26"
          type="button"
          :pressed="form.delivery_time == '30' ? true : false"
          variant="outline-primary"
          @click="deliveryHandler('30')"
        >
          {{ $t("deliveryTime30Days") }}
        </b-button>
        <b-button
          class="w-100 py-4 border-26"
          :pressed="form.delivery_time == '20' ? true : false"
          variant="outline-primary"
          @click="deliveryHandler('20')"
          type="button"
        >
          {{ $t("deliveryTime20Days") }}
        </b-button>
        <b-button
          class="w-100 py-4 border-26"
          type="button"
          :pressed="form.delivery_time == '10' ? true : false"
          variant="outline-primary"
          @click="deliveryHandler('10')"
        >
          {{ $t("deliveryTime10Days") }}
        </b-button>
      </div>
      <textarea
        v-model="form.others"
        class="input-text-area py-5 px-3 px-lg-5 mt-15 mx-lg-3"
        :placeholder="`${$t('companyInfoOthers')}`"
      ></textarea>
      <div
        class="w-100 d-flex flex-column flex-lg-row justify-content-center mt-5 align-items-center font-weight-bolder"
        dir="ltr"
      >
        <button
          class="btn btn-secondary w-100 text-dark ml-lg-4 mt-4 mt-lg-0 form-buttons"
          type="submit" 
        >
          {{ $t("submit") }}
        </button>
      </div>
    </b-form>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FormCopyBody",
  data() {
    return {
      files: [],
      color: "#444444",
      currentLanguage: localStorage.currentLanguage,
      status: localStorage.getItem('quote') ? true : false,
      form: {
        ordernumber: '',
        companyname: '',
        contactperson: '',
        email: '',
        phone: '',
        postcode: '',
        street: '',
        city: '',
        province: '',
        country: '',
        ship_port: '',
        ship_country: '',
        currency: '',
        products: [{
          item:'',
          unit_price:'',
          quantity:'',
          description:''
        }],
        remarks_other: '',
        remarks_trading_term: '',
        payment_term: '',
        shipping_method: '',
        delivery_time: '',
        others: '',
        userid: ''
      }
    };
  },

  computed: {
    // ...mapGetters(["errors"]),
    ...mapGetters('order', ['selected', 'isWaiting', 'error']),
  },

  mounted(){
    this.getOrderInfo(localStorage.ordernumber).then(()=>{      
      this.form.ordernumber = this.selected.ordernumber ? this.selected.ordernumber : '';
      this.form.companyname = this.selected.companyname ? this.selected.companyname : '';
      this.form.contactperson = this.selected.contactperson ? this.selected.contactperson : '';
      this.form.email = this.selected.email ? this.selected.email : '';
      this.form.phone = this.selected.phone ? this.selected.phone : '';
      this.form.postcode = this.selected.postcode ? this.selected.postcode : '';
      this.form.street = this.selected.street ? this.selected.street : '';
      this.form.city = this.selected.city ? this.selected.city : '';
      this.form.province = this.selected.province ? this.selected.province : '';
      this.form.country = this.selected.country ? this.selected.country : '';
      this.form.ship_port = this.selected.ship_port ? this.selected.ship_port : '';
      this.form.ship_country = this.selected.ship_country ? this.selected.ship_country : '';
      this.form.currency = this.selected.currency  ? this.selected.currency : '';  
      this.form.products = this.selected.products  ? this.selected.products : [{
          item:'',
          unit_price:'',
          quantity:'',
          description:''
        }];  
      this.form.remarks_other = this.selected.remarks_other ? this.selected.remarks_other : '';
      this.form.remarks_trading_term = this.selected.remarks_trading_term ? this.selected.remarks_trading_term : '';
      this.form.payment_term = this.selected.payment_term  ? this.selected.payment_term : '';
      this.form.shipping_method = this.selected.shipping_method  ? this.selected.shipping_method : '';
      this.form.delivery_time = this.selected.delivery_time  ? this.selected.delivery_time : '';
      this.form.others = this.selected.others ? this.selected.others : '' ;
      this.form.userid = this.selected.userid;     
    });
  },

  methods: {
    ...mapActions("order", ["postquoteData", "getOrderInfo", "copyquoteData"]),

    onSubmit(e) {
      e.preventDefault();
      let logoFile = document.getElementById('logo');
      let formData = new FormData();
      formData.append('logo', logoFile.files[0]);
      formData.append('companyname', this.form.companyname ? this.form.companyname : '');
      formData.append('contactperson', this.form.contactperson ? this.form.contactperson : '');
      formData.append('email', this.form.email ? this.form.email : '');
      formData.append('phone', this.form.phone ? this.form.phone : '');
      formData.append('postcode', this.form.postcode ? this.form.postcode : '');
      formData.append('street', this.form.street ? this.form.street : '');
      formData.append('city', this.form.city ? this.form.city : '');
      formData.append('province', this.form.province ? this.form.province : '');
      formData.append('country', this.form.country ? this.form.country : '');
      formData.append('ship_port', this.form.ship_port ? this.form.ship_port : '');
      formData.append('ship_country', this.form.ship_country ? this.form.ship_country : '');
      formData.append('currency', this.form.currency ? this.form.currency : '');      
      formData.append('remarks_other', this.form.remarks_other ? this.form.remarks_other : '');
      formData.append('remarks_trading_term', this.form.remarks_trading_term ? this.form.remarks_trading_term : '');
      formData.append('payment_term', this.form.payment_term ? this.form.payment_term : '');
      formData.append('shipping_method', this.form.shipping_method ? this.form.shipping_method : '');
      formData.append('delivery_time', this.form.delivery_time ? this.form.delivery_time : '');
      formData.append('others', this.form.others ? this.form.others : '');
      formData.append('userid', this.form.userid ? this.form.userid : '');
      formData.append('ordernumber', this.form.ordernumber ? this.form.ordernumber : '');
      var tp = [];
      for(var i = 0; i < this.form.products.length; i ++){
        if(this.form.products[i].item == '' || this.form.products[i].unit_price == '' || this.form.products[i].unit_price == '0' || this.form.products[i].quantity == '' || this.form.products[i].quantity == '0') continue;
        tp.push(this.form.products[i]);
      }
      if(tp.length == 0) return Vue.$toast.warning('Please insert products', {}); 
      formData.append('products', JSON.stringify(tp));
      this.copyquoteData(formData).then(() => {
        if(this.error == 200){
          setTimeout(()=>{
            this.$router.push({ path: "/dashboard/orders-detailed" }).catch(() => {});
            window.location.reload();
          }, 1000);
          Vue.$toast.success('Quote Order has been created successfully!', {});  
        }else if(this.error == 420){
          Vue.$toast.error('Logo validation failed', {});  
        }
        else{
          Vue.$toast.error('Quote Order failed! Please fill all fields', {});  
        }
      });
    },

    handleFileDrop(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.files.push(f);
      });
      this.color = "#444444";
    },

    currencyHandler(e) {
      if (e == "SAR") {
        this.form.currency = "SAR";
      } else if (e == "AED") {
        this.form.currency = "AED";
      } else if (e == "USD") {
        this.form.currency = "USD";
      } else if (e == "EURO") {
        this.form.currency = "EURO";
      }
    },

    paymentHandler(e) {
      if (e == "LC") {
        this.form.payment_term = "LC";
      } else {
        this.form.payment_term = "TT";
      }
    },

    shippingHandler(e) {
      if (e == "AIR") {
        this.SEA = false;
        this.form.shipping_method = "AIR";
      } else {
        this.AIR = false;
        this.form.shipping_method = "SEA";
      }
    },

    deliveryHandler(e) {
      if (e == "30") {
        this.form.delivery_time = 30;
      } else if (e == "20") {
        this.form.delivery_time = 20;
      } else {
        this.form.delivery_time = 10;
      }
    },

    handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach((f) => {
        this.files.push(f);
      });
    },

    fileDragIn() {
      this.color = "white";
    },

    fileDragOut() {
      this.color = "#444444";
    },

    addProducts(){
     let d = this.form.products;
      d.push(
        {
          item:'',
          unit_price:'',
          quantity:'',
          description:''
        }
      );
      this.form.products = d;
    },

    removeProducts(){
      var d = this.form.products;
      if(d.length>1)
      {
        d.splice(d.length - 1, 1);
      }
      this.form.products = d;
    }
  },
};
</script>
<style scoped>
.file-list {
  list-style: none;
}

.container {
  min-height: 150px;
}

.file-wrapper {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  overflow: hidden;
  display: flex;
}

.file-wrapper input {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 300px;
  height: 200px;
}
</style>