import { render, staticRenderFns } from "./shippingEditForm.vue?vue&type=template&id=861e639a&scoped=true&"
import script from "./shippingEditForm.vue?vue&type=script&lang=js&"
export * from "./shippingEditForm.vue?vue&type=script&lang=js&"
import style0 from "./shippingEditForm.vue?vue&type=style&index=0&id=861e639a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "861e639a",
  null
  
)

export default component.exports