import * as accountApi from '@/api/account';
import * as clientApi from '@/api/client';
import axios from "axios";
import { BIconTabletLandscapeFill } from 'bootstrap-vue';
export default {
    namespaced: true,
    state: {
      errorState: 0,
      employees:[],
      roles:[],
      clients:[],
      selectedClient:{},
      departments:[],
      newUser:{},
      error: 428,
      isWaiting: false,
      countries: []
    },
    getters:{
      countries: state => state.countries,
      isWaiting: state => state.isWaiting,
      error: state => state.error,
      employees: state => state.employees,
      roles: state => state.roles,
      clients: state => state.clients,
      selectedClient: state => state.selectedClient,
      errorState: state => state.errorState,
      departments: state => state.departments,
      newUser: state => state.newUser
    },
    mutations: {

      setCountryCodes(state, countries){
        state.countries = countries;
      },

      setIsWaiting(state, isWaiting) {
        state.isWaiting = isWaiting;
      },
  
      setError(state, error) {
        state.error = error;
      },

      setNewUser(state, newUser) {
        state.newUser = newUser;
      },
      setErrorState(state, errorState) {
        state.errorState = errorState;
      },
      setEmployees(state, employees) {
        state.employees = employees;
      },
      setRoles(state, roles) {
        state.roles = roles;
      },
      setClients(state, clients) {
        state.clients = clients;
      },
      setSelectedClient(state, selectedClient) {
        state.selectedClient = selectedClient;
      },
      setDepartments(state, departments) {
        state.departments = departments;
      },
    },
    actions: {
      async updatePassword({commit}, param){
        commit('setError', 428);
        commit('setIsWaiting', true);
        try {
          const response = await accountApi.updatePassword(param);
          if (response.status == 200) {
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
            if(response.data.code == 200){
              commit('setEmployees', response.data.message);
            }
            return;
          }
          commit('setError', 428);
          commit('setIsWaiting', false);
        } catch (e) {
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      },
      async updateEmployee({commit}, param){
        commit('setError', 428);
        commit('setIsWaiting', true);
        try {
          const response = await accountApi.updateEmployee(param);
          if (response.status == 200) {
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
            if(response.data.code == 200){
              commit('setEmployees', response.data.message);
            }
            return;
          }
          commit('setError', 428);
          commit('setIsWaiting', false);
        } catch (e) {
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      },
      async addNewEmployee({commit, state}, param) {
        commit('setError', 428);
        commit('setIsWaiting', true);
        try {
          const response = await accountApi.addNewEmployee(param);
          if (response.status === 200) {
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
            if(response.data.code == 200){

            }
            return;
          }
          commit('setError', 428);
          commit('setIsWaiting', false);
        } catch (e) {
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      },
      async addNewClient({commit, state}, param) {
        commit('setError', 428);
        commit('setIsWaiting', true);
        try {
          const response = await accountApi.addNewClient(param);
          if (response.status === 200) {
            var user = response.data.message;
            if(response.data.code == 200){
              commit('setNewUser', response.data.message);
            }
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
          }else{
            commit('setError', 428);
            commit('setIsWaiting', false);
          }
        } catch (e) {
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      },
      async LoginAsUser({commit, state}){
        commit('setError', 428);
        commit('setIsWaiting', true);
        let formdata = {};
        formdata.email = state.newUser.email;
        formdata.password = state.newUser.password;
        formdata.fake = true;
        try{
          var response = await clientApi.loginAs(formdata);
          if(response.status == 200){
            commit('setError', 200);
            commit('setIsWaiting', false);
            var avatar =  process.env.VUE_APP_ASSETS + "avatars/" + state.newUser.profile_photo_path;
            // return "https://keerki.com/dashboard?token="+response.data.token + "&userInfo=" + JSON.stringify(response.data.user) + "&avatar=" + avatar + '&currentLanguage=' + localStorage.getItem('currentLanguage');
            window.open("https://keerki.com/dashboard?token="+response.data.token + "&userInfo=" + JSON.stringify(response.data.user) + "&avatar=" + avatar + '&currentLanguage=' + localStorage.getItem('currentLanguage'), "_blank");
          }else{
            commit('setError', 428);
            commit('setIsWaiting', false);
          }
        }catch(e){
          commit('setError', 428);
          commit('setIsWaiting', false);
        }

      },
      async getAllEmployees({commit, state}){
        try {
          commit('setError', 428);
          commit('setIsWaiting', true);
          const response = await accountApi.getAllEmployees();
          if (response.status === 200) {
            commit('setError', response.data.code);
            if(response.data.code == 200){
              commit('setEmployees', response.data.message);
            }
            commit('setIsWaiting', false);
          }
        } catch (e) {
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      },
      async getAllClients(context){
        try {
          const response = await accountApi.getAllClients();
          if (response.status === 200) {
            context.commit('setClients', response.data.message);
          }
        } catch (e) {
        }
      },
      async getSettingData(context){
        try {
          const response = await accountApi.getSettingData();
          if (response.status === 200) {
            context.commit('setEmployees', response.data.message.employees ? response.data.message.employees : []);
            context.commit('setRoles', response.data.message.roles ? response.data.message.roles : []);
            context.commit('setDepartments', response.data.message.departments ? response.data.message.departments : []);
            context.commit('setCountryCodes', response.data.message.countries ? response.data.message.countries : []);
          }
        } catch (e) {
        }
      },
      async activeAccount({commit, state}, param){
        try {
          const response = await accountApi.activeAccount(param);
          if (response.status === 200) {
            commit('setErrorState', response.data.code);
            commit('setClients', response.data.message);
            var tp = state.selectedClient;
            if(response.data.code > 201) return;
            if(response.data.code == 200) tp.isActivate = 1;
            else if(response.data.code == 201) tp.isActivate = 0;     
            commit('setSelectedClient', tp);
          }
        } catch (e) {
        }
      },
      async suspendAccount({commit, state}, param){
        try {
          const response = await accountApi.suspendAccount(param);
          if (response.status === 200) {
            commit('setErrorState', response.data.code);
            commit('setClients', response.data.message);
            var tp = state.selectedClient;
            if(response.data.code > 201) return;
            if(response.data.code == 200) tp.isSuspended = 1;
            else if(response.data.code == 201) tp.isSuspended = 0;     
            commit('setSelectedClient', tp);
          }
        } catch (e) {
        }
      },
      async sendEmail({commit, state}, param){
        commit('setError', 428);
        commit('setIsWaiting', true);
        try{
          var response = await accountApi.sendEmail(param);
          if(response.status == 200){
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
          }else{
            commit('setError', 428);
            commit('setIsWaiting', false);
          }
        }catch(e){
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      },
      async remindEmailLogin({commit}, param){
        commit('setError', 428);
        commit('setIsWaiting', true);
        try{
          var response = await accountApi.remindEmailLogin(param);
            
          if(response.status == 200){
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
            if(response.data.code == 200){
              
            }
          }else{
            commit('setError', 428);
            commit('setIsWaiting', false);
          }
        }catch(e){
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      },

      changeSessionTime({commit, state}, val){
        console.log(val);
        var response = accountApi.changeSessionTime(val);
      }
    },
  }