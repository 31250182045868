import * as notify from '@/api/notify';
import * as clientApi from '@/api/client';
import * as accountApi from '@/api/account';

export default {
  namespaced: true,
  state: {
    error: 428,
    isWaiting: false,
    notifies:[],
    notificationDetail: null,
    searchData: [],
    newNotify: 0,
    read: false,
    // admins:[],
    // customers:[],
    // departs:[]
  },
  mutations: {
    setIsWaiting(state, isWaiting){
      state.isWaiting = isWaiting;
    },
    setError(state, error){
      state.error = error;
    },
    setNotifies(state, notifies){
      state.notifies = notifies;
    },

    setSearchData(state, searchData){
      state.searchData = searchData;
    },
    setRead(state, read) {
      state.read = read;
    },
    removeSearchData(state, index){
      state.searchData.splice(index, 1);
    },
    setdetailData(state, detail) {
      state.notificationDetail = detail;
    },
    // setadminsData(state, admins) {
    //   state.admins = admins;
    // },
    // setcustomersData(state, customers) {
    //     state.customers = customers;
    // },
    // setdepartsData(state, departs) {
    //     state.departs = departs;
    // },
  },
  actions: {
    async getAllNotify({commit,state}) {
      try {
        commit('setError', 428);
        commit('setIsWaiting', true);
        commit('setNotifies', []);
        var param = {id: JSON.parse(localStorage.getItem('adminInfo')).id, state: 0};
        state.read ? param.state = 1 : param.state = 0;
        const response = await notify.getAllNotify(param);
        if(response.status === 200) {
          commit('setError', response.data.code);
          commit('setIsWaiting', false);
          if(response.data.code == 200){
            commit('setNotifies', response.data.message);
          }
          return;
        }
        commit('setError', 428);
        commit('setIsWaiting', false);
      } catch(e){
        commit('setError', 428);
        commit('setIsWaiting', false);
      }
    },
    async sendNotification({commit, state}, param) {
      try {
        commit('setError', 428);
        commit('setIsWaiting', true);
        const response = await notify.sendNotification(param);
        if (response.status === 200){
          commit('setError', response.data.code);
          commit('setIsWaiting', false);
        }else{
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      } catch(e){
        commit('setError', 428);
        commit('setIsWaiting', false);
      }
    },
    async notifyAllClients({commit, state}, param) {
      try {
        commit('setError', 428);
        commit('setIsWaiting', true);
        const response = await notify.notifyAllClients(param);
        if (response.status === 200){
          commit('setError', response.data.code);
          commit('setIsWaiting', false);
        }else{
          commit('setError', 428);
          commit('setIsWaiting', false);
        }
      } catch(e){
        commit('setError', 428);
        commit('setIsWaiting', false);
      }
    },
    searchEmployeeByDeparts({commit, state}, param){
      var departs = param.departs;
      var txt = param.txt;
      for(var i = 0; i < departs.length; i ++){
        if(departs[i].name.toLowerCase().includes(txt.toLowerCase())){
          for(var j = 0; j < state.searchData.length; j ++){
            if(departs[i].id == state.searchData[j].id) break;
          }
          if(j == state.searchData.length) state.searchData.push(departs[i]); 
        }
      }
    },
    searchEmployeeByName({commit, state}, param){
      var admins = param.admin;
      var txt = param.txt;
      for(var i = 0; i < admins.length; i ++){
        if(admins[i].name.toLowerCase().includes(txt.toLowerCase())){
          for(var j = 0; j < state.searchData.length; j ++){
            if(admins[i].id == state.searchData[j].id) break;
          }
          if(j == state.searchData.length) state.searchData.push(admins[i]); 
        }
      }
      // commit('setError', 428);
      // commit('setIsWaiting', true);
      // try {
      //   const response = await notify.searchEmployeeByName(param);
      //   if (response.status === 200) {
      //     commit('setError', response.data.code);
      //     commit('setIsWaiting', false);
          
      //     if(response.data.code == 200){
      //       commit('setSearchData', response.data.message);
      //       var data = response.data.message;
      //       for(var i = 0; i < data.length; i ++){
      //         for(var j = 0; j < state.searchData.length; j ++){
      //           if(data[i].id == state.searchData[j].id){
      //             break;
      //           }
      //           if(j == state.searchData.length - 1){
      //             state.searchData.push(data[i]);
      //             break;
      //           }
      //         }
      //       }
      //     }
      //     commit('setError', 428);
      //     commit('setIsWaiting', false);
      //     return;
      //   }
      // } catch(e){
      //     commit('setError', 428);
      //     commit('setIsWaiting', false);
      // }
    },
    async searchClientByName({commit, state}, param){
      var customers = param.customers;
      console.log(customers);
      var txt = param.txt;
      for(var i = 0; i < customers.length; i ++){
        if(customers[i].name.toLowerCase().includes(txt.toLowerCase())){
          for(var j = 0; j < state.searchData.length; j ++){
            if(customers[i].id == state.searchData[j].id) break;
          }
          if(j == state.searchData.length) state.searchData.push(customers[i]); 
        }
      }
      // try {
      //   const response = await notify.searchClientByName(param);
      //   if (response.status === 200) {
      //     var data = response.data.message;
      //     if(state.searchData.length == 0){
      //       state.searchData = data;
      //       return;
      //     }
      //     for(var i = 0; i < data.length; i ++){
      //       for(var j = 0; j < state.searchData.length; j ++){
      //         if(data[i].id == state.searchData[j].id){
      //           break;
      //         }
      //         if(j == state.searchData.length - 1){
      //           state.searchData.push(data[i]);
      //           console.log(state.searchData);
      //           break;
      //         }
      //       }
      //     }
      //   }
      // } catch(e){
      // }
    },
    async getonenotificationData({ commit, state }, id) {
      try {
        const response = await notify.getonenotificationData(id);
        if (response.status === 200){
            commit("setdetailData", response.data.message);
        }
      } catch(e){
      }
    }, 
    async postdismissData({ commit }, id) {
      try {
        const response = await notify.postdismissData(id);
        if (response.status === 200){
        }
      } catch(e){
      }
    },
    async dismissAllNotifications({commit}){
      try {
        const response = await notify.dismissAllNotifications(JSON.parse(localStorage.getItem('adminInfo')).id);
        if (response.status === 200){
        }
      } catch(e){
      }
    },
    async sendEmail({commit, state}, param){
      commit('setError', 428);
      commit('setIsWaiting', true);
      try{
        var response = await accountApi.sendEmail(param);
        console.log(response.data);
        commit('setError', response.data.code);
        commit('setIsWaiting', false);
      }catch(e){
        console.log(e);
        commit('setError', 428);
        commit('setIsWaiting', false);
      }
    },
  },
  getters:{
    isWaiting: state => state.isWaiting,
    error: state => state.error,
    read: state => state.read,
    notifies : state => state.notifies,
    searchData : state => state.searchData,
    detail: state => state.notificationDetail
  }
}