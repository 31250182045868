import * as roleApi from '@/api/role';
import { _ } from 'core-js';
import Vue from 'vue';
export default {
  namespaced: true,
  state: {
    employees:[],
    roles:[],
    selectedEmployees: [],
    selectedRole: {},
    checked: []
  },
  mutations: {
    setEmployees(state, employees) {
      state.employees = employees;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setSelectedRole(state, role) {
      state.selectedRole = role;
    },
    setSelectedEmployees(state, employees) {
      state.selectedEmployees = employees;
      state.checked = [];
      for(var i = 0; i < employees.length; i ++){
        state.checked.push('not_accepted');
      }
    }
  },
  actions: {
    async getPermissionDetails({commit, state}) {
      try {
        const response = await roleApi.getPermissionDetails();
        console.log(response);
        if (response.status === 200 && response.data.code == 200) {
          commit('setRoles', response.data.message.roles);
          commit('setEmployees', response.data.message.employees);
          commit('setSelectedRole', response.data.message.selected);
          commit('setSelectedEmployees', response.data.message.selectedEmployees);
        }else{
          console.log("**********");
          commit('setRoles', []);
          commit('setEmployees', response.data.message.employees);
          commit('setSelectedRole', null);
          commit('setSelectedEmployees', []);
          console.log(state.selectedRole);
        }
      } catch (e) {
      }
    },
    async addNewRole(context, param) {
      try {
        const response = await roleApi.addNewRole(param);
        if (response.status === 200) {
          context.commit('setRoles', response.data.message);
        }
      } catch (e) {
      }
    },
    async duplicateRole(context, param) {

      try {
        const response = await roleApi.duplicateRole(param);
        if (response.status === 200) {
          context.commit('setRoles', response.data.message);
        }
      } catch (e) {
      }
    },
    async deleteRole(context, param) {
      try {
        const response = await roleApi.deleteRole(param);
        if (response.status === 200) {
          context.commit('setRoles', response.data.message.roles);
          context.commit('setEmployees', response.data.message.employees);
          context.commit('setSelectedRole', response.data.message.selected);
          context.commit('setSelectedEmployees', response.data.message.selectedEmployees);
        }
      } catch (e) {
      }
    },
    async selectRole(context, param){    // Get all datas when click  roles
      try{
        const response = await roleApi.selectRole(param);
        if (response.status === 200) {
          context.commit('setRoles', response.data.message.roles);
          context.commit('setEmployees', response.data.message.employees);
          context.commit('setSelectedRole', response.data.message.selected);
          context.commit('setSelectedEmployees', response.data.message.selectedEmployees);
        }
      }catch(e){
      }
    },
    async addEmployeeToRole(context, param){    // Get all datas when click  roles
      try{
        const response = await roleApi.addEmployeeToRole(param);
        if (response.status === 200) 
        {
          context.commit('setEmployees', response.data.message.employees);
          context.commit('setSelectedEmployees', response.data.message.selectedEmployees);
        }
      }catch(e){
      }
    },
    async deleteEmployeeFromRole(context, param){    // Get all datas when click  roles
      try{
        const response = await roleApi.deleteEmployeeFromRole(param);
        if (response.status === 200) {
          context.commit('setEmployees', response.data.message.employees);
          context.commit('setSelectedEmployees', response.data.message.selectedEmployees);
        }
      }catch(e){

      }
    },
    async updateRolevalue(context, param){    // Get all datas when click  roles
      try{
        delete param['created_at'];
        delete param['updated_at'];
        delete param['rolename'];
        const response = await roleApi.updateRolevalue(param);
        if (response.status === 200) {
          context.commit('setRoles', response.data.message.roles);
          context.commit('setSelectedRole', response.data.message.selected);
        }
      } catch(e){
      }
    },
  },
  getters:{
    employees: state => state.employees,
    roles: state => state.roles,
    selectedEmployees: state => state.selectedEmployees,
    selectedRole: state => state.selectedRole,
    checked: state => state.checked
  }
}