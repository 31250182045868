import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './styles/custom.scss'
import moment from 'moment'
import Echo from 'laravel-echo'
import VueChatScroll from 'vue-chat-scroll'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axios from "axios";
// import VueAxios from 'vue-axios';


var numeral = require("numeral");

window.Pusher = require('pusher-js');

// Vue.use(VueAxios, axios);


window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'anyKey',
  wsHost: 'apis.keerki.com',
  wsPort: 6001,
  wssPort: 6001,
  enabledTransports: ['ws', 'wss'],
  disableStats: true,
  encrypted: true
});

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: '11a6035c106818cb285b',
//   wsHost: window.location.hostname,
//   wsPort: 6001,
//   disableStats: true,
//   forceTLS: false,
//   // enabledTransports: ['ws', 'wss']
// });

window.Echo.channel('keerkiChat')
  .listen('MessageSent', (e) => {
    console.log("channel-keerki");
    console.log(e.message);
    if(window.location.pathname != "/dashboard/inbox"){
        if(e.message.receiver == JSON.parse(localStorage.adminInfo).id){
          store.state.newMessage ++;
          if ('' + e.message.room in store.state.msgs){
            store.state.msgs[e.message.room] = 0;
          }else{
            store.state.msgs[e.message.room] ++;
          }
        } 
    }
});

window.Echo.channel('keerkiNotify')
  .listen('NotificationSent', (e) => {
    console.log("channel-keerkiNotify");
    console.log(e.message);
    if(JSON.parse(localStorage.getItem('adminInfo')).id == e.message.receiver_id){
      var t = store.state.newNotify + 1;
      store.commit("setNewNotify", t);
      store.dispatch("notify/getAllNotify");
    }
});

Vue.config.productionTip = false

Vue.use(VueChatScroll)

Vue.use(VueToast)

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm:ss')
  }
})

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0.00");
});

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')