<template>
  <div class="w-90 pt-9">
    <div class="w-90 border-bottom pb-4 border-dark">
      <h1 class="font-24 mb-0">{{$t('adminAllKeerkiEmployees')}}</h1>
    </div>
    <div
      class="w-90 d-flex justify-content-center flex-column mt-4 align-items-center"
    >
      <div class="w-90 d-flex justify-content-around align-items-center mt-5" v-if="isWaiting">
        <Circle2></Circle2>
      </div>
      <div class="w-90 d-flex justify-content-around align-items-center flex-column flex-lg-row" v-else>
        <EmployeeCard v-for="employee in employees" :key="employee.id"
           :data="employee"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EmployeeCard from './employeeCard';
import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
import {Circle2} from 'vue-loading-spinner';
export default {
  name: "AdminEmployees",
  components: {
      EmployeeCard,
      Circle2
  },
  mounted(){
    this.getAllEmployees();
  },
  methods:{
    ...mapActions('setting', ['getAllEmployees'])
  },
  computed:{
    ...mapGetters('setting', ['employees', 'isWaiting', 'error']),
  },
};
</script>
