<template>
  <div class="w-100 d-flex justify-content-center py-11 align-items-center">
    <div class="d-flex flex-column w-100 align-items-center">
      <label class="file-select align-self-center">
        <div class="select-button d-flex flex-column">
          <div class="d-flex justify-content-center align-items-center" v-if="isWaiting"><Circle2></Circle2></div>
          <img
            v-else
            v-bind:src="avatar"
            width="130"
            height="130"
            class="rounded-circle p-1 border-select-file rounded-circle"
            id="adminImage"
          />
          <span class="pt-3 fs-16 font-weight-lighter outline-none">
            {{ $t("adminAccountSetNewPhoto") }}
          </span>
        </div>
        <input type="file" id="avatar" @change="handleFileChange" />
      </label>
      <div
        class="width-85 pb-11 flex-column admin-settings-form d-flex justify-content-center align-items-center"
      >
        <b-modal
          id="admin-name-modal"
          ref="admin-name-modal"
          centered
          hide-footer
          content-class="content-class"
          header-class="header-class"
          hide-header
          hide-header-close
        >
          <div
            class="d-flex w-100 justify-content-center align-items-center pt-5 pb-4"
          >
            <input
              class="border-bottom outline-none w-100 pb-4"
              :placeholder="`${$t('adminAccountFullName')}`"
              :value="name"
              id="editname"
            />
          </div>
          <div class="d-flex justify-content-end">
            <button
              class="rounded-pill modal-button color-c7 outline-none"
              @click="$bvModal.hide('admin-name-modal')"
            >
              {{ $t("adminAccountCancel") }}
            </button>
            <button
              class="rounded-pill color-ff modal-button outline-none ml-2"
              @click="SaveValue('name')"
            >
              {{ $t("adminAccountSave") }}
            </button>
          </div>
        </b-modal>
        <div
          class="w-90 border-a9a9 py-4 d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 font-weight-bold">
            {{ $t("adminAccountName") }} {{ name }}
          </p>
          <button class="outline-none" v-b-modal.admin-name-modal>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                stroke="#696F79"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                stroke="#696F79"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div
          class="w-90 border-a9a9 py-4 d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 text-muted font-italic">{{ $t("adminAccountEmail") }} {{email}}</p>
          <p class="mb-0 text-muted font-italic">{{ $t("adminAccountCannotEdit") }}</p>
        </div>

        <b-modal
          id="admin-phone-modal"
          ref="admin-phone-modal"
          centered
          hide-footer
          content-class="content-class"
          header-class="header-class"
          hide-header
          hide-header-close
        >
          <div
            class="d-flex w-100 justify-content-center align-items-center pt-5 pb-4"
          >
            <input
              class="border-bottom outline-none w-100 pb-4"
              :placeholder="`${$t('adminAccountPhoneNumber')}`"
              id="editphone"
              :value="number"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="15"
            />
          </div>
          <div class="d-flex justify-content-end">
            <button
              class="rounded-pill modal-button color-c7 outline-none"
              @click="$bvModal.hide('admin-phone-modal')"
            >
              {{ $t("adminAccountCancel") }}
            </button>
            <button
              class="rounded-pill color-ff modal-button outline-none ml-2"
              @click="SaveValue('phone')"
            >
              {{ $t("adminAccountSave") }}
            </button>
          </div>
        </b-modal>

        <div
          class="w-90 border-a9a9 py-4 d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 font-weight-bold">{{ $t("adminAccountPhone") }} {{ number }}</p>
          <button class="outline-none" v-b-modal.admin-phone-modal>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                stroke="#696F79"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                stroke="#696F79"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <b-modal
              id="admin-password-modal"
              ref="admin-password-modal"
              centered
              hide-footer
              content-class="content-class"
              header-class="header-class"
              hide-header
              hide-header-close
            >
              <div
                class="d-flex w-100 justify-content-center align-items-center py-4"
              >
                <input
                  type="password"
                  id="oldpassword"
                  class="border-bottom outline-none w-100 pb-4"
                  :placeholder="`${$t('adminAccountOldPassword')}`"
                />
              </div>
              <div
                class="d-flex w-100 justify-content-center align-items-center py-4"
              >
                <input
                  class="border-bottom outline-none w-100 pb-4"
                  id="editpassword"
                  :placeholder="`${$t('adminAccountNewPassword')}`"
                />
              </div>
              <div
                class="d-flex w-100 justify-content-center align-items-center py-4"
              >
                <input
                  class="border-bottom outline-none w-100 pb-4"
                  id="confirmpassword"
                  :placeholder="`${$t('adminAccountConfirmPassword')}`"
                />
              </div>
              <div class="d-flex justify-content-end">
                <button
                  class="rounded-pill modal-button color-c7 outline-none"
                  @click="$bvModal.hide('admin-password-modal')"
                >
                  {{ $t("adminAccountCancel") }}
                </button>
                <button
                  class="rounded-pill color-ff modal-button outline-none ml-2"
                  @click="SaveValue('password')"
                >
                  {{ $t("adminAccountSave") }}
                </button>
              </div>
            </b-modal>

        <div
          class="w-90 border-a9a9 py-4 d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 font-weight-bold">Password: {{ '********' }}</p>
          <button class="outline-none" v-b-modal.admin-password-modal>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                stroke="#696F79"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                stroke="#696F79"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div
          class="w-90 border-a9a9 py-4 d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 text-muted font-italic">{{ $t("adminAccountUserName") }} HEDI_NASIR</p>
          <p class="mb-0 text-muted font-italic">{{ $t("adminAccountCannotEdit") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapActions } from "vuex";
import {Circle2} from 'vue-loading-spinner';
export default {
  name: "AdminAccount",
    components: {
      Circle2
    },
  computed: {
    ...mapGetters("auth", ["error", "isWaiting"])
  },
  data() {
    return {
      avatar: localStorage.getItem('avatar'),
      email: JSON.parse(localStorage.getItem('adminInfo')).email,
      name: JSON.parse(localStorage.getItem('adminInfo')).f_name + " " + JSON.parse(localStorage.getItem('adminInfo')).l_name,
      number: JSON.parse(localStorage.getItem('adminInfo')).phone,
      password: JSON.parse(localStorage.getItem('adminInfo')).password,
      rules: [
        { message:"8 characters minimum.", regex:/.{8,}/ , state: false},
        { message:'One lowercase letter required.', regex:/[a-z]+/, state: false },
        { message:"One uppercase letter required.",  regex:/[A-Z]+/, state: false },
        { message:"One number required.", regex:/[0-9]+/, state: false },
        // { message:"One special character required.", regex:/[~!@#$%^&*()_+{}|<>?:;]+/, state: false }
      ],
    };
  },
  mounted(){
    console.log(JSON.parse(localStorage.getItem('adminInfo')));
  },
  methods: {
    SaveValue(field){
      if(field == 'password'){
        if(document.getElementById('oldpassword').value == '') return Vue.$toast.warning('Please insert your old password.', {});
        var oldPassword = document.getElementById('oldpassword').value;
        var editPassword = document.getElementById('editpassword').value;
        for(var i = 0; i < this.rules.length; i ++){
          if(!this.rules[i].regex.test(document.getElementById('editpassword').value)) return Vue.$toast.warning(this.rules[i].message, {});
        }

        if(document.getElementById('editpassword').value != document.getElementById('confirmpassword').value){
          return Vue.$toast.warning('The password confirmation does not match.', {});
        }
      }else if(field == 'name'){
        if(document.getElementById('editname').value == '') return Vue.$toast.warning('Please insert your name.', {});
      }else if(field == 'phone'){
        if(document.getElementById('editname').value == '') return Vue.$toast.warning('Please insert your phone.', {});
      }
      this.$store.dispatch('auth/updateAccount', {
        email: this.email,
        name: field == 'name' ? document.getElementById('editname').value : '',
        phone: field == 'phone' ? document.getElementById('editphone').value : '',
        password: field == 'password' ? document.getElementById('editpassword').value : '',
        oldPassword: field == 'password' ? document.getElementById('oldpassword').value : '',
      }).then(()=>{
        if(this.$store.state.error.validations === 200 && this.error == 200){
          this.name = JSON.parse(localStorage.getItem('adminInfo')).f_name + " " + JSON.parse(localStorage.getItem('adminInfo')).l_name;
          this.number = JSON.parse(localStorage.getItem('adminInfo')).phone;
          this.password = JSON.parse(localStorage.getItem('adminInfo')).password;
          Vue.$toast.success('Updated successfully.', {});
          this.$refs['admin-' + field + '-modal'].hide();
        }else if(this.error == 421){
          return Vue.$toast.error('Please insert correct old password.', {});
        }else if(this.error == 422){
          return Vue.$toast.error('Phone number duplicated.', {});
        }else{
          return Vue.$toast.error('Updating failed.', {});
        }
      });
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    handleFileChange(e) {
      var formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('email', this.email);
      this.$store.dispatch('auth/updateAvatar', formData).then(async ()=>{
        
        if(this.error == 426){
          Vue.$toast.error('Avatar file format incorrect', {
              // optional options Object
            });
        }else if(this.error == 427){
          Vue.$toast.error('Avatar file size is over 2MB.', {
              // optional options Object
            });
        }else if(this.error == 428){
            Vue.$toast.error('Avatar file upload failed.', {
              // optional options Object
            });
        }
        if(this.error == 200){
          this.avatar = localStorage.getItem('avatar');
          Vue.$toast.success('Avatar file uploaded successfully.', {
            // optional options Object
          });
          this.sleep(300).then(()=>{
            location.reload();
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.width-85 {
  width: 85% !important;
}
.border-a9a9 {
  border-bottom: 0.5px solid #a9a9a9;
  opacity: 0.8;
}
.admin-settings-form {
  background: #ffffff;
  border-radius: 10px;
}
.file-select > .select-button {
  font-style: normal;
  font-weight: bold;
  font-size: 16.0564px;
  line-height: 18px;

  color: #aeaeae;

  cursor: pointer;
  border-radius: 0.3rem;
  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}
.border-select-file {
  border: 1.5px solid #dfe0eb !important;
}
</style>