import http from '@/api/http';

export function getAllOrders(param){
    return http.post('/admin/allOrders', param);
}

export function getOrderDetails(id){
    return http.get('/admin/orders/' + id);
}

export function getOrderInfo(ordernumber, ordertype){
    if(ordertype == 1){
        return http.get('/admin/orderquoteinfo/' + ordernumber);
    }else if(ordertype == 2){
        return http.get('/admin/orderserviceinfo/' + ordernumber);
    }else if(ordertype == 3){
        return http.get('/admin/ordershippinginfo/' + ordernumber);
    }
}

export function updateOrderCompanyInfo(param){
    return http.post('/admin/updateOrderCompanyInfo', param);
}

export function copyOrderCompanyInfo(param){
    return http.post('/admin/copyOrderCompanyInfo', param);
}

export function searchOrders(param){
    return http.post('/admin/searchOrders', param);
}

export function updateTags(param){
    return http.post('/admin/updateTags', param);
}

export function updateRating(param){
    return http.post('/admin/updateRating', param);
}

export function orderDone(param){
    return http.post('/admin/orderDone', param);
}

export function deleteOrder(id){
    return http.get('/admin/orders/delete/' + id)
}

export function sendSMS(param){
    return http.post('/admin/sendSMS', param);
}

export function changeStatus(param){
    return http.post('/admin/orders/changeStatus', param);
}

export function updateQuote(param){
    return http.post('/admin/updatequote', param);
}

export function updateService(param){
    return http.post('/admin/updateservice', param);
}

export function updateShip(param){
    return http.post('/admin/updateship', param);
}

export function copyQuote(param){
    return http.post('/admin/copyquote', param);
}

export function copyService(param){
    return http.post('/admin/copyservice', param);
}

export function copyShip(param){
    return http.post('/admin/copyship', param);
}

export function exportCSV(){
    return http.get('/admin/orderExport', {responseType: 'arraybuffer'});
}

export function getQuoteDownloadPDF(data){
    return http.get("/admin/orders/quote/download/" + data.ordernumber, {responseType: 'blob'});
}

export function getServiceDownloadPDF(data){
    return http.get("/admin/orders/service/download/" + data.ordernumber, {responseType: 'blob'});
}

export function getShipDownloadPDF(data){
    return http.get("/admin/orders/ship/download/" + data.ordernumber, {responseType: 'blob'});
}