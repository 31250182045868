import axios from "axios";
import * as orderApi from '@/api/order';

export default {
  namespaced: true,
  state: {
    isWaiting: false,
    selected: {},
    orders: [],
    totalOrders:[],
    filterType: -1,
    filterText: '',
    isOrderWaiting: false,
    error: 428,
    employees: []
  },
  getters:{
    employees: state => state.employees,
    error: state => state.error,
    clients: state => state.clients,
    selected: state => state.selected,
    orders: state => state.orders,
    totalOrders: state => state.totalOrders,
    filterText: state => state.filterText,
    filterType: state => state.filterType,
    isWaiting: state => state.isWaiting,
    isOrderWaiting: state => state.isOrderWaiting,

  },
  mutations: {
    setEmployees(state, employees){
      state.employees = employees;
    },
    setError(state, error){
      state.error = error;
    },
    setClients(state, clients){
      state.clients = clients;
    },
    setOrderWaiting(state, isOrderWaiting){
      state.isOrderWaiting = isOrderWaiting;
    },
    setIsWaiting(state, isWaiting){
      state.isWaiting = isWaiting;
    },
    setSelected(state, sel){
      state.selected = sel;
    },
    setOrders(state, orders){
      state.orders = orders;
    },
    setTotalOrders(state, orders){
      state.totalOrders = orders;
    },
    setFilterType(state, type){
      state.filterType = type;
    },
    setFilterText(state, txt){
      state.filterText = txt;
    }
  },
  actions: {
    async searchOrders({commit, state}, filter) {
      try{
        var param={quickFilter:filter};
        if(localStorage.getItem('orderKey') !== null) param.orderKey = localStorage.getItem('orderKey');
        if(localStorage.getItem('sortKey') !== null) param.sortKey = localStorage.getItem('sortKey');
        const response = await orderApi.searchOrders(param);
        if (response.status === 200) {
          commit('setOrders', response.data.message);
          commit('setTotalOrders', response.data.message);
        }
      } catch (e) {
      }
    },
    async getAllOrders(context, data) {
      try {
        var param={quickType: data.filterType, quickFilter: data.filterText};
        context.commit('setIsWaiting', true);
        const response = await orderApi.getAllOrders(param);
        if (response.status === 200) {
          localStorage.removeItem('quickFilterType');
          localStorage.removeItem('quickFilterText');
          var orders = response.data.message ? response.data.message : [];
          var tp = [];
          var depart = JSON.parse(localStorage.getItem('adminInfo')).department; 
          if(depart == 100) tp = orders;
          else{
            for(var i = 0; i < orders.length; i ++){
              if(orders[i].department == depart) tp.push(orders[i]);
            }
          }
          context.commit('setOrders', tp);
          context.commit('setTotalOrders', tp);
          context.commit('setIsWaiting', false);
        }
      } catch (e) {
          context.commit('setIsWaiting', false);
      }
    },
    async getOrderDetails({commit, state}, id) {
      try {
        commit('setIsWaiting', true);
        commit('setError', 428);
        console.log("-------------");
        const response = await orderApi.getOrderDetails(id);
        console.log(response);
        if (response.status === 200) {
          commit('setError', response.data.code)
          commit('setIsWaiting', false);
          if(response.data.code == 200){
            var employees = response.data.message.employees;
            if(JSON.parse(localStorage.adminInfo).department == 100) commit("setEmployees", employees);
            else{
              var tpE = [];
              for(var i = 0; i < employees.length; i ++){
                if(JSON.parse(localStorage.adminInfo).department == employees[i].department) continue;
                tpE.push(employees[i]);
              }
              commit("setEmployees", tpE);
              console.log(tpE);
            }
           
            var tp = response.data.message.order;
            var tags = tp.tags != null ? tp.tags.split(",") : [];
            tp.tags = tags;
            commit('setSelected', tp);
            console.log(tp);
          }
        }
      } catch (e){
          commit('setIsWaiting', false);
      }
    },
    async updateOrderCompanyInfo(context, param) {
      try {
        const response = await orderApi.updateOrderCompanyInfo(param);
        if (response.status === 200) {
          context.commit('setSelected', response.data.message);
        }
      } catch (e) {
      }
    },
    async copyOrderCompanyInfo(context, param) {
      try {
        const response = await orderApi.copyOrderCompanyInfo(param);
        if (response.status === 200) {
          context.commit('setSelected', response.data.message);
        }
      } catch (e) {
      }
    },
    async addEmployeeToRole(context, param) {
      try {
        const response = await orderApi.updateOrderCompanyInfo(param);
        if (response.status === 200) {
          context.commit('setSelected', response.data.message);
        }
      } catch (e) {
      }
    },
    async updateTags(context, param){
      try {
        const response = await orderApi.updateTags(param);
        if (response.status === 200) {
        }
      } catch (e) {
      }
    },
    async updateRating(context, param){
      try {
        const response = await orderApi.updateRating(param);
        if (response.status === 200){
        }
      } catch (e) {
      }
    },
    getDownloadPDF({ commit }, data) {
      if(data.type == "quote"){
        return orderApi.getQuoteDownloadPDF(data)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'quote-'+data.ordernumber+'.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }else if(data.type == "service"){
        return orderApi.getQuoteDownloadPDF(data)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'service-'+data.ordernumber+'.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }else if(data.type == "ship"){
        return orderApi.getShipDownloadPDF(data)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'shipping-'+data.ordernumber+'.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async deleteOrder({commit, state}, param){
      try {
        const response = await orderApi.deleteOrder(param.id);
        if (response.status === 200) {

          state.orders.splice(param.index, 1);
          for(var i = 0; i < state.totalOrders.length; i ++){
            if(state.totalOrders[i].id == param.id){
              state.totalOrders.splice(i, 1);
            }
          }
        }
      } catch (e) {
      }
    },
    exportCSV({commit}){
      orderApi.exportCSV()
      .then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            var d = new Date();
            fileLink.setAttribute('download', 'orders-'+d.getFullYear()+(d.getMonth()+1)+d.getDate()+d.getHours()+d.getMinutes()+d.getSeconds()+'.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
    },
    
    async orderSMS({commit, state}, param){
      commit('setError', 428);
      commit('setOrderWaiting', true);
      const response = await orderApi.sendSMS(param);
      if (response.status === 200) {
        commit('setError', response.data.code);
        commit('setOrderWaiting', false);
        if(response.data.code == 200){

        }
        // var tp = response.data.message;
        //   var tags = tp.tags != null ? tp.tags.split(",") : [];
        //   tp.tags = tags;
        //   commit('setSelected', tp);
      }else{
        commit('setError', 428);
        commit('setOrderWaiting', false);
      }
    },
    async changeStatus({commit, state}, param){
      const response = await orderApi.changeStatus(param);
      if (response.status === 200) {
          var tp = response.data.message;
          var tags = tp.tags != null ? tp.tags.split(",") : [];
          tp.tags = tags;
          commit('setSelected', tp);
      }
    },

    async getOrderInfo({commit, state}, ordernumber) {
      try {
        commit('setIsWaiting', true);
        const response = await orderApi.getOrderInfo(ordernumber, localStorage.ordertype);
        if (response.status === 200) {
          var tp = response.data.message;
          commit('setSelected', tp);
          commit('setIsWaiting', false);
        }
      } catch (e){
      }
    },

    async copyserviceData({commit, state}, param){
      commit('setError', 428);
      commit('setIsWaiting', true);
      try {
        const response = await orderApi.copyService(param);
        if (response.status === 200){
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
            if(response.data.code == 200){
              localStorage.setItem('selected', response.data.message.id);
            }
        }else{
            commit('setIsWaiting', false);
        }
      } catch (e){
        commit('setIsWaiting', false);
      }
    },

    async postserviceData({commit, state}, param){
      commit('setError', 428);
      commit('setIsWaiting', true);
      try {
        const response = await orderApi.updateService(param);
        if (response.status === 200){
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
        }else{
            commit('setIsWaiting', false);
        }
      } catch (e){
        commit('setIsWaiting', false);
      }
    },

    async copyquoteData({commit, state}, param){
      commit('setError', 428);
      commit('setIsWaiting', true);
      try {
        const response = await orderApi.copyQuote(param);
        if (response.status === 200){
            
            commit('setError', response.data.code);
            if(response.data.code == 200){
              localStorage.setItem('selected', response.data.message.id);
            }
            commit('setIsWaiting', false);
        }else{
            commit('setIsWaiting', false);
        }
      } catch (e){
        commit('setIsWaiting', false);
      }
    },

    async postquoteData({commit, state}, param){
      commit('setError', 428);
      commit('setIsWaiting', true);
      try {
        const response = await orderApi.updateQuote(param);
        if (response.status === 200){
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
        }else{
            commit('setIsWaiting', false);
        }
      } catch (e){
        commit('setIsWaiting', false);
      }
    },

    async copyshipData({commit, state}, param){
      commit('setError', 428);
      commit('setIsWaiting', true);
      try {
        const response = await orderApi.copyShip(param);
        if (response.status === 200){
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
            if(response.data.code == 200){
              localStorage.setItem('selected', response.data.message.id);
            }
        }else{
            commit('setIsWaiting', false);
        }
      } catch (e){
        commit('setIsWaiting', false);
      }
    },

    async postshipData({commit, state}, param){
      commit('setError', 428);
      commit('setIsWaiting', true);
      try {
        const response = await orderApi.updateShip(param);
        if (response.status === 200){
            commit('setError', response.data.code);
            commit('setIsWaiting', false);
        }else{
            commit('setIsWaiting', false);
        }
      } catch (e){
        commit('setIsWaiting', false);
      }
    }
  },
}