import * as clientApi from '@/api/client';
import * as orderApi from '@/api/order';
import axios from "axios";

export default {
  namespaced: true,
  state: {
    isWaiting:false,
    error: 428,
    clients: [],
    totalClients: [],
    selected: {},
    orders: [],
    totalOrders: []
  },
  mutations: {
    setError(state, error){
      state.error = error;
    },
    setTotalOrders(state, totalOrders) {
      state.totalOrders = totalOrders;
    },
    setIsWaiting(state, isWaiting) {
      state.isWaiting = isWaiting;
    },
    setClients(state, clients) {
      state.clients = clients;
    },
    setTotalClients(state, clients){
      state.totalClients = clients;
    },
    setSelected(state, sel){
      state.selected = sel;
    },
    setOrders(state, orders){
      state.orders = orders;
    }
  },
  actions: {
    async searchClients({commit, state}, filter) {
      try{
        var param={quickFilter:filter};
        if(localStorage.getItem('orderKey') !== null) param.orderKey = localStorage.getItem('orderKey');
        if(localStorage.getItem('sortKey') !== null) param.sortKey = localStorage.getItem('sortKey');
        const response = await clientApi.searchClients(param);
        if (response.status === 200) {
          commit('setClients', response.data.message);
          commit('setTotalClients', response.data.message);
        }
      } catch (e) {
      }
    },
    async getAllClients({commit, state}) {
      try {
        commit('setIsWaiting', true);
        const response = await clientApi.getAllClients();
        if (response.status === 200) {
          commit('setIsWaiting', false);
          commit('setClients', response.data.message ? response.data.message : []);
          commit('setTotalClients', response.data.message ? response.data.message : []);
        }
      } catch (e) {
      }
    },
    async deleteClient({commit, state}, param){
      try {
        const response = await clientApi.deleteClient(param.id);
        if (response.status === 200) {
          state.clients.splice(param.index, 1);
        }
      } catch (e) {
      }
    },
    async getClientDetails({commit, state}, id) {
      try {
        commit('setIsWaiting', true);
        console.log("--------->   " + id);
        const response = await clientApi.getClientDetails(id);
        console.log(response.data);
        commit('setIsWaiting', false);
        if (response.status === 200) {
          // console.log(response.data.message);
          commit('setSelected', response.data.message.user);
          commit('setOrders', response.data.message.orders);
          commit('setTotalOrders', response.data.message.orders);
        }
      } catch (e) {
        commit('setIsWaiting', false);
      }
    },
    async updateCompanyInfo(context, param) {
      try {
        const response = await clientApi.updateCompanyInfo(param);
        if (response.status === 200) {
          context.commit('setSelected', response.data.message);
        }
      } catch (e) {
      }
    },
    exportOrderCSV({commit}, id){
      clientApi.exportOrderCSV(id)
      .then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            var d = new Date();
            fileLink.setAttribute('download', 'clientOrders-'+d.getFullYear()+(d.getMonth()+1)+d.getDate()+d.getHours()+d.getMinutes()+d.getSeconds()+'.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
    },
    exportCSV({commit}){
      clientApi.exportCSV().then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            var d = new Date();
            fileLink.setAttribute('download', 'clients-'+d.getFullYear()+(d.getMonth()+1)+d.getDate()+d.getHours()+d.getMinutes()+d.getSeconds()+'.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
      })
    },
      async googleLoginAs({commit}, param){
        let formdata = {};
        formdata.email = param.email;
        formdata.googleId = param.googleId;
        formdata.fake = true;
        return clientApi.googleLoginAs(formdata)
          .then(resp => {
            var avatar =  process.env.VUE_APP_ASSETS + "avatars/" + resp.data.user.profile_photo_path;
            window.open("https://keerki.com/dashboard?token=xxx&userInfo=" + JSON.stringify(resp.data.user) + "&avatar=" + avatar + '&currentLanguage=' + localStorage.getItem('currentLanguage'), "_blank");
          })
          .catch((err) => {
            console.log(err);
        });
      },

    async loginAs({commit}, param){
      const response = await clientApi.getCorrectpassword(param.temp);
      if (response.status === 200) {
          let formdata = {};
          formdata.email = param.email;
          formdata.password = response.data.message;
          formdata.fake = true;
          return clientApi.loginAs(formdata)
            .then(resp => {
              var avatar =  process.env.VUE_APP_ASSETS + "avatars/" + resp.data.user.profile_photo_path;
              window.open("https://keerki.com/dashboard?token="+resp.data.token + "&userInfo=" + JSON.stringify(resp.data.user) + "&avatar=" + avatar + '&currentLanguage=' + localStorage.getItem('currentLanguage'), "_blank");
            })
            .catch((err) => {
              console.log(err);
          });
      }
    },
    addTagToClient({commit, state}, param){
      clientApi.addTagToClient(param).then((response)=>{
      });
    },
    async deleteOrder({commit, state}, param){
      try {
        const response = await orderApi.deleteOrder(param.id);
        if (response.status === 200) {

          state.orders.splice(param.index, 1);
          for(var i = 0; i < state.totalOrders.length; i ++){
            if(state.totalOrders[i].id == param.id){
              state.totalOrders.splice(i, 1);
            }
          }
        }
      } catch (e) {
      }
    },
  },
  getters:{
    totalOrders: state => state.totalOrders,
    isWaiting: state=> state.isWaiting,
    clients: state => state.clients,
    selected: state => state.selected,
    orders: state => state.orders,
    totalClients: state => state.totalClients,
    error: state => state.error
  }
}