import axios from 'axios';
import store from '@/store';
import VueRouter from "vue-router";
import router from '@/router'
const instance = axios.create({
    baseURL: process.env.VUE_APP_API
});

function myTimerFunction(){
    var now = new Date();
    // store.state.logoutModalShow = true;
}

instance.interceptors.request.use(function(config){
    var now = new Date();
    console.log("여기는 HTTP 함수 이다.");
    if(localStorage.sessionTime != null && parseInt(localStorage.sessionTime) > 0 && now.getMilliseconds - parseInt(localStorage.lastAction) >= parseInt(localStorage.sessionTime) * 60 * 1000){
        localStorage.removeItem('adminInfo');
        localStorage.removeItem('session');
        store.state.error.validations = 421;
        // clearTimeout(store.state.timer);
        router.push('/');
        return;
    }
    localStorage.setItem("lastAction", now.getMilliseconds());
    // clearTimeout(store.state.timer);
    // if(parseInt(localStorage.sessionTime)) store.state.timer = setTimeout(myTimerFunction, parseInt(localStorage.sessionTime) * 60 * 1000);

    if(store.state.auth.token !== null){
        config['headers']['Authorization'] = `Bearer ${store.state.auth.token}`;
    }
    return config;
});

instance.interceptors.response.use(function (response) {

        store.commit('error/setValidationError', response.data.code);
        return response;
    }, function (error) {
        console.log('failed');
        console.log(error);
        store.commit('error/setValidationError', error.response.status);
        store.commit('order/setError', error.response.status);
        store.commit('order/setIsWaiting', false);
        store.commit('setting/setError', error.response.status);
        store.commit('setting/setIsWaiting', false);
        store.commit('auth/setError', error.response.status);
        store.commit('auth/setIsWaiting', false);
        store.commit('message/setError', error.response.status);
        store.commit('message/setIsWaiting', false);
        store.commit('message/setIsWaiting1', false);
        store.commit('message/setIsWaiting2', false);
        store.commit('message/setIsWaiting3', false);
        store.commit('client/setError', error.response.status);
        store.commit('client/setIsWaiting', false);
        store.commit('notify/setError', error.response.status);
        store.commit('notify/setIsWaiting',false);
        return Promise.reject(error);
        console.log(error);
        if (error.response.status === 422) {
            // store.commit('error/setValidationError', error.response.data.data);
        }else if(error.response.status === 423){
            console.log(error);
        }
        else {
            return Promise.reject(error);
        }
});
  
export default instance;