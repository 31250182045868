import http from '@/api/http';

export function getAllNotify(param){
    return http.post('/admin/getAllNotify', param);
}

export function sendNotification(param){
    return http.post('/admin/sendNotification', param);
}

export function searchEmployeeByName(param){
    return http.post('/admin/searchEmployeeByName', param);
}

export function searchClientByName(param){
    return http.post('/admin/searchClientByName', param);
}

export function notifyAllClients(param){
    return http.post('/admin/notifyAllClients', param);
}

export function getonenotificationData(id){
    return http.get('/admin/notification/' + id);
}

export function postdismissData(id){
    return http.get('/admin/dismiss/' + id);
}

export function dismissAllNotifications(id){
    return http.get('/admin/dismissAll/' + id);
}