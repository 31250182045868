import http from '@/api/http';

export function contactAdmin(param){
    return http.post('/admin/contactAdmin', param,
        {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }
    );
}

export function contactClient(param){
    return http.post('/admin/contactClient', param,
        {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }
    );
}

export function dismissAllMsg(id){
    return http.get('/admin/dismissAllMsg/' + id);
}

export function dismissRoomMsg(room, id){
    return http.get('/admin/dismissRoomMsg/' + room + "/" + id);
}

export function markAsResolved(param){
    return http.post('/admin/chat/markAsResolved', param);
}

export function transferChat(param){
    return http.post('/admin/chat/transferChat', param);
}

export function getMessageData(){
    return http.get('/admin/getAllMessage');
}

export function addResponse(param){
    return http.post('/admin/addResponse', param);
}


export function deleteResponse(param){
    return http.post('/admin/deleteResponse', param);
}

export function updateResponse(param){
    return http.post('/admin/updateResponse', param);
}

export function changeChatRoom(room_id){
    return http.get("/admin/changeRoom/" + room_id + '/' + JSON.parse(localStorage.getItem('adminInfo')).id);
    
}

export function getcustomerData(room_id){
    return http.get("/admin/customer/" + room_id + '/' + JSON.parse(localStorage.getItem('adminInfo')).id)
    
}

export function postchatFileData(data){
    return http.post("/admin/chat/file", data);
}

export function postchatData(data){
    return http.post("/admin/chat", data);
}

export function postKeyPressing(data){
    return http.post("/admin/keyPressing", data);
}

export function getQresponseData(){
    return http.get("/admin/selectResponse/" + JSON.parse(localStorage.getItem('adminInfo')).id);
}

export function getQresponseOne(id){
    return http.get("/admin/selectResponseOne/" + id);
}












