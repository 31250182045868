<template>
  <Circle2 v-if="isWaiting" class="mt-6"></Circle2>
  <div v-else class="d-flex flex-lg-row flex-column w-100 bg-white mt-15">
    <div class="w-15 admin-inbox-side border-right py-4"  style="height: 930px !important;  overflow-y:scroll">
      <button
        class="w-100 d-flex justify-content-between btn outline-none align-items-center font-weight-bold"
      >
        <span>{{ $t("adminMessagesHeading") }}</span>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.20711 4.79289C4.81658 4.40237 4.18342 4.40237 3.79289 4.79289C3.40237 5.18342 3.40237 5.81658 3.79289 6.20711L8.29289 10.7071C8.68342 11.0976 9.31658 11.0976 9.70711 10.7071L14.2071 6.20711C14.5976 5.81658 14.5976 5.18342 14.2071 4.79289C13.8166 4.40237 13.1834 4.40237 12.7929 4.79289L9 8.58579L5.20711 4.79289Z"
            fill="#191919"
          />
        </svg>
      </button>
      <div class="w-100 bg-white mt-15 d-flex justify-content-between" dir="auto">
        <p class="d-none">{{ $t("rtlHelper") }}</p>
        <input
          class=" outline-none search-admin-inbox fs-12 px-3"
          :placeholder="`${$t('adminMessagesSearch')}`"
          id="adminMessageSearch"
          @input="changeAdminMessageSearch"
        />
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          class="mt-2 mr-2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.63007 8.54774C2.63007 5.2733 5.2733 2.63007 8.54774 2.63007C11.8222 2.63007 14.4654 5.2733 14.4654 8.54774C14.4654 11.8222 11.8222 14.4654 8.54774 14.4654C5.2733 14.4654 2.63007 11.8222 2.63007 8.54774ZM16.438 14.4654H15.3991L15.0309 14.1103C16.3196 12.6112 17.0955 10.665 17.0955 8.54774C17.0955 3.82676 13.2687 0 8.54774 0C3.82676 0 0 3.82676 0 8.54774C0 13.2687 3.82676 17.0955 8.54774 17.0955C10.665 17.0955 12.6112 16.3196 14.1103 15.0309L14.4654 15.3991V16.438L21.0406 23L23 21.0406L16.774 14.8021L16.438 14.4654Z"
            fill="#747F8D"
          />
        </svg>
      </div>
      <div class="w-100 d-flex justify-content-between align-items-center pt-4 pb-2">
        <div class="d-flex align-items-center">
          <button class="btn outline-none">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.295 4L6 6.2869L3.705 4L3 4.70403L6 7.7L9 4.70403L8.295 4Z"
                fill="#6A7480"
              />
            </svg>
          </button>
          <p class="mb-0 text-muted font-weight-bold">
            {{ $t("adminMessagesOrderMessages") }}
          </p>
        </div>
        <button class="btn outline-none">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15 10H10V15H8V10H3V8H8V3H10V8H15V10Z" fill="#6A7480" />
          </svg>
        </button>
      </div>
      <button
        v-for="(room,index) in rooms" :key="index" 
        class="w-100 outline-none btn d-flex justify-content-between align-items-center"
      >
        <div 
          class="d-flex w-100 py-2" 
          :style="room.id == selectedRoom ? 'background-color: #8290A555; border-radius:7px' : ''"
          @click="room.id == selectedRoom ? '' : changeRoom(room)"
        >
          <div class="btn outlin-none" style="width:100px">
            <img v-bind:src="url + room.profile_photo_path" class=" rounded-circle" width="40px" height="40px"/>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              class="align-self-end mt-3"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle v-if="unReadMsgs[index].count > 0" cx="5" cy="5" r="5" fill="#43B581" />
            </svg>
          </div>
          <div style="width:130px">
            <p class="w-100 mb-0" style="text-align:left; font-size:18px;">{{room.name}}</p>
            <p class="mb-0 font-weight-light w-100 " style="font-size:14px; width:100px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; text-align:left;">{{room.subject}}</p>
          </div>
        </div>
      </button>
    </div>
    <div class="pb-4 order-1 order-lg-0 w-75-resp">
      <div class="w-100">
        <div
          class="d-flex justify-content-around flex-column flex-md-row align-items-center border-bottom py-15"
        >
          <div
            class="width-50 px-4 px-lg-0 d-flex justify-content-around align-items-center"
          >
            <div class="d-flex flex-column mx-3">
              <img v-if="customer"
                v-bind:src="url + customer.profile_photo_path"
                width="50"
                height="50"
              />
              <p class="btn btn-dark py-0 mt-2">VIP</p>
            </div>
            <div class="w-100">
              <p class="mb-1 text-inbox font-weight-bold" v-if="customer">{{ customer.name }}</p>
              <p class="mb-0 text-inbox" v-if="customer">
                {{ customer.ip_address }} + {{ customer.device_type }} + Online status Date of
                Conversation
              </p>
            </div>
          </div>
          <div
            class="width-50 pt-4 pt-md-0 d-flex justify-content-around align-items-center flex-column flex-lg-row"
          >
            <button
              class="d-flex flex-column pt-2 px-2 d-lg-none align-items-center justify-content-center"
              v-on:click="sidebarToggle"
            >
              <span class="px-13 py-1 border-top border-dark"></span>
              <span class="px-13 py-1 border-top border-dark"></span>
              <span class="px-13 py-1 border-top border-dark"></span>
            </button>
            <div
              v-if="isMyChat"
              class="d-flex w-100 justify-content-around mt-4 mt-lg-0 flex-row flex-lg-column flex-xl-row"
            >
              <Circle2 v-if="isWaiting3" class="d-flex justify-content-center align-items-center mt-lg-0 mt-2"></Circle2>
              <button
                v-else
                class="btn btn-primary px-0 admin-inbox-button admin-btn-color d-flex justify-content-center align-items-center mt-lg-0 mt-2"
                v-b-modal.transfer-modal
                @click="openTransferModal"
              >
                {{ $t("adminMessagesTransferTheChat") }}
              </button>
              <b-modal
                id="transfer-modal"
                centered
                hide-footer
                header-class="header-class2"
                no-stacking
                dir="auto"
                size="md"
              >
                <div class="w-100">
                  <div dir="auto">
                    <p class="d-none">{{ $t("clientsSearchFor") }}</p>
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      class="position-absolute mt-20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8906 13.5742L10.582 10.2656C10.5 10.2109 10.418 10.1562 10.3359 10.1562H9.98047C10.8281 9.17188 11.375 7.85938 11.375 6.4375C11.375 3.32031 8.80469 0.75 5.6875 0.75C2.54297 0.75 0 3.32031 0 6.4375C0 9.58203 2.54297 12.125 5.6875 12.125C7.10938 12.125 8.39453 11.6055 9.40625 10.7578V11.1133C9.40625 11.1953 9.43359 11.2773 9.48828 11.3594L12.7969 14.668C12.9336 14.8047 13.1523 14.8047 13.2617 14.668L13.8906 14.0391C14.0273 13.9297 14.0273 13.7109 13.8906 13.5742ZM5.6875 10.8125C3.25391 10.8125 1.3125 8.87109 1.3125 6.4375C1.3125 4.03125 3.25391 2.0625 5.6875 2.0625C8.09375 2.0625 10.0625 4.03125 10.0625 6.4375C10.0625 8.87109 8.09375 10.8125 5.6875 10.8125Z"
                        fill="#747474"
                      />
                    </svg>

                    <input
                      class="w-100 pt-4 pb-2 px-15 border-bottom outline-none"
                      :placeholder="`${$t('clientsSearchFor')}`"
                      v-model="transferSearch"
                      @input="employeeSearchChange"
                    />
                  </div>
                  <div class="overflow-scroll h-300 mt-3">
                    <button
                      v-for="(admin) in this.employeeList" :key="admin.id"
                      class="hover-btn d-flex justify-content-around w-100 align-items-center "
                      @click="TransferChat(admin)"
                    >
                      <div class="p-1 rounded-circle border">
                        <img
                          :src="url + admin.profile_photo_path"
                          class="rounded-circle"
                          width="50"
                          height="50"
                        />
                      </div>

                      <div class="mx-2">
                        <p class="mb-0">{{admin.name + '  (Employee)'}}</p>
                        <p
                          class="mb-0 font-weight-light d-flex justify-content-center align-items-center w-75 text-white button-color2A4"
                        >
                          {{admin.role}}
                        </p>
                      </div>
                   </button>
                  </div>
                </div>
              </b-modal>
              <b-modal
                id="modal-rating"
                centered
                hide-footer
                content-class="content-class"
                header-class="rating-header"
                size="lg"
              >
                <div
                  class="d-flex flex-column flex-sm-row justify-content-around align-items-center pb-4"
                >
                  <div class="order-1 order-sm-0 mt-5 mt-sm-0">
                    <p class="text-center font-24 font-weight-bolder">
                      {{ $t("issueHasBeenResolved") }}
                    </p>
                    <div
                      class="d-flex w-100 justify-content-center flex-column align-items-center"
                    >
                      <div
                        class="d-flex w-75 justify-content-between align-items-center"
                      >
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.9271 0.62615L6.48599 5.28026L1.02432 6.029C0.0448775 6.16257 -0.347645 7.29798 0.362634 7.94829L4.31403 11.5689L3.37945 16.6835C3.21123 17.608 4.24674 18.3005 5.11403 17.8682L10 15.4532L14.886 17.8682C15.7533 18.297 16.7888 17.608 16.6206 16.6835L15.686 11.5689L19.6374 7.94829C20.3476 7.29798 19.9551 6.16257 18.9757 6.029L13.514 5.28026L11.0729 0.62615C10.6355 -0.203435 9.36823 -0.213981 8.9271 0.62615Z"
                            fill="#FFC145"
                          />
                        </svg>

                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.9271 0.62615L6.48599 5.28026L1.02432 6.029C0.0448775 6.16257 -0.347645 7.29798 0.362634 7.94829L4.31403 11.5689L3.37945 16.6835C3.21123 17.608 4.24674 18.3005 5.11403 17.8682L10 15.4532L14.886 17.8682C15.7533 18.297 16.7888 17.608 16.6206 16.6835L15.686 11.5689L19.6374 7.94829C20.3476 7.29798 19.9551 6.16257 18.9757 6.029L13.514 5.28026L11.0729 0.62615C10.6355 -0.203435 9.36823 -0.213981 8.9271 0.62615Z"
                            fill="#FFC145"
                          />
                        </svg>

                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.9271 0.62615L6.48599 5.28026L1.02432 6.029C0.0448775 6.16257 -0.347645 7.29798 0.362634 7.94829L4.31403 11.5689L3.37945 16.6835C3.21123 17.608 4.24674 18.3005 5.11403 17.8682L10 15.4532L14.886 17.8682C15.7533 18.297 16.7888 17.608 16.6206 16.6835L15.686 11.5689L19.6374 7.94829C20.3476 7.29798 19.9551 6.16257 18.9757 6.029L13.514 5.28026L11.0729 0.62615C10.6355 -0.203435 9.36823 -0.213981 8.9271 0.62615Z"
                            fill="#FFC145"
                          />
                        </svg>

                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.9271 0.62615L6.48599 5.28026L1.02432 6.029C0.0448775 6.16257 -0.347645 7.29798 0.362634 7.94829L4.31403 11.5689L3.37945 16.6835C3.21123 17.608 4.24674 18.3005 5.11403 17.8682L10 15.4532L14.886 17.8682C15.7533 18.297 16.7888 17.608 16.6206 16.6835L15.686 11.5689L19.6374 7.94829C20.3476 7.29798 19.9551 6.16257 18.9757 6.029L13.514 5.28026L11.0729 0.62615C10.6355 -0.203435 9.36823 -0.213981 8.9271 0.62615Z"
                            fill="#FFC145"
                          />
                        </svg>

                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.9271 0.62615L6.48599 5.28026L1.02432 6.029C0.0448775 6.16257 -0.347645 7.29798 0.362634 7.94829L4.31403 11.5689L3.37945 16.6835C3.21123 17.608 4.24674 18.3005 5.11403 17.8682L10 15.4532L14.886 17.8682C15.7533 18.297 16.7888 17.608 16.6206 16.6835L15.686 11.5689L19.6374 7.94829C20.3476 7.29798 19.9551 6.16257 18.9757 6.029L13.514 5.28026L11.0729 0.62615C10.6355 -0.203435 9.36823 -0.213981 8.9271 0.62615Z"
                            fill="#FFC145"
                          />
                        </svg>
                      </div>
                      <button class="d-flex justify-content-center align-items-center outline-none request-btn mt-5" @click="RequestResolved">
                        {{ $t("requestRatingFromClient") }}
                      </button>
                    </div>
                  </div>

                  <div>
                    <svg
                      width="143"
                      height="143"
                      viewBox="0 0 143 143"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M143 71.5C143 110.988 110.988 143 71.5 143C32.0115 143 0 110.988 0 71.5C0 32.0115 32.0115 0 71.5 0C110.988 0 143 32.0115 143 71.5ZM63.2296 109.359L116.278 56.3103C118.079 54.5089 118.079 51.5881 116.278 49.7868L109.755 43.2633C107.953 41.4616 105.032 41.4616 103.231 43.2633L59.9677 86.526L39.7693 66.3275C37.9679 64.5262 35.0471 64.5262 33.2455 66.3275L26.722 72.851C24.9206 74.6523 24.9206 77.5732 26.722 79.3745L56.7058 109.358C58.5075 111.16 61.428 111.16 63.2296 109.359Z"
                        fill="#46D68C"
                      />
                    </svg>
                  </div>
                </div>
              </b-modal>
              <button
                class="btn btn-color29 text-white px-0 admin-inbox-button d-flex justify-content-center align-items-center mt-2 mt-xl-0"
                v-b-modal.modal-rating
              >
                {{ $t("adminMessagesMarkAsResolved") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isWaiting2" class="d-flex justify-content-center flex-column align-items-center" style="height: 530px !important;"><Circle2 ></Circle2></div>
      <div v-else class="w-100 p-2">
        <ul
          class="d-flex flex-column p-4 justify-content-between overflow-scroll messages"
          style="height: 500px !important; list-style-type: none;"
          id="chatContainer"
          v-chat-scroll
        >
          <li 
            v-for="(listItem) in chats"
            :key="listItem.id"
            class="message"
          >
            <div class="d-flex flex-row-reverse " v-if="listItem.receive_to_id == 1">
              <div class="d-flex">
                <div class="mx-3">
                  <p class="messagesend text-right">{{ employeeInfo ? employeeInfo.name : '' }}</p>
                  <div
                    class="send d-flex justify-content-center align-items-center p-4"
                  >
                    <p v-if="listItem.type == 0" class="fs-16 mb-0 font-weight-normal">
                      {{ listItem.chat }}
                    </p>
                    <svg v-if="listItem.type == 1" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.4258 7.91016L20.2734 6.15234L18.5156 0H6.21094C4.75471 0 3.57422 1.18049 3.57422 2.63672V27.3633C3.57422 28.8195 4.75471 30 6.21094 30H23.7891C25.2453 30 26.4258 28.8195 26.4258 27.3633V7.91016Z" fill="#4086F4"/>
                      <path d="M26.4258 7.91016V27.3633C26.4258 28.8195 25.2453 30 23.7891 30H15V0H18.5156L20.2734 6.15234L26.4258 7.91016Z" fill="#4175DF"/>
                      <path d="M26.4258 7.91016H20.2734C19.3066 7.91016 18.5156 7.11914 18.5156 6.15234V0C18.7441 0 18.9727 0.0878906 19.1308 0.26373L26.1621 7.29498C26.3379 7.45312 26.4258 7.68164 26.4258 7.91016Z" fill="#80AEF8"/>
                      <path d="M20.2734 14.1211H9.72656C9.24076 14.1211 8.84766 13.728 8.84766 13.2422C8.84766 12.7564 9.24076 12.3633 9.72656 12.3633H20.2734C20.7592 12.3633 21.1523 12.7564 21.1523 13.2422C21.1523 13.728 20.7592 14.1211 20.2734 14.1211Z" fill="#FFF5F5"/>
                      <path d="M20.2734 17.6367H9.72656C9.24076 17.6367 8.84766 17.2436 8.84766 16.7578C8.84766 16.272 9.24076 15.8789 9.72656 15.8789H20.2734C20.7592 15.8789 21.1523 16.272 21.1523 16.7578C21.1523 17.2436 20.7592 17.6367 20.2734 17.6367Z" fill="#FFF5F5"/>
                      <path d="M20.2734 21.1523H9.72656C9.24076 21.1523 8.84766 20.7592 8.84766 20.2734C8.84766 19.7876 9.24076 19.3945 9.72656 19.3945H20.2734C20.7592 19.3945 21.1523 19.7876 21.1523 20.2734C21.1523 20.7592 20.7592 21.1523 20.2734 21.1523Z" fill="#FFF5F5"/>
                      <path d="M16.7578 24.668H9.72656C9.24076 24.668 8.84766 24.2749 8.84766 23.7891C8.84766 23.3033 9.24076 22.9102 9.72656 22.9102H16.7578C17.2436 22.9102 17.6367 23.3033 17.6367 23.7891C17.6367 24.2749 17.2436 24.668 16.7578 24.668Z" fill="#FFF5F5"/>
                      <path d="M15 24.668H16.7578C17.2436 24.668 17.6367 24.2749 17.6367 23.7891C17.6367 23.3033 17.2436 22.9102 16.7578 22.9102H15V24.668Z" fill="#E3E7EA"/>
                      <path d="M15 21.1523H20.2734C20.7592 21.1523 21.1523 20.7592 21.1523 20.2734C21.1523 19.7876 20.7592 19.3945 20.2734 19.3945H15V21.1523Z" fill="#E3E7EA"/>
                      <path d="M15 17.6367H20.2734C20.7592 17.6367 21.1523 17.2436 21.1523 16.7578C21.1523 16.272 20.7592 15.8789 20.2734 15.8789H15V17.6367Z" fill="#E3E7EA"/>
                      <path d="M15 14.1211H20.2734C20.7592 14.1211 21.1523 13.728 21.1523 13.2422C21.1523 12.7564 20.7592 12.3633 20.2734 12.3633H15V14.1211Z" fill="#E3E7EA"/>
                    </svg>
                    <a class="fs-16 mb-0 font-weight-normal ml-2" v-if="listItem.type == 1" :href="fileUrl + 'chats/' + listItem.url" target="_blank">{{listItem.chat}}</a>
                  </div>
                </div>
                <div>
                  <img 
                    v-bind:src="url + employeeInfo.profile_photo_path"
                    class="rounded-circle"
                    width="35" height="35"
                  />                  
                </div>
              </div>
            </div>
            <div class="d-flex" v-else-if="listItem.receive_to_id == 0">
              <div>
                <img v-if="customer"
                  v-bind:src="url + customer.profile_photo_path"
                  class="rounded-circle"
                  width="35"
                  height="35"
                />
              </div>
              <div class="d-flex">
                <div class="mx-3">
                  <p class="messagesend">{{ customer ? customer.name : '' }} - Customer Support</p>
                  <div
                    class="receive d-flex justify-content-center align-items-center w-100 p-4"
                  >
                    <p v-if="listItem.type == 0" class="fs-16 mb-0 font-weight-normal">
                      {{ listItem.chat }}
                    </p>
                    <svg v-if="listItem.type == 1" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.4258 7.91016L20.2734 6.15234L18.5156 0H6.21094C4.75471 0 3.57422 1.18049 3.57422 2.63672V27.3633C3.57422 28.8195 4.75471 30 6.21094 30H23.7891C25.2453 30 26.4258 28.8195 26.4258 27.3633V7.91016Z" fill="#4086F4"/>
                      <path d="M26.4258 7.91016V27.3633C26.4258 28.8195 25.2453 30 23.7891 30H15V0H18.5156L20.2734 6.15234L26.4258 7.91016Z" fill="#4175DF"/>
                      <path d="M26.4258 7.91016H20.2734C19.3066 7.91016 18.5156 7.11914 18.5156 6.15234V0C18.7441 0 18.9727 0.0878906 19.1308 0.26373L26.1621 7.29498C26.3379 7.45312 26.4258 7.68164 26.4258 7.91016Z" fill="#80AEF8"/>
                      <path d="M20.2734 14.1211H9.72656C9.24076 14.1211 8.84766 13.728 8.84766 13.2422C8.84766 12.7564 9.24076 12.3633 9.72656 12.3633H20.2734C20.7592 12.3633 21.1523 12.7564 21.1523 13.2422C21.1523 13.728 20.7592 14.1211 20.2734 14.1211Z" fill="#FFF5F5"/>
                      <path d="M20.2734 17.6367H9.72656C9.24076 17.6367 8.84766 17.2436 8.84766 16.7578C8.84766 16.272 9.24076 15.8789 9.72656 15.8789H20.2734C20.7592 15.8789 21.1523 16.272 21.1523 16.7578C21.1523 17.2436 20.7592 17.6367 20.2734 17.6367Z" fill="#FFF5F5"/>
                      <path d="M20.2734 21.1523H9.72656C9.24076 21.1523 8.84766 20.7592 8.84766 20.2734C8.84766 19.7876 9.24076 19.3945 9.72656 19.3945H20.2734C20.7592 19.3945 21.1523 19.7876 21.1523 20.2734C21.1523 20.7592 20.7592 21.1523 20.2734 21.1523Z" fill="#FFF5F5"/>
                      <path d="M16.7578 24.668H9.72656C9.24076 24.668 8.84766 24.2749 8.84766 23.7891C8.84766 23.3033 9.24076 22.9102 9.72656 22.9102H16.7578C17.2436 22.9102 17.6367 23.3033 17.6367 23.7891C17.6367 24.2749 17.2436 24.668 16.7578 24.668Z" fill="#FFF5F5"/>
                      <path d="M15 24.668H16.7578C17.2436 24.668 17.6367 24.2749 17.6367 23.7891C17.6367 23.3033 17.2436 22.9102 16.7578 22.9102H15V24.668Z" fill="#E3E7EA"/>
                      <path d="M15 21.1523H20.2734C20.7592 21.1523 21.1523 20.7592 21.1523 20.2734C21.1523 19.7876 20.7592 19.3945 20.2734 19.3945H15V21.1523Z" fill="#E3E7EA"/>
                      <path d="M15 17.6367H20.2734C20.7592 17.6367 21.1523 17.2436 21.1523 16.7578C21.1523 16.272 20.7592 15.8789 20.2734 15.8789H15V17.6367Z" fill="#E3E7EA"/>
                      <path d="M15 14.1211H20.2734C20.7592 14.1211 21.1523 13.728 21.1523 13.2422C21.1523 12.7564 20.7592 12.3633 20.2734 12.3633H15V14.1211Z" fill="#E3E7EA"/>
                    </svg>
                    <a class="fs-16 mb-0 font-weight-normal ml-2" v-if="listItem.type == 1" :href="fileUrl + 'chats/' + listItem.url" target="_blank">{{listItem.chat}}</a>
                  </div>
                </div>
              </div>
            </div>
         </li>
       </ul>
      </div>
      <div
        class="w-100 p-2 send-bar d-flex justify-content-between align-items-center"
      >
        <b-modal
          id="copymodal"
          ref="copymodal"
          centered
          hide-footer
          header-class="header-class"
          hide-header
          size="lg"
          hide-header-close
        >
          <div class="w-100">
            <textarea class="quick-resp-text w-100 outline-none p-4" :value="qresponseOne.text" id="qresponseText"></textarea>
            <div class="w-90 d-flex justify-content-between align-items-center">
              <div>
              </div>
              <div>
                <button
                  class="btn edit-button text-white"
                  type="button"
                  @click="copyResponse(qresponseOne.id)"
                >
                  {{ $t("adminMessagesCopy") }}
                </button>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          id="editmodal"
          ref="editmodal"
          centered
          hide-footer
          header-class="header-class"
          hide-header
          size="lg"
          hide-header-close
        >
          <div class="w-100">
            <textarea class="quick-resp-text w-100 outline-none p-4" :value="qresponseOne.text" id="qresponseText"></textarea>
            <div class="w-90 d-flex justify-content-between align-items-center">
              <div>
              </div>
              <div>
                <button
                  class="btn edit-button text-white"
                  type="button"
                  @click="editResponse(qresponseOne.id)"
                >
                  {{ $t("adminMessagesEdit") }}
                </button>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          id="deleterating"
          ref="deleterating"
          centered
          hide-footer
          hide-header
          content-class="content-class"
          size="lg"
        >
          <div
            class="d-flex pt-5 justify-content-center align-items-center flex-column"
          >
            <h1 class="fs-30">{{ $t("adminMessageDeletePopup") }}</h1>
            <div
              class="w-100 d-flex justify-content-end align-items-center mt-9"
            >
              <div
                class="d-flex justify-content-around width-50 align-items-center"
              >
                <button
                  class="btn btn-67 mt-4 mt-lg-0 text-white"
                  @click="$bvModal.hide('deleterating')"
                >
                  {{ $t("adminMessageDeleteNo") }}
                </button>
                <button
                  class="btn btn-ff mt-4 mt-lg-0 mx-2 mx-lg-0 text-white"
                  @click="deleteResponse"
                >
                  {{ $t("adminMessageDeleteYes") }}
                </button>
              </div>
            </div>
          </div>
        </b-modal>
        <div v-if="isMyChat" class="d-flex justify-content-around align-items-center width-25">
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none p-0 outline-none"
            dropup
            left
            no-caret
          >
            <template #button-content>
              <svg
                width="20"
                height="26"
                viewBox="0 0 20 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L1 15.4H10L9 25L19 10.6H10L11 1Z"
                  stroke="#4F5660"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
            <b-dropdown-text>
              <p class="mb-0 font-weight-bolder">Use a quick response</p>
            </b-dropdown-text>
            <b-dropdown-divider />
            <div
              class="w-100 d-flex justify-content-center align-items-center flex-column"
            >
              <div class="w-100 px-2">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="position-absolute mt-3 mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.48656 4.83133C1.48656 2.98056 2.98056 1.48656 4.83133 1.48656C6.6821 1.48656 8.1761 2.98056 8.1761 4.83133C8.1761 6.6821 6.6821 8.1761 4.83133 8.1761C2.98056 8.1761 1.48656 6.6821 1.48656 4.83133ZM9.29102 8.1761H8.70383L8.49571 7.97541C9.22413 7.12807 9.66266 6.02802 9.66266 4.83133C9.66266 2.16295 7.49971 0 4.83133 0C2.16295 0 0 2.16295 0 4.83133C0 7.49971 2.16295 9.66266 4.83133 9.66266C6.02802 9.66266 7.12807 9.22413 7.97541 8.49571L8.1761 8.70383V9.29102L11.8925 13L13 11.8925L9.48095 8.36641L9.29102 8.1761Z"
                    fill="#63656F"
                  />
                </svg>
                <input
                  placeholder="Search"
                  id="quickMessageSearch"
                  @input="onChangeQuickSearch"
                  class="w-100 px-4 rounded-lg py-2 border outline-none"
                />
              </div>
              <div class="w-100 px-2" style="height:180px; overflow-y: scroll;">
                <div 
                  v-for="(item) in quickItems" :key="item.id"
                  class="align-self-start w-100 mt-3 d-flex justify-content-between align-items-center"
                >
                  <p class="mb-0" style="cursor: pointer; width:200px; text-overflow: ellipsis; overflow:hidden " @click="clickItem(item.text)">{{item.text}}</p>
                  <div style="display: flex; justify-content: center;">
                    <button class="outline-none"
                      type="button"
                      @click="editClicked(item.id)"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="mx-1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3333 2.00004C11.5084 1.82494 11.7163 1.68605 11.9451 1.59129C12.1738 1.49653 12.419 1.44775 12.6667 1.44775C12.9143 1.44775 13.1595 1.49653 13.3883 1.59129C13.617 1.68605 13.8249 1.82494 14 2.00004C14.1751 2.17513 14.314 2.383 14.4087 2.61178C14.5035 2.84055 14.5523 3.08575 14.5523 3.33337C14.5523 3.58099 14.5035 3.82619 14.4087 4.05497C14.314 4.28374 14.1751 4.49161 14 4.66671L5 13.6667L1.33333 14.6667L2.33333 11L11.3333 2.00004Z"
                          stroke="#52575C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <button class="outline-none" @click="copyClicked(item.id)">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="mx-1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                          stroke="#52575C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33333 9.99992H2.66666C2.31304 9.99992 1.9739 9.85944 1.72385 9.60939C1.4738 9.35935 1.33333 9.02021 1.33333 8.66659V2.66659C1.33333 2.31296 1.4738 1.97382 1.72385 1.72378C1.9739 1.47373 2.31304 1.33325 2.66666 1.33325H8.66666C9.02028 1.33325 9.35942 1.47373 9.60947 1.72378C9.85952 1.97382 10 2.31296 10 2.66659V3.33325"
                          stroke="#52575C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <button class="outline-none"
                      type="button"
                      @click="deleteClicked(item.id)">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="mx-1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4H2.33333H13"
                          stroke="#52575C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.33333 3.99992V2.66659C4.33333 2.31296 4.4738 1.97382 4.72385 1.72378C4.9739 1.47373 5.31304 1.33325 5.66666 1.33325H8.33333C8.68695 1.33325 9.02609 1.47373 9.27614 1.72378C9.52619 1.97382 9.66666 2.31296 9.66666 2.66659V3.99992M11.6667 3.99992V13.3333C11.6667 13.6869 11.5262 14.026 11.2761 14.2761C11.0261 14.5261 10.687 14.6666 10.3333 14.6666H3.66666C3.31304 14.6666 2.9739 14.5261 2.72385 14.2761C2.4738 14.026 2.33333 13.6869 2.33333 13.3333V3.99992H11.6667Z"
                          stroke="#52575C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>  
              </div>

            </div>
            <b-dropdown-divider />
            <div class="w-100">
              <input
                id="qresponse"
                ref="qresponse"
                class="outline-none w-100 px-3"
                placeholder="Create a new quick response"
                @keyup.enter="enterClicked"
              />
            </div>
          </b-dropdown>
          <label class="file-select mb-0">
            <div class="select-button d-flex flex-column">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.41699C7.82996 2.41699 2.41663 7.83033 2.41663 14.5003C2.41663 21.1703 7.82996 26.5837 14.5 26.5837C21.17 26.5837 26.5833 21.1703 26.5833 14.5003C26.5833 7.83033 21.17 2.41699 14.5 2.41699ZM20.5416 15.7087H15.7083V20.542H13.2916V15.7087H8.45829V13.292H13.2916V8.45866H15.7083V13.292H20.5416V15.7087Z"
                fill="#4F5660"
              />
            </svg>
            </div>
            <input type="file" id="attachment" @change="handleFileChange" />
          </label>
          <button class="outline-none position-relative">

            <emoji-picker @emoji="append" :search="search">
              <div
                class="emoji-invoker"
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                  fill="#CFCFCF"
                />
                <path
                  d="M7.59402 17.0598C7.62652 17.1891 8.43758 20.2224 12.9999 20.2224C17.5629 20.2224 18.3732 17.1891 18.4057 17.0598C18.4454 16.9031 18.3746 16.7406 18.2345 16.6597C18.0937 16.5795 17.9175 16.6034 17.8012 16.7153C17.7875 16.729 16.39 18.0558 12.9999 18.0558C9.60974 18.0558 8.21152 16.7291 8.19852 16.7161C8.12919 16.6474 8.03674 16.6113 7.9443 16.6113C7.88363 16.6113 7.82224 16.6265 7.76663 16.6575C7.62508 16.7384 7.5543 16.9024 7.59402 17.0598Z"
                  fill="#4F5660"
                />
                <path
                  d="M8.66664 12.2782C9.66382 12.2782 10.4722 11.1465 10.4722 9.75043C10.4722 8.35438 9.66382 7.22266 8.66664 7.22266C7.66946 7.22266 6.86108 8.35438 6.86108 9.75043C6.86108 11.1465 7.66946 12.2782 8.66664 12.2782Z"
                  fill="#4F5660"
                />
                <path
                  d="M17.3333 12.2782C18.3304 12.2782 19.1388 11.1465 19.1388 9.75043C19.1388 8.35438 18.3304 7.22266 17.3333 7.22266C16.3361 7.22266 15.5277 8.35438 15.5277 9.75043C15.5277 11.1465 16.3361 12.2782 17.3333 12.2782Z"
                  fill="#4F5660"
                />
              </svg>

              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert}">

                <div
                  class="emoji-picker"
                >
                
                  <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                      <h5>{{ category }}</h5>
                      <div class="emojis">
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                          >{{ emoji }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>

          </button>
        </div>
      </div>
      <div>
        <textarea
          id="messageBox"
          v-if="this.$i18n.locale === 'ar'"
          class="w-100 text-right messagebox px-4 py-2 outline-none border-bottom"
          :placeholder="`${$t('adminMessagesPlaceholder')}`"
          v-model="input"
          :disabled="!isMyChat"
        ></textarea>
        <textarea
          v-else
          id="messageBox"
          class="w-100 messagebox px-4 py-2 outline-none border-bottom"
          :placeholder="`${$t('adminMessagesPlaceholder')}`"
          v-model="input"
          :disabled="!isMyChat"
        ></textarea>
      </div>
      <div class="d-flex justify-content-space-between" v-if="typing == true">
        <p class="typing">{{customer.name + ' ' + $t("typing") }}</p>
        <button
          type="button"
          @click="sendMessage"
          class="btn btn-outline-primary border-0 outline-none font-weight-bold"
        >
          {{ $t("adminMessagesSend") }}
        </button>
      </div>
      <div class="d-flex justify-content-end" v-else-if="typing == false">
        <button
          type="button"
          @click="sendMessage"
          class="btn btn-outline-primary border-0 outline-none font-weight-bold"
        >
          {{ $t("adminMessagesSend") }}
        </button>
      </div>
    </div>
    <div
      class="py-15 border-left d-flex flex-column w-xl-25 align-items-center"
    >
      <div class="d-flex justify-content-center align-items-center w-100">
        <div
          class="d-flex justify-content-around align-items-center w-100 flex-wrap"
        >
          <button :class="fileType == 0 ? 'fs-14 btn-inbox btn btn-primary' : 'fs-14 btn-inbox btn btn-outline-primary'" @click="fileFilter(0)">
            {{ $t("adminMessagesAll") }}
          </button>
          <button :class="fileType == 1 ? 'fs-14 btn-inbox btn btn-primary mx-2' : 'fs-14 btn-inbox btn btn-outline-primary mx-2'" @click="fileFilter(1)">
            {{ $t("adminMessagesFile") }}
          </button>
          <button :class="fileType == 2 ? 'fs-14 btn-inbox btn btn-primary' : 'fs-14 btn-inbox btn btn-outline-primary'" @click="fileFilter(2)">
            {{ $t("adminMessagesImages") }}
          </button>
        </div>
      </div>
      <div class="w-100 bg-white mt-15" dir="auto">
        <p class="d-none">{{ $t("rtlHelper") }}</p>
        <input
          class="w-100 px-5 outline-none quick-search"
          :placeholder="`${$t('adminMessagesQuickSearch')}`"
          v-model="searchKey"
          @input="inputSearch"
        />
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          class="position-absolute mx-n5 mt-17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.63007 8.54774C2.63007 5.2733 5.2733 2.63007 8.54774 2.63007C11.8222 2.63007 14.4654 5.2733 14.4654 8.54774C14.4654 11.8222 11.8222 14.4654 8.54774 14.4654C5.2733 14.4654 2.63007 11.8222 2.63007 8.54774ZM16.438 14.4654H15.3991L15.0309 14.1103C16.3196 12.6112 17.0955 10.665 17.0955 8.54774C17.0955 3.82676 13.2687 0 8.54774 0C3.82676 0 0 3.82676 0 8.54774C0 13.2687 3.82676 17.0955 8.54774 17.0955C10.665 17.0955 12.6112 16.3196 14.1103 15.0309L14.4654 15.3991V16.438L21.0406 23L23 21.0406L16.774 14.8021L16.438 14.4654Z"
            fill="#747F8D"
          />
        </svg>
      </div>
      <ul class="w-100  mt-3 messages" dir="auto" style="height: 560px !important; list-style-type: none; overflow-x: scroll;" vue-chat-scroll>
        <li v-for="(file) in attach_files" :key="file.id" class="mt-2 ml-3 mr-3 message"><a :href="fileUrl + 'chats/' + file.temp_attach_file" target="_blank">{{file.attach_file}}</a></li>
      </ul>
    </div>
  </div>
</template>
<script>
import VueChatScroll from 'vue-chat-scroll';
import Vue from 'vue';
import {Circle2} from 'vue-loading-spinner';
import { mapGetters, mapActions, mapMutations } from "vuex";
import EmojiPicker from "vue-emoji-picker";
import Echo from 'laravel-echo';



export default {
  name: "AdminInbox",
  data() {
    return {
      fileUrl: process.env.VUE_APP_ASSETS,
      value: 3,
      input: "",
      search: "",
      avatar: localStorage.getItem('avatar'),
      name: JSON.parse(localStorage.getItem('adminInfo')).f_name + ' ' + JSON.parse(localStorage.getItem('adminInfo')).l_name,
      url: process.env.VUE_APP_ASSETS + 'avatars/',
      form: {
        room_id: this.$route.query.room_id,
        sender: JSON.parse(localStorage.getItem('adminInfo')).id,
        attach_file: "",
        message: this.input
      },
      responseForm: {
        userid: '',
        text: ''
      },
      typing:false,
      keyTimer:null,
      receiverTimer:null,
      isKeyEnable: true,
      fileType: 0,
      searchKey: "",
      employeeList: [],
      transferSearch: "",
      rooms: [],
      unReadMsgs: [],
      quickItems: []
    };
  },
   components: {
    EmojiPicker,
    VueChatScroll,
    Circle2,
  },

  computed: {
    ...mapGetters(["timer"]),
    ...mapGetters("message", ["chats", "customer",  "qresponse", "qresponseOne", "attach_files", "tempFiles", "isMyChat", 
      "employeeInfo", "isWaiting", "isWaiting1", "isWaiting2", "isWaiting3", "myRooms", "selectedRoom", "employees", "unReads", "error"])
  },

  created() {
    this.getcustomerData(this.$route.query.room_id).then(()=>{
      this.rooms = this.myRooms;
      this.unReadMsgs = this.unReads;
      window.Echo.channel('keerkiChat').listen('MessageSent',(event)=>{
        if(event.message.room == this.$route.query.room_id && event.message.receiver == JSON.parse(localStorage.adminInfo).id){
          if ('chat' in event.message){
            this.chats.push(event.message);
            this.chats.push({
              type: 0,
              receive_to_id: 0,
              chat: event.message.chat
            });
          }else if('attach_file' in event.message){
            this.chats.push({
              type: 1,
              receive_to_id: 0,
              chat: event.message.attach_file,
              url: event.message.temp_attach_file
            });
            this.tempFiles.push({
                receive_to_id: 0,
                attach_file: event.message.attach_file,
                temp_attach_file: event.message.temp_attach_file
            });
            this.attach_files.push({
                receive_to_id: 0,
                attach_file: event.message.attach_file,
                temp_attach_file: event.message.temp_attach_file
            });
          }
        }else if(event.message.receiver == JSON.parse(localStorage.adminInfo).id){
          for(var i = 0; i < this.unReadMsgs.length; i ++){
            if(this.unReadMsgs[i].room_id == event.message.room) this.unReadMsgs[i].count ++;
          }

          for(i = 0; i < this.unReads.length; i ++){
            if(this.unReads[i].room_id == event.message.room) this.unReads[i].count ++;
          }
        }
      });
      window.Echo.channel('keerkiKeyEvent').listen('KeyEventSent', (e) => {
          console.log("channel-keerkiKeyEvent");
          console.log(e.keyEvent);
          var data = e.keyEvent;
          if(data.room == this.$route.query.room_id && data.receiver == JSON.parse(localStorage.getItem('adminInfo')).id){
            this.typing = true;
            if(this.receiverTimer) clearTimeout(this.receiverTimer);
            this.receiverTimer = setTimeout(()=>{
              this.typing = false;
            }, 2000)
          }
        }
      );
      this.initControls();
    });
    this.getQresponseData().then(()=>{
      this.quickItems = this.qresponse;
    });    
  },
  methods: {
    ...mapActions("message", ["getchatData", "postchatData", "getcustomerData", "getQresponseData", "getQresponseOne", 
        "postQresponseData", "updateQresponseData", "delQresponseData", "postKeyPressing", "postchatFileData", 
        "changeChatRoom", "markAsResolved", "transferChat"]),
    ...mapActions(["dismissRoomMsg"]),
    ...mapMutations("message", ["setTempFiles", "setAttachFiles", "setMyRooms", "setqresponseData"]),
    RequestResolved(){
      this.markAsResolved(this.selectedRoom).then(() => {
        this.$bvModal.hide("transfer-modal");
        Vue.$toast.success('Requested successfully.', {});
      });
    },
    initControls(){
      document.getElementById('messageBox').addEventListener('keydown', (e)=>{
          if (e.keyCode === 13 && e.ctrlKey) {
              e.preventDefault();
              return this.input= this.input + "\n";
          }
          this.sendTypingEvent();

      });
      document.getElementById('messageBox').addEventListener('keypress', (e)=>{
        if (e.keyCode === 13 && !e.ctrlKey) {
            e.preventDefault();
              this.sendMessage();
              return false;  
          } 
      });
    },
    fileFilter(type){
      this.fileType = type;
      var tp = [];
      for(var i = 0; i < this.tempFiles.length; i ++){
        var name = this.tempFiles[i].attach_file;
        if(this.searchKey == "" || name.indexOf(this.searchKey) !== -1){
          var n = name.lastIndexOf(".");
          var ext = name.substring(n + 1, name.length);
          if(type == 0) tp.push(this.tempFiles[i]);
          if(ext == "png" || ext == "jpg" || ext == "jpeg"){
            if(type == 2) tp.push(this.tempFiles[i]);
          }else{
            if(type == 1) tp.push(this.tempFiles[i]);
          }
        }
      }
      this.setAttachFiles(tp);
    },
    inputSearch(e){
      var tp = [];
      for(var i = 0; i < this.tempFiles.length; i ++){
        var name = this.tempFiles[i].attach_file;
        if(this.searchKey == "" || name.indexOf(this.searchKey) !== -1){
          var n = name.lastIndexOf(".");
          var ext = name.substring(n + 1, name.length);
          if(this.fileType == 0) tp.push(this.tempFiles[i]);
          if(ext == "png" || ext == "jpg" || ext == "jpeg"){
            if(this.fileType == 2) tp.push(this.tempFiles[i]);
          }else{
            if(this.fileType == 1) tp.push(this.tempFiles[i]);
          }
        }
      }
      this.setAttachFiles(tp);
    },
    sendTypingEvent(){
      if(!this.isKeyEnable) return;
      this.postKeyPressing(this.form);
      this.isKeyEnable = false;
      this.keyTimer = setTimeout(()=>{
        this.isKeyEnable = true;
      }, 1000);
      // Echo.join('chat').whisper('typing', this.form);
    },
    sidebarToggle() {
      document.getElementsByClassName("admin-side")[0].classList.add("d-none");
    },
     append(emoji) {
      this.input += emoji;
    },
    handleFileChange(e) {
      var formData = new FormData();
      formData.append('attach_file', e.target.files[0]);
      formData.append('room_id', this.selectedRoom);
      formData.append('sender', JSON.parse(localStorage.getItem('adminInfo')).id);
      this.changeRoomOrder();
      this.postchatFileData(formData).then(() => {
      });
    },

    changeRoomOrder(){
      var tp = [];
      for(var i = 0; i < this.myRooms.length; i ++){
        if(this.myRooms[i].id == this.selectedRoom) {
          if(i == 0) return;
          tp.push(this.myRooms[i]);
          break;
        }
      }
      for(i = 0; i < this.myRooms.length; i ++){
        if(this.myRooms[i].id != this.selectedRoom) tp.push(this.myRooms[i]);
      }
      this.setMyRooms(tp);

      tp = [];
      for(i = 0; i < this.rooms.length; i ++){
        if(this.rooms[i].id == this.selectedRoom) {
          if(i == 0) return;
          tp.push(this.rooms[i]);
          break;
        }
      }
      for(i = 0; i < this.rooms.length; i ++){
        if(this.rooms[i].id != this.selectedRoom) tp.push(this.rooms[i]);
      }
      this.rooms = tp;
    },

    sendMessage(e) {
      if(this.input == "") return;
      this.chats.push({
        type: 0,
        receive_to_id: 1,
        chat: this.input
      });
      this.form.message = this.input;
      this.form.room_id = this.selectedRoom;
      this.changeRoomOrder();
      this.postchatData(this.form).then(() => {
      });
      this.input = "";
    },
    enterClicked() {
      if(document.getElementById('qresponse').value == '') return;
      this.responseForm.userid = JSON.parse(localStorage.adminInfo).id;
      this.responseForm.text = document.getElementById('qresponse').value;
      this.postQresponseData(this.responseForm).then(() => {
        this.onChangeQuickSearch(null);
        this.$refs["qresponse"].value = '';
        Vue.$toast.success('Quick Message has been added successfully.', {});
      }); 
    },
    editClicked(id) {      
      this.getQresponseOne(id).then(() => {
        this.$bvModal.show("editmodal");
      });
    },
    copyClicked(id) {
      this.getQresponseOne(id).then(() => {
        this.$bvModal.show("copymodal");
      });
    },
    editResponse(id) {
      if(document.getElementById('qresponseText').value == '') return;
      let data = {}
      data.id = id;
      data.text = document.getElementById('qresponseText').value; 
      data.userid = JSON.parse(localStorage.adminInfo).id;
      this.updateQresponseData(data).then(() => {
        this.$bvModal.hide("editmodal");
        this.onChangeQuickSearch(null);
        Vue.$toast.success('Quick Message has been modified successfully.', {});
      }); 
    },
    copyResponse(id) {
      if(document.getElementById('qresponseText').value == '') return;
      let data = {}
      this.responseForm.userid = JSON.parse(localStorage.adminInfo).id;
      this.responseForm.text = document.getElementById('qresponseText').value;
      this.postQresponseData(this.responseForm).then(() => {
        this.$bvModal.hide("copymodal");
        this.onChangeQuickSearch(null);
        Vue.$toast.success('Quick Message has been copied successfully.', {});
      }); 
    },
    deleteClicked(id) {
      localStorage.setItem('qresponseId', id)
      this.$bvModal.show("deleterating");      
    },
    deleteResponse() {
      let data = {}
      data.id = localStorage.qresponseId;
      data.userid = JSON.parse(localStorage.adminInfo).id;      
      this.delQresponseData(data).then(()=>{
        this.onChangeQuickSearch(null);
        this.$bvModal.hide("deleterating");
        Vue.$toast.success('Quick Message has been deleted successfully.', {});
      });   
    },
    clickItem(content) {
      this.input = content;
    },
    changeRoom(room){
      this.dismissRoomMsg(room.id);
      this.changeChatRoom(room);
      for(var i = 0; i < this.unReadMsgs.length; i ++){
        if(this.unReadMsgs[i].room_id == room.id) {
          this.unReadMsgs[i].count = 0;
          break;
        }
      }

      for(i = 0; i < this.unReads.length; i ++){
        if(this.unReads[i].room_id == room.id) {
          this.unReads[i].count = 0;
          break;
        }
      }
    },
    openTransferModal(e){
      this.transferSearch = "";
      this.employeeList = this.employees
    },
    employeeSearchChange(e){
      var tp = [];
      for(var i = 0; i < this.employees.length; i ++){
        if(this.employees[i].name.toLowerCase().indexOf(this.transferSearch.toLowerCase()) > -1) tp.push(this.employees[i]);
        this.employeeList = tp;
      }
    },
    TransferChat(admin){
      var param = {
        "room_id" : this.selectedRoom,
        "cur_id" : this.employeeInfo.id,
        "new_id" : admin.id
      }
      this.$bvModal.hide("transfer-modal");
      this.transferChat(param).then(()=>{
        if(this.error == 200){
          Vue.$toast.success('Feedback has been sent successfully.', {});
          this.$router.push({ path: "/dashboard/messages" }).catch(() => {});
        }else{
          Vue.$toast.error('Transfer Failed. Try again!', {});
        }
      });
    },
    changeAdminMessageSearch(e){
      var sKey = (document.getElementById("adminMessageSearch").value).toLowerCase();
      var tp = [];
      for(var i = 0; i < this.myRooms.length; i ++){
        if(this.myRooms[i].name.toLowerCase().includes(sKey) || this.myRooms[i].subject.toLowerCase().includes(sKey)) tp.push(this.myRooms[i]);
      }
      this.rooms = tp;
    },
    onChangeQuickSearch(e){      
      var sKey = (document.getElementById("quickMessageSearch").value).toLowerCase();
      var tp = [];
      for(var i = 0; i < this.qresponse.length; i ++){
        if(this.qresponse[i].text.toLowerCase().includes(sKey)) tp.push(this.qresponse[i]);
      }
      this.quickItems = tp;
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>
<style scoped>
.request-btn{
border: 3px solid #FFC145;
border-radius: 8px;
width: 224px;
height: 41px;
color:#ffc145
}
.request-btn:hover{
  background: #FFC145;
  color:#ffffff
}
.quick-resp-text {
  min-height: 221px;
  background: #f3f3f3;
  border-radius: 20px;
}
.done-button {
  background: #29cc97;
  border-radius: 5px;
  min-width: 154px;
  min-height: 40px;
}
.edit-button {
  background: #245aab;
  border-radius: 5px;
  min-width: 154px;
  min-height: 40px;
}
.btn-67 {
  background: #678aaa;
  min-width: 132px;
}

.btn-ff {
  background: #ff3737;
  min-width: 132px;
}

.rounded-button-inbox {
  background: #ff3737;
  width: 20px;
  height: 20px;
  color: white;
  border: 1.5px solid #f7f8fc;
}
.search-admin-inbox {
  height: 46px;
}
.admin-inbox-button {
  min-width: 150px;
  min-height: 46px;
}
.admin-btn-color {
  background: #8290a5;
}
.w-15 {
  min-width: 20%;
}
.text-inbox {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}
.admin-inbox-side {
  background: #f2f3f5;
}
.btn-color29 {
  background: #29cc97 !important;
}
.emoji-invoker {
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow-y: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  bottom:32px!important;
  right:-110px!important;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
.file-select > .select-button {
  font-style: normal;
  font-weight: bold;
  font-size: 16.0564px;
  line-height: 18px;

  color: #aeaeae;

  cursor: pointer;
  border-radius: 0.3rem;
  text-align: center;
  font-weight: bold;
}

/* Don't forget to hide the original file input! */
.file-select > input[type="file"] {
  display: none;
}

.typing{
  color: gray;
  padding-left: 10px;
}

.justify-content-space-between{
  justify-content: space-between;
}

button.hover-btn:hover{
  background-color: #8290A555; 
  border-radius: 7px;
}
</style>