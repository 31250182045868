import Vue from 'vue'
import Vuex from 'vuex'
import * as authApi from '@/api/auth';
import * as messageApi from '@/api/message';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import auth from './auth';
import error from './error';
import client from './client';
import order from './order';
import setting from './setting';
import role from './role';
import notify from './notify';
import message from './message';

export default new Vuex.Store({
  state: {
    newNotify: 0,
    newMessage: 0,
    msgs: {},
    logoutModalShow: false,
    timer: null,
    menuSelected: 0
  },
  mutations: {
    setMenuSelected(state, menuSelected){
      state.menuSelected = menuSelected
    },
    setNewNotify(state, newNotify) {
      state.newNotify = newNotify;
    },
    setNewMessage(state, newMessage) {
      state.newMessage = newMessage;
    },
    setMsgs(state, msgs){
      state.msgs = msgs;
    },
    setLogoutModalShow(state, modalShow){
      state.logoutModalShow = modalShow;
    }
  },
  actions: {
    async getInitData({commit, state}){
      try {
        if(localStorage.getItem('menuSelected') !== null) {
          console.log(localStorage.getItem('menuSelected'));
          commit("setMenuSelected", localStorage.getItem('menuSelected'));
        }
        const response = await authApi.getInitData();
        if (response.status === 200) {
          commit("setNewMessage", response.data.data.newMsg);
          var messages = response.data.data.msgs;
          for(var i = 0; i < messages.length; i ++){
            if ('' + messages[i].room_id in state.msgs){
              state.msgs['' + messages[i].room_id] = 0;
            }else{
              state.msgs['' + messages[i].room_id] ++;
            }
          }
          var notification = response.data.data.newNotify;
          // var count = 0;
          // for(i = 0; i < notification.length; i ++){
          //   if(notification[i].receiver_id > 0){
          //     count ++;
          //     continue;
          //   }
          //   if(notification[i].status != null && notification[i].status.includes('|' + JSON.parse(localStorage.getItem('adminInfo')).id + '|')) continue;
          //   if(notification[i].exception != null && notification[i].exception.includes('|' + JSON.parse(localStorage.getItem('adminInfo')).id + '|')) continue;
          //   count ++;
          // }
          commit("setNewNotify", notification.length);
        }
      } catch (e) {
      }
    },
    dismissAllMsg({commit}){
      try {
        const response = messageApi.dismissAllMsg(JSON.parse(localStorage.getItem('adminInfo')).id).then(()=>{
          commit("setNewMessage", 0);
        })
      } catch(e){
      }
    },
    async dismissRoomMsg({commit}, room){
      try {
          var response = await  messageApi.dismissRoomMsg(room, JSON.parse(localStorage.getItem('adminInfo')).id);
          console.log(room,"   ",  JSON.parse(localStorage.getItem('adminInfo')).id);
          console.log(response.data);
              commit("setNewMessage", response.data.data.newMsg);
          } catch(e){
            console.log("error");
          }
      },
  },
  modules: {
    auth,
    error,
    client,
    order,
    setting,
    role,
    notify,
    message
  },
  getters: {
    menuSelected: state => state.menuSelected,
    newNotify: state => state.newNotify,
    newMessage: state => state.newMessage,
    logoutModalShow: state => state.logoutModalShow,
    timer: state => state.timer
  },
})