import * as authApi from '@/api/auth';
import * as accountApi from '@/api/account';

export default {
  namespaced: true,
  state: {
    token: null,
    userinfo: {},
    isWaiting: false,
    error: 200
  },
  getters: {
    isWaiting: state => state.isWaiting,
    error: state => state.error
  },
  mutations: {
    setIsWaiting(state, isWaiting) {
      state.isWaiting = isWaiting;
    },

    setError(state, error) {
      state.error = error;
    },

    setToken(state, token) {
      state.token = token;
    },

    setUserInfo(state, user){
      state.userinfo = user;
    }
  },
  actions: {
    async login(context, { email, password }) {
      try {
        var now = new Date();
        localStorage.setItem('lastAction', now.getMilliseconds());
        const response = await authApi.login(email, password);
        if (response.status === 200) {
          context.commit('setUserInfo', response.data.message.user);
          localStorage.setItem('sessionTime', response.data.message.setting.session_time);
          localStorage.setItem('adminInfo', JSON.stringify(response.data.message.user));
          localStorage.setItem('avatar', process.env.VUE_APP_ASSETS + "avatars/" + JSON.parse(localStorage.getItem('adminInfo')).profile_photo_path);
        }
      } catch (e) {
      }
    },

    async updateAccount({commit, state}, param) {
      try {
        commit('setError', 428);
        commit('setIsWaiting', true);
        const response = await accountApi.updateAccount(param);
        commit('setIsWaiting', false);
        if (response.status === 200) {
          commit('setError', response.data.code);
          if(response.data.code == 200){
            commit('setUserInfo', response.data.message);
            localStorage.setItem('adminInfo', JSON.stringify(response.data.message));
            localStorage.setItem('avatar', process.env.VUE_APP_ASSETS + "avatars/" + JSON.parse(localStorage.getItem('adminInfo')).profile_photo_path);
          }
        }
      } catch (e) {
        commit('setIsWaiting', false);
      }
    },

    async updateAvatar({commit}, param) {
      try {
        commit('setError', 428);
        commit('setIsWaiting', true);
        const response = await accountApi.updateAvatar(param);
        commit('setIsWaiting', false);
        if (response.status === 200) {
          commit('setError', response.data.code);
          if(response.data.code == 200){
            commit('setUserInfo', response.data.message);
            localStorage.setItem('adminInfo', JSON.stringify(response.data.message));
            localStorage.setItem('avatar', process.env.VUE_APP_ASSETS + "avatars/" + JSON.parse(localStorage.getItem('adminInfo')).profile_photo_path);
          }
        }else{
          commit('setIsWaiting', false);
          commit('setError', 428);
        }
      } catch (e) {
        commit('setIsWaiting', false);
        commit('setError', 428);
      }
    },
    
    loginWithoutAsync(context, { email, password }) {
      authApi.login(email, password).then(response => {
        if (response.status === 200) {
          context.commit('setToken', response.data.token);
        }
      }).catch(e => {
        alert('Please check UserId and Password!');
      });
    }
  }
}