<template>
    <div>{{ remaining.minutes }} minute {{ remaining.seconds }} seconds</div>
</template>
<script>
import { mapMutations } from 'vuex';



export default {
    data() {
        var now = new Date();
        return {
            counter: 120,
            timeOut: ''
        }
    },

    created: function()
    {
        this.advance();
    },

    methods:
    {
        ...mapMutations("error", ["setValidationError"]),
        advance: function()
        {
           this.timeOut = setInterval(this.timer, 1000);
        },
        timer: function()
        {

            this.counter--;
            if(this.counter == 0){
                this.destroyed();
                localStorage.removeItem('adminInfo');
                this.setValidationError(421);
                this.$router.replace({ path: "/" }).catch(() => {});
            }else{
                // this.advance();
            }            
        },
        destroyed() {
            // clearTimeout(this.timeout);
            clearInterval(this.timeOut);
        }
    },

    computed:
    {
        remaining: function()
        {
            return {
                minutes: Math.floor(this.counter / 60),
                seconds: Math.floor(this.counter % 60),
            }
        }
    }
}
</script>