import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AdminLogin from '../components/admin/login.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import DashboardHome from '../components/admin/dashboardHome.vue'
import AdminAccount from '../components/admin/accountSettings.vue'
import AdminNotifications from '../components/admin/notifications.vue'
import AdminClients from '../components/admin/clients.vue'
import ClientDetailed from '../components/admin/ClientDetailed.vue'
import AdminOrders from '../components/admin/orders.vue'
import AdminOrdersDetailed from '../components/admin/orderDetailed.vue'
import AdminMessages from '../components/admin/messages.vue'
import AdminInbox from '../components/admin/adminInbox.vue'
import AdminContentUnavailable from '../components/admin/contentUnAvailable.vue'
import AdminFunctionalityUnavailable from '../components/admin/functionalityUnavailable.vue'
import AdminSettings from '../components/admin/settings.vue'
import AdminSMS from '../components/admin/sendSms.vue'
import AdminEmail from '../components/admin/sendEmail.vue'
import AdminEmployees from '../components/admin/allEmployees.vue'
import EditPermissions from '../components/admin/editPermissions.vue'
import NotifyEmployees from '../components/admin/notifyEmployees.vue'
import NotifyClients from '../components/admin/notifyClients.vue'
import FormEditBody from '../components/admin/edit/formEditBody.vue'
import ServiceEditForm from '../components/admin/edit/serviceEditForm.vue'
import ShippingEditForm from '../components/admin/edit/shippingEditForm.vue'
import FormCopyBody from '../components/admin/edit/formCopyBody.vue'
import ServiceCopyForm from '../components/admin/edit/serviceCopyForm.vue'
import ShippingCopyForm from '../components/admin/edit/shippingCopyForm.vue'
Vue.use(VueRouter)

const routes = [
  {
    path:"/",
    name:"Admin",
    component:AdminLogin
  },
  {
    path:"/dashboard",
    component:AdminDashboard,
    children:[
      {
        path: "",
        component: DashboardHome
      },
      {
        path: "account",
        component: AdminAccount
      },
      {
        path:"notifications",
        component:AdminNotifications
      },
      {
        path:"clients",
        component:AdminClients
      },
      {
        path:"client-detailed",
        component:ClientDetailed
      },
      {
        path:"orders",
        component:AdminOrders
      },
      {
        path:"orders-detailed",
        component:AdminOrdersDetailed
      },
      {
        path:"messages",
        component:AdminMessages
      },
      {
        path:"inbox",
        component:AdminInbox
      },

      {
        path:"content-unavailable",
        component:AdminContentUnavailable
      },
      {
        path:"functionality-unavailable",
        component:AdminFunctionalityUnavailable
      },
      {
        path:"settings",
        component:AdminSettings
      },
      {
        path:"sms",
        component:AdminSMS
      },
      {
        path:'email',
        component:AdminEmail
      },
      {
        path:"employees",
        component: AdminEmployees
      },
      {
        path:"edit-permissions",
        component:EditPermissions
      },
      {
        path:"notify-employees",
        component:NotifyEmployees
      },
      {
        path:"notify-clients",
        component:NotifyClients
      },
      {
        path:"quote-order-edit",
        component:FormEditBody
      },
      {
        path:"service-order-edit",
        component:ServiceEditForm
      },
      {
        path:"shipping-order-edit",
        component:ShippingEditForm
      },
      {
        path:"quote-order-copy",
        component:FormCopyBody
      },
      {
        path:"service-order-copy",
        component:ServiceCopyForm
      },
      {
        path:"shipping-order-copy",
        component:ShippingCopyForm
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
