<template>
  <div dir="ltr">
    <div class="d-flex flex-column flex-lg-row align-items-center w-100 pt-5 pt-xl-8">
      <div class="d-flex flex-column width-25 ml-xl-4">
        <div
          class="d-flex text-center text-white justify-content-center align-items-center w-100 ml-3"
        >
          <svg
            width="42"
            height="42"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.5 27.3802C27.7281 27.3802 31.1666 23.9417 31.1666 19.7135C31.1666 15.4854 27.7281 12.0469 23.5 12.0469C19.2718 12.0469 15.8333 15.4854 15.8333 19.7135C15.8333 23.9417 19.2718 27.3802 23.5 27.3802ZM23.5 15.8802C25.6141 15.8802 27.3333 17.5995 27.3333 19.7135C27.3333 21.8276 25.6141 23.5469 23.5 23.5469C21.3859 23.5469 19.6666 21.8276 19.6666 19.7135C19.6666 17.5995 21.3859 15.8802 23.5 15.8802Z"
              fill="white"
            />
            <path
              d="M22.3884 42.357C22.7128 42.5886 23.1014 42.7132 23.5001 42.7132C23.8987 42.7132 24.2873 42.5886 24.6117 42.357C25.1944 41.9449 38.889 32.0568 38.8334 19.7135C38.8334 11.259 31.9545 4.38013 23.5001 4.38013C15.0457 4.38013 8.16673 11.259 8.16673 19.7039C8.11115 32.0568 21.8057 41.9449 22.3884 42.357ZM23.5001 8.21346C29.8423 8.21346 35.0001 13.3712 35.0001 19.723C35.0403 28.2292 26.5897 35.8671 23.5001 38.3722C20.4123 35.8652 11.9598 28.2254 12.0001 19.7135C12.0001 13.3712 17.1578 8.21346 23.5001 8.21346Z"
              fill="white"
            />
          </svg>
          <p class="mb-0">{{$t("address")}}</p>
        </div>
        <div
          class="d-flex text-center text-white justify-content-center align-items-center w-100 pt-3"
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.8334 1.50513H12.1667C9.74585 1.50513 7.79169 3.45929 7.79169 5.88013V29.2135C7.79169 31.6343 9.74585 33.5885 12.1667 33.5885H23.8334C26.2542 33.5885 28.2084 31.6343 28.2084 29.2135V5.88013C28.2084 3.45929 26.2542 1.50513 23.8334 1.50513ZM20.9167 30.6718H15.0834V29.2135H20.9167V30.6718ZM25.6563 26.2968H10.3438V5.88013H25.6563V26.2968Z"
              fill="white"
            />
          </svg>

          <p class="mb-0">{{$t("contactNo")}}</p>
        </div>
        <div
          class="d-flex text-center text-white justify-content-center align-items-center w-100 pt-3"
        >
          <svg
            class="ml-lg-4"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.645 10.7256C36.4202 11.2539 35.0873 11.6342 33.7144 11.7823C35.1398 10.9355 36.2072 9.59764 36.7164 8.01982C35.3788 8.81549 33.9136 9.37343 32.3855 9.66889C31.7469 8.98615 30.9745 8.44225 30.1165 8.07108C29.2585 7.69991 28.3332 7.50943 27.3983 7.51149C23.6159 7.51149 20.5739 10.5775 20.5739 14.3399C20.5739 14.8683 20.6379 15.3966 20.742 15.9049C15.0783 15.6087 10.027 12.903 6.66887 8.7603C6.05697 9.80544 5.73632 10.9955 5.74026 12.2065C5.74026 14.5761 6.94505 16.6654 8.78224 17.8942C7.69955 17.8516 6.64222 17.554 5.69624 17.0257V17.1097C5.69624 20.4279 8.04176 23.1777 11.1678 23.8101C10.5808 23.9625 9.97704 24.0405 9.37062 24.0422C8.92633 24.0422 8.50606 23.9982 8.08178 23.9382C8.94634 26.6439 11.464 28.6092 14.4619 28.6732C12.1164 30.5104 9.17849 31.5911 5.98843 31.5911C5.41605 31.5911 4.88771 31.5711 4.33936 31.5071C7.36532 33.4483 10.9556 34.569 14.8222 34.569C27.3743 34.569 34.2428 24.1703 34.2428 15.1444C34.2428 14.8483 34.2428 14.5521 34.2227 14.2559C35.5516 13.2832 36.7164 12.0785 37.645 10.7256Z"
              fill="white"
            />
          </svg>
          <svg
            class="ml-3"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.7103 5.02979H6.25117C5.54271 5.02979 4.97034 5.60216 4.97034 6.31062V35.7697C4.97034 36.4782 5.54271 37.0506 6.25117 37.0506H35.7103C36.4187 37.0506 36.9911 36.4782 36.9911 35.7697V6.31062C36.9911 5.60216 36.4187 5.02979 35.7103 5.02979ZM32.0119 14.3758H29.4542C27.4489 14.3758 27.0607 15.3285 27.0607 16.7294V19.8154H31.8478L31.2234 24.6465H27.0607V37.0506H22.0694V24.6505H17.8947V19.8154H22.0694V16.2531C22.0694 12.1184 24.5951 9.86492 28.2855 9.86492C30.0546 9.86492 31.5716 9.99701 32.0159 10.057V14.3758H32.0119Z"
              fill="white"
            />
          </svg>
          <svg
            class="ml-3"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.6981 5.02979H6.23902C5.53056 5.02979 4.95819 5.60216 4.95819 6.31062V35.7697C4.95819 36.4782 5.53056 37.0506 6.23902 37.0506H35.6981C36.4066 37.0506 36.979 36.4782 36.979 35.7697V6.31062C36.979 5.60216 36.4066 5.02979 35.6981 5.02979ZM14.4564 32.3155H9.70527V17.0336H14.4564V32.3155ZM12.0828 14.9442C11.5382 14.9442 11.0057 14.7827 10.5529 14.4801C10.1 14.1775 9.74707 13.7474 9.53865 13.2443C9.33022 12.7411 9.27568 12.1874 9.38194 11.6532C9.4882 11.119 9.75047 10.6283 10.1356 10.2432C10.5207 9.85809 11.0114 9.59581 11.5456 9.48956C12.0798 9.3833 12.6335 9.43784 13.1366 9.64626C13.6398 9.85469 14.0699 10.2077 14.3725 10.6605C14.6751 11.1134 14.8366 11.6458 14.8366 12.1904C14.8326 13.7114 13.5998 14.9442 12.0828 14.9442ZM32.2439 32.3155H27.4968V24.8827C27.4968 23.1095 27.4648 20.832 25.0272 20.832C22.5576 20.832 22.1774 22.7613 22.1774 24.7546V32.3155H17.4343V17.0336H21.9892V19.1229H22.0533C22.6857 17.9222 24.2347 16.6533 26.5482 16.6533C31.3593 16.6533 32.2439 19.8194 32.2439 23.9341V32.3155Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div
        class="d-flex text-white justify-content-around justify-content-lg-center align-items-center width-50"
      >
        <div class="pt-5 pt-lg-0">
          <p class="font-weight-normal fs-20">{{$t("main")}}</p>
          <p class="pt-3">{{ $t("home") }}</p>
          <p>{{ $t("product") }}</p>
          <p>{{ $t("about") }}</p>
          <p>{{ $t("contact") }}</p>
        </div>
        <div class="pl-lg-8 pt-5 pt-lg-0">
          <p class="font-weight-normal fs-20" >{{$t("resources")}}</p>
          <p class="pt-3">{{$t("freeCatalog")}}</p>
          <p>{{$t("blog")}}</p>
          <p>{{$t("request")}}</p>
          <p>{{$t("updates")}}</p>
        </div>
      </div>
      <div class="width-25 mt-4 mt-lg-0">
        <img src="../assets/logo.png" class="w-100" />
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center align-items-center text-white font-weight-light py-lg-9">
      <p>{{$t("copyRight")}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>