<template>
  <button class="d-flex align-items-center outline-none" >
    <svg v-if="val == 2"
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      class="mx-2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3359 13.6289L1.98438 0.859375C1.90234 0.804688 1.79297 0.75 1.71094 0.75C1.57422 0.75 1.4375 0.832031 1.35547 0.914062L1.08203 1.26953C1.02734 1.32422 0.972656 1.46094 0.972656 1.54297C0.972656 1.65234 1.05469 1.81641 1.16406 1.87109L17.5156 14.668C17.5703 14.7227 17.6797 14.75 17.7891 14.75C17.8984 14.75 18.0352 14.6953 18.1172 14.5859L18.3906 14.2578C18.4453 14.2031 18.5 14.0664 18.5 13.9844C18.5 13.875 18.418 13.7109 18.3359 13.6289ZM9.09375 4.76953L12.7852 7.64062C12.7305 6 11.3906 4.6875 9.75 4.6875C9.55859 4.71484 9.28516 4.74219 9.09375 4.76953ZM10.3789 10.7578L6.6875 7.88672C6.74219 9.52734 8.08203 10.8125 9.75 10.8125C9.91406 10.8125 10.1875 10.7852 10.3789 10.7578ZM9.75 3.8125C12.4297 3.8125 14.918 5.31641 16.2305 7.75C15.957 8.26953 15.4375 9.00781 15.0273 9.41797L16.0664 10.2109C16.5586 9.69141 17.1875 8.78906 17.5156 8.16016C17.5703 8.05078 17.625 7.88672 17.625 7.75C17.625 7.64062 17.5703 7.47656 17.5156 7.36719C16.0391 4.46875 13.0859 2.5 9.75 2.5C8.73828 2.5 7.78125 2.69141 6.87891 3.01953L8.13672 4.03125C8.65625 3.89453 9.20312 3.8125 9.75 3.8125ZM9.75 11.6875C7.04297 11.6875 4.55469 10.1836 3.24219 7.75C3.51562 7.25781 4.03516 6.51953 4.44531 6.10938L3.40625 5.31641C2.91406 5.83594 2.28516 6.73828 1.95703 7.36719C1.90234 7.47656 1.875 7.64062 1.875 7.77734C1.875 7.88672 1.90234 8.05078 1.95703 8.16016C3.43359 11.0586 6.38672 13 9.75 13C10.7344 13 11.6914 12.8086 12.5938 12.5078L11.3359 11.4961C10.8164 11.6328 10.2695 11.6875 9.75 11.6875Z"
        fill="#0074BD"
      />
    </svg>
    <svg
    v-if="val==1"
      width="16"
      height="15"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.875 2.6875C7.62891 2.71484 7.24609 2.76953 7 2.82422C7.10938 3.01562 7.19141 3.37109 7.21875 3.5625C7.21875 4.41016 6.50781 5.09375 5.6875 5.09375C5.46875 5.09375 5.11328 5.01172 4.94922 4.90234C4.86719 5.14844 4.8125 5.50391 4.8125 5.75C4.8125 7.44531 6.17969 8.8125 7.875 8.8125C9.57031 8.8125 10.9375 7.44531 10.9375 5.75C10.9375 4.08203 9.57031 2.6875 7.875 2.6875ZM15.6406 5.36719C14.1641 2.46875 11.2109 0.5 7.875 0.5C4.51172 0.5 1.55859 2.46875 0.0820312 5.36719C0.0273438 5.47656 0 5.64062 0 5.77734C0 5.88672 0.0273438 6.05078 0.0820312 6.16016C1.55859 9.05859 4.51172 11 7.875 11C11.2109 11 14.1641 9.05859 15.6406 6.16016C15.6953 6.05078 15.7227 5.88672 15.7227 5.75C15.7227 5.64062 15.6953 5.47656 15.6406 5.36719ZM7.875 9.6875C5.16797 9.6875 2.67969 8.18359 1.36719 5.75C2.67969 3.31641 5.16797 1.8125 7.875 1.8125C10.5547 1.8125 13.043 3.31641 14.3555 5.75C13.043 8.18359 10.5547 9.6875 7.875 9.6875Z"
        fill="#267CB2"
      />
    </svg>
    <svg
      v-if="val==0"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9922 10.1836C10.9375 10.2383 10.9375 10.293 10.9375 10.3477V13.4375H1.3125V3.8125H7.90234C7.95703 3.8125 8.01172 3.8125 8.06641 3.75781L8.94141 2.88281C9.07812 2.74609 8.96875 2.5 8.77734 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V9.47266C12.25 9.28125 12.0039 9.17188 11.8672 9.30859L10.9922 10.1836ZM15.2578 4.6875C15.8867 4.05859 15.8867 3.04688 15.2578 2.41797L14.082 1.24219C13.4531 0.613281 12.4414 0.613281 11.8125 1.24219L4.64844 8.40625L4.375 10.8945C4.29297 11.6055 4.89453 12.207 5.60547 12.125L8.09375 11.8516L15.2578 4.6875ZM12.5781 5.50781L7.49219 10.5938L5.6875 10.8125L5.90625 9.00781L10.9922 3.92188L12.5781 5.50781ZM14.3281 3.34766C14.4648 3.45703 14.4648 3.62109 14.3555 3.75781L13.5078 4.60547L11.9219 2.99219L12.7422 2.17188C12.8516 2.03516 13.043 2.03516 13.1523 2.17188L14.3281 3.34766Z"
        fill="#267CB2"
      />
    </svg>
    <b-dropdown
      size="sm"
      variant="transparent"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <svg
          width="11"
          height="6"
          viewBox="0 0 11 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.71875 5.875C5.875 6.03125 6.09375 6.03125 6.25 5.875L10.875 1.28125C11.0312 1.15625 11.0312 0.90625 10.875 0.75L10.25 0.15625C10.125 0 9.875 0 9.71875 0.15625L6 3.84375L2.25 0.15625C2.09375 0 1.875 0 1.71875 0.15625L1.09375 0.75C0.9375 0.90625 0.9375 1.15625 1.09375 1.28125L5.71875 5.875Z"
            fill="black"
            fill-opacity="0.55"
          />
        </svg>
      </template>
      <b-dropdown-item @click="SelectRole(1)">
        <div
          class="d-flex justify-content-between align-items-center w-75"
        >
          <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.875 2.6875C7.62891 2.71484 7.24609 2.76953 7 2.82422C7.10938 3.01562 7.19141 3.37109 7.21875 3.5625C7.21875 4.41016 6.50781 5.09375 5.6875 5.09375C5.46875 5.09375 5.11328 5.01172 4.94922 4.90234C4.86719 5.14844 4.8125 5.50391 4.8125 5.75C4.8125 7.44531 6.17969 8.8125 7.875 8.8125C9.57031 8.8125 10.9375 7.44531 10.9375 5.75C10.9375 4.08203 9.57031 2.6875 7.875 2.6875ZM15.6406 5.36719C14.1641 2.46875 11.2109 0.5 7.875 0.5C4.51172 0.5 1.55859 2.46875 0.0820312 5.36719C0.0273438 5.47656 0 5.64062 0 5.77734C0 5.88672 0.0273438 6.05078 0.0820312 6.16016C1.55859 9.05859 4.51172 11 7.875 11C11.2109 11 14.1641 9.05859 15.6406 6.16016C15.6953 6.05078 15.7227 5.88672 15.7227 5.75C15.7227 5.64062 15.6953 5.47656 15.6406 5.36719ZM7.875 9.6875C5.16797 9.6875 2.67969 8.18359 1.36719 5.75C2.67969 3.31641 5.16797 1.8125 7.875 1.8125C10.5547 1.8125 13.043 3.31641 14.3555 5.75C13.043 8.18359 10.5547 9.6875 7.875 9.6875Z"
              fill="#267CB2"
            />
          </svg>
          <p class="mb-0 w-50">{{$t('permissionCanEdit')}}</p>
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="SelectRole(0)">
        <div
          class="d-flex justify-content-between align-items-center w-75"
        >
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9922 10.1836C10.9375 10.2383 10.9375 10.293 10.9375 10.3477V13.4375H1.3125V3.8125H7.90234C7.95703 3.8125 8.01172 3.8125 8.06641 3.75781L8.94141 2.88281C9.07812 2.74609 8.96875 2.5 8.77734 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V9.47266C12.25 9.28125 12.0039 9.17188 11.8672 9.30859L10.9922 10.1836ZM15.2578 4.6875C15.8867 4.05859 15.8867 3.04688 15.2578 2.41797L14.082 1.24219C13.4531 0.613281 12.4414 0.613281 11.8125 1.24219L4.64844 8.40625L4.375 10.8945C4.29297 11.6055 4.89453 12.207 5.60547 12.125L8.09375 11.8516L15.2578 4.6875ZM12.5781 5.50781L7.49219 10.5938L5.6875 10.8125L5.90625 9.00781L10.9922 3.92188L12.5781 5.50781ZM14.3281 3.34766C14.4648 3.45703 14.4648 3.62109 14.3555 3.75781L13.5078 4.60547L11.9219 2.99219L12.7422 2.17188C12.8516 2.03516 13.043 2.03516 13.1523 2.17188L14.3281 3.34766Z"
              fill="#267CB2"
            />
          </svg>
          <p class="mb-0 w-50">{{$t('permissionsCanEdit')}}</p>
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="SelectRole(2)">
        <div
          class="d-flex justify-content-between align-items-center w-75"
        >
          <svg
            width="19"
            height="15"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3359 13.6289L1.98438 0.859375C1.90234 0.804688 1.79297 0.75 1.71094 0.75C1.57422 0.75 1.4375 0.832031 1.35547 0.914062L1.08203 1.26953C1.02734 1.32422 0.972656 1.46094 0.972656 1.54297C0.972656 1.65234 1.05469 1.81641 1.16406 1.87109L17.5156 14.668C17.5703 14.7227 17.6797 14.75 17.7891 14.75C17.8984 14.75 18.0352 14.6953 18.1172 14.5859L18.3906 14.2578C18.4453 14.2031 18.5 14.0664 18.5 13.9844C18.5 13.875 18.418 13.7109 18.3359 13.6289ZM9.09375 4.76953L12.7852 7.64062C12.7305 6 11.3906 4.6875 9.75 4.6875C9.55859 4.71484 9.28516 4.74219 9.09375 4.76953ZM10.3789 10.7578L6.6875 7.88672C6.74219 9.52734 8.08203 10.8125 9.75 10.8125C9.91406 10.8125 10.1875 10.7852 10.3789 10.7578ZM9.75 3.8125C12.4297 3.8125 14.918 5.31641 16.2305 7.75C15.957 8.26953 15.4375 9.00781 15.0273 9.41797L16.0664 10.2109C16.5586 9.69141 17.1875 8.78906 17.5156 8.16016C17.5703 8.05078 17.625 7.88672 17.625 7.75C17.625 7.64062 17.5703 7.47656 17.5156 7.36719C16.0391 4.46875 13.0859 2.5 9.75 2.5C8.73828 2.5 7.78125 2.69141 6.87891 3.01953L8.13672 4.03125C8.65625 3.89453 9.20312 3.8125 9.75 3.8125ZM9.75 11.6875C7.04297 11.6875 4.55469 10.1836 3.24219 7.75C3.51562 7.25781 4.03516 6.51953 4.44531 6.10938L3.40625 5.31641C2.91406 5.83594 2.28516 6.73828 1.95703 7.36719C1.90234 7.47656 1.875 7.64062 1.875 7.77734C1.875 7.88672 1.90234 8.05078 1.95703 8.16016C3.43359 11.0586 6.38672 13 9.75 13C10.7344 13 11.6914 12.8086 12.5938 12.5078L11.3359 11.4961C10.8164 11.6328 10.2695 11.6875 9.75 11.6875Z"
              fill="#267CB2"
            />
          </svg>

          <p class="mb-0 w-50">{{$t('permissionsHide')}}</p>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </button>
    
</template>
<script>
export default {
    name:"AdminPopUp",
    props:{
      val:{
        type: Number,
        required: false
      },
      type:{
        type: Number,
        required: false
      }
    },
    methods:{
      SelectRole(value){
        this.$emit('ChangeType', this.type);
        this.$emit('ChangeRole', value);
      }
    }
}
</script>