import http from '@/api/http';

export function getAllClients(){
    return http.get('/admin/clients');
}

export function getClientDetails(id){
    return http.get('/admin/clients/' + id);
}

export function updateCompanyInfo(param){
    return http.post('/admin/updateCompanyInfo', param);
}

export function getAllMembers(id){
    return http.get('/admin/members/' + id);
}

export function deleteClient(id){
    return http.get('/admin/clients/delete/' + id)
}

export function searchClients(param){
    return http.post('/admin/searchClients', param);
}

export function getCorrectpassword(param){
    return http.post('/admin/getCorrectpassword', {password: param});
}

export function addTagToClient(param){
    return http.post('/admin/addTagToClient', param);
}

export function exportOrderCSV(id){
    return http.get('/admin/clientOrderExport/' + id, {responseType: 'arraybuffer'})
}

export function exportCSV(id){
    return http.get('/admin/clientExport', {responseType: 'arraybuffer'})
}

export function googleLoginAs(formdata){
    return http.post("/googleLogin", formdata)
}

export function loginAs(formdata){
    return http.post("/login", formdata);
}