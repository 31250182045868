<template>
  <div class="d-flex justify-content-center w-100 mt-9 align-items-center">
    <div
      class="admin-messages-cont w-90 d-flex flex-column py-5 align-items-center"
    >
      <div
        class="width-50 align-self-start d-flex justify-content-around align-items-center"
      >
        <button
          :class="status == 1 ? 'pb-3 px-2 outline-none border-bottom-open fs-16 font-weight-bold' : 'btn btn-outline-primary outline-none fs-16 border'"
          @click="status == 1 ? '' : setChatStatus(1)"
        >
          {{ $t("adminMessagesOpen") }}
        </button>
        <button
          :class="status == 0 ? 'pb-3 px-2 outline-none border-bottom-open fs-16 font-weight-bold' : 'btn btn-outline-primary outline-none fs-16 border'"
          @click="status == 0 ? '' : setChatStatus(0)"
        >
          {{ $t("adminMessagesPending") }}
        </button>
        <button
          :class="status == 2 ? 'pb-3 px-2 outline-none border-bottom-open fs-16 font-weight-bold' : 'btn btn-outline-primary outline-none fs-16 border'"
          @click="status == 2 ? '' : setChatStatus(2)"
        >
          {{ $t("adminMessagesResolve") }}
        </button>
        <button 
          :class="status == 3 ? 'pb-3 px-2 outline-none border-bottom-open fs-16 font-weight-bold' : 'btn btn-outline-primary outline-none fs-16 border'"
          @click="status == 3 ? '' : setChatStatus(3)"
        >
          {{ $t("adminMessagesClose") }}
        </button>
      </div>
      <Circle2 v-if="isWaiting" class="d-flex justify-content-center flex-column align-items-center mt-5"></Circle2>
      <div class="w-100 pt-5"  v-else>
        <button v-for="(listItem) in messageList" :key="listItem.id"
          class="outline-none notification-hover p-0 m-0 w-100 border d-flex justify-content-center flex-column align-items-center"
          @click="inbox(listItem.id)"
        >
          <div
            class="w-100 border-left-yellow py-3 d-flex flex-column flex-lg-row justify-content-center align-items-center"
          >
            <div class="w-90 d-flex justify-content-between align-items-center">
              <div class="w-50">
                <div
                  class="d-flex justify-content-between w-30-resp align-items-center"
                >
                  <div class="profile-circle d-flex justify-content-center align-items-center rounded-circle">
                    <img
                      v-bind:src="avatarUrl + listItem.avatar"
                      class="w-100 rounded-circle"
                    />
                  </div>
                  <p class="mb-0 fs-14" v-if="listItem.ordernumber">{{listItem.ordernumber}}</p>
                  <p class="mb-0 fs-14" v-else>{{listItem.subject}}</p>
                </div>
              </div>
              <div
                class="w-50 d-flex justify-content-between flex-column flex-lg-row align-items-end"
              >
                <p class="text-muted mb-0 w-75 text-right text-lg-center" v-if="listItem.status == 0">{{ $t("adminMessagesPending") }}</p>
                <p class="text-muted mb-0 w-75 text-right text-lg-center" v-else-if="listItem.status == 1 || listItem.status == 2">{{ $t("adminMessagesOpen") }}</p>
                <p class="text-muted mb-0 w-75 text-right text-lg-center" v-else-if="listItem.status == 3">{{ $t("adminMessagesResolve") }}</p>
                <p class="text-muted mb-0 w-75 text-right text-lg-center" v-else>{{ $t("adminMessagesClose") }}</p>

                <p class="text-muted mb-0 text-right mt-11 mt-lg-0">
                  {{listItem.created_at | formatDateTime}}
                </p>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {Circle2} from 'vue-loading-spinner'
export default {
  name: "AdminMessages",

  data () {
    return {
      // avatar: localStorage.getItem('avatar')
      avatarUrl: process.env.VUE_APP_ASSETS + 'avatars/',
      status : 1,
      messageList: []
    }
  },

  computed: {
    ...mapGetters("message", ["message", "isWaiting"])
  },
  components:{
      Circle2
  },
  mounted() {
    localStorage.removeItem('quickFilterType');
    localStorage.removeItem('quickFilterText');
    this.getmessageData().then(()=>{
      this.setChatStatus(1);
    });
  },

  methods: {
    ...mapActions("message", ["getmessageData"]),
    ...mapActions(["dismissRoomMsg"]),

    inbox(id) {
      this.dismissRoomMsg(id);
      this.$router.push({ path: "/dashboard/inbox", query: { room_id: id }}).catch(() => {});
    },
    setChatStatus(status){
      this.status = status;
      var tp = [];
      for(var i = 0; i < this.message.length; i ++){
        var t = 0;
        if(this.message[i].status == 0) t = 0;
        else if(this.message[i].status == 1 || this.message[i].status == 2) t = 1;
        else if(this.message[i].status == 3) t = 2;
        else if(this.message[i].status == 4) t = 3;
        if(t == status) tp.push(this.message[i]);
      }
      this.messageList = tp;
    }
  }
};
</script>
<style scoped>
.w-30-resp {
  width: 100% !important;
}
.border-left-yellow {
  border-left: 7px solid #ffe663;
}
.admin-messages-cont {
  background: #ffffff;
  border-radius: 10px;
}
.border-bottom-open {
  border-bottom: 2px solid#267CB2;
}
.profile-circle {
  width: 49px;
  height: 49px;
  border: 1.5px solid #dfe0eb;
}
@media (min-width: 576px) and (max-width: 768px) {
  .w-30-resp {
    width: 55% !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .w-30-resp {
    width: 50% !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .w-30-resp {
    width: 45% !important;
  }
}
@media (min-width: 1200px) {
  .w-30-resp {
    width: 30% !important;
  }
}
</style>