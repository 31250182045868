import axios from "axios";
import * as clientApi from '@/api/client';
import * as messageApi from '@/api/message';

export default {
    namespaced: true,

    state: {
        employeeInfo: {},
        tempFiles: [],
        isWaiting:false,
        isWaiting1:false,
        isWaiting2:false,
        isWaiting3: false,
        messageData: null,
        chatData: [],
        attach_files: [],  
        customerData: null,
        admins:[],
        customers:[],
        departs:[],
        selectedClient: null,
        newMsg: 0,
        eachMsg: {},
        qresponseData: [],
        qresponseOne: {},
        error: 428,
        isMyChat: true,
        myRooms: [],
        unReads: [],
        selectedRoom: null,
        employees: [],
    },

    getters: {
        employees: state => state.employees,
        selectedRoom: state => state.selectedRoom,
        myRooms: state => state.myRooms,
        unReads: state => state.unReads,
        employeeInfo: state => state.employeeInfo,
        isMyChat: state => state.isMyChat,
        tempFiles: state => state.tempFiles,
        attach_files: state => state.attach_files,
        isWaiting: state => state.isWaiting,
        isWaiting1: state => state.isWaiting1,
        isWaiting2: state => state.isWaiting2,
        isWaiting3: state => state.isWaiting3,  //transfer button
        error: state => state.error,
        message: state => state.messageData,
        chats: state => state.chatData,
        customer: state => state.customerData,
        admins: state => state.admins,
        customers: state => state.customers,
        departs: state => state.departs,
        selectedClient: state => state.selectedClient,
        newMsg: state => state.newMsg,
        eachMsg: state => state.eachMsg,
        qresponse: state => state.qresponseData,
        qresponseOne: state => state.qresponseOne
    },

    mutations: {
        setUnReads(state, unReads){
            state.unReads = unReads;
        },
        setEmployees(state, employees){
            state.employees = employees;
        },
        setSelectedRoom(state, selectedRoom){
            state.selectedRoom = selectedRoom;
        },
        setMyRooms(state, myRooms){
            state.myRooms = myRooms;
        },
        setEmployeeInfo(state, employee){
            state.employeeInfo = employee;
        },
        setIsMyChat(state, flag){
            state.isMyChat = flag;
        },
        setTempFiles(state, files){
            state.tempFiles = files;
        },
        setAttachFiles(state, attach_files) {
            state.attach_files = attach_files;
        },
        setqresponseData(state, qresponse) {
            state.qresponseData = qresponse;
        },
        setqresponseOne(state, qresponseOne) {
            state.qresponseOne = qresponseOne;
        },
        setError(state, error) {
            state.error = error;
        },
        setIsWaiting(state, isWaiting) {
            state.isWaiting = isWaiting;
        },
        setIsWaiting1(state, isWaiting) {
            state.isWaiting1 = isWaiting;
        },
        setIsWaiting2(state, isWaiting) {
            state.isWaiting2 = isWaiting;
        },
        setIsWaiting3(state, isWaiting) {
            state.isWaiting3 = isWaiting;
        },
        setNewMsg(state, newMsg) {
            state.newMsg = newMsg;
        },
        setEachMsg(state, eachMsg) {
            state.eachMsg = eachMsg;
        },
        setmessageData(state, message) {
            state.messageData = message;
        },
        setchatData(state, chat) {
            state.chatData = chat;
        },
        setcustomerData(state, customer) {
            state.customerData = customer;
        },
        setadminsData(state, admins) {
            state.admins = admins;
        },
        setcustomersData(state, customers) {
            state.customers = customers;
        },
        setdepartsData(state, departs) {
            state.departs = departs;
        },
        setSelectedClient(state, selectedClient) {
            state.selectedClient = selectedClient;
        }
    },

    actions: {
        async getAllMembers({commit}){
            commit('setError', 428);
            commit('setIsWaiting', true);
            try{
                const response = await clientApi.getAllMembers(JSON.parse(localStorage.getItem('adminInfo')).id);
                if (response.status === 200){
                    commit('setError', response.data.code);
                    commit('setIsWaiting', false);
                    commit("setadminsData", response.data.message.admins);
                    commit("setdepartsData", response.data.message.departs);
                    commit("setcustomersData", response.data.message.customers);
                }else{
                    commit('setError', 428);
                    commit('setIsWaiting', false);
                }
              }catch(e){
                commit('setError', 428);
                commit('setIsWaiting', false);
            }
        },

        async getmessageData({ commit }) {
            commit('setIsWaiting', true);
            try {
                var response = await messageApi.getMessageData();
                if(response.status == 200){
                    var rooms = response.data.messageData.rooms;
                    var employees = response.data.messageData.employees;
                    for(var i = 0; i < rooms.length; i ++){
                        for(var j = 0; j < employees.length; j ++){
                            if(rooms[i].from_id == employees[j].id || rooms[i].to_id == employees[j].id){
                                rooms[i].avatar = employees[j].profile_photo_path;
                                rooms[i].employee = employees[j].id;
                                break;
                            }
                        }
                    }
                    commit("setmessageData", rooms);
                    commit('setIsWaiting', false);
                }else{
                    commit('setIsWaiting', false);
                }
            } catch (error) {
                commit('setIsWaiting', false);
            }
        },

        async changeChatRoom({commit, state}, selRoom){
            commit('setSelectedRoom', selRoom.id);
            commit('setcustomerData', selRoom);
            commit('setError', 428);
            commit('setIsWaiting2', true);
            var response = await messageApi.changeChatRoom(selRoom.id);
            if(response.status == 200){
                commit('setError', response.status);
                commit('setIsWaiting2', false);
                var temp = [];
                var tempFiles = [];
                var chats = response.data.infos.chats;
                var room = response.data.infos.room; 
                for(var i = 0; i < chats.length; i ++){
                    var dir = 0;
                    if((chats[i].receive_to_id == 0 && room.from_id == state.employeeInfo.id) || (chats[i].receive_to_id == 1 && room.to_id == state.employeeInfo.id)){
                        dir = 1;
                    }else dir = 0;
                    if(chats[i].chat){
                        temp.push({
                            type: 0,
                            receive_to_id: dir,
                            chat: chats[i].chat
                        });
                    }else if(chats[i].attach_file){
                        temp.push({
                            type: 1,
                            receive_to_id: dir,
                            chat: chats[i].attach_file,
                            url: chats[i].temp_attach_file
                        });
                        tempFiles.push({
                            receive_to_id: dir,
                            attach_file: chats[i].attach_file,
                            temp_attach_file: chats[i].temp_attach_file
                        });
                    }
                }
                commit("setTempFiles", tempFiles);
                commit("setchatData", temp);
                commit("setAttachFiles", tempFiles);
            }else{
                commit('setError', 428);
                commit('setIsWaiting2', false);
            }
        },

        async markAsResolved({commit, state}, selRoom){
            try {
                var param = {
                    sender: JSON.parse(localStorage.getItem('adminInfo')).id,
                    room_id: selRoom
                }
                const response = await messageApi.markAsResolved(param);
                if (response.status === 200) {
                }
            } catch (error) {
            }
        },
        
        async getcustomerData({ commit, state }, room_id) {
            commit('setSelectedRoom', room_id);
            commit('setError', 428);
            commit('setIsWaiting', true);
            var response = await messageApi.getcustomerData(room_id);
            if(response.status == 200){
                commit('setError', response.status);
                commit('setIsWaiting', false);
                commit("setcustomerData", response.data.infos.customer);
                commit("setEmployeeInfo", response.data.infos.employee);
                commit("setEmployees", response.data.infos.employees);
                var tpRooms = response.data.infos.myRooms;
                var unReads = response.data.infos.unReadMsgs;
                var customers = response.data.infos.customers;
                
                for(var i = 0; i < tpRooms.length; i ++){
                    var other = (tpRooms[i].from_id == JSON.parse(localStorage.getItem('adminInfo')).id) ? tpRooms[i].to_id : tpRooms[i].from_id;
                    for(var j = 0; j < customers.length; j ++){
                        if(customers[j].id == other){
                            tpRooms[i]['name'] = customers[j].name;
                            tpRooms[i]['profile_photo_path'] = customers[j].profile_photo_path;
                            tpRooms[i]['type'] = 0;
                            break;
                        }
                        if(j < customers.length - 1) continue;
                        for(var k = 0; k < state.employees.length; k ++){
                            if(state.employees[k].id == other){
                                tpRooms[i]['name'] = state.employees[k].name;
                                tpRooms[i]['profile_photo_path'] = state.employees[k].profile_photo_path;
                                tpRooms[i]['type'] = 0;
                                break;
                            }
                        }
                    }
                }

                commit("setMyRooms", tpRooms);
                var tpUnReads = [];
                for(j = 0; j < tpRooms.length; j ++){
                    for(k = 0; k < unReads.length; k ++){
                        if(tpRooms[j].id == unReads[k].room_id) {
                            if(tpRooms[j].id == room_id){
                                tpUnReads.push({
                                    room_id: unReads[k].room_id,
                                    count : 0
                                });    
                            }else{
                                tpUnReads.push({
                                    room_id: unReads[k].room_id,
                                    count : unReads[k].cnt
                                });
                            }
                            break;
                        }
                        if(k == unReads.length - 1){
                            tpUnReads.push({
                                room_id: unReads[k].room_id,
                                count : 0
                            });
                        }
                    }
                    if(unReads.length == 0) 
                        tpUnReads.push({
                            room_id: tpRooms[j].id,
                            count : 0
                        }); 
                }
                commit("setUnReads", tpUnReads); 
                if(response.data.infos.employee.id != JSON.parse(localStorage.getItem('adminInfo')).id){
                    commit("setIsMyChat", false);
                }else{
                    commit("setIsMyChat", true);
                }
                var temp = [];
                var tempFiles = [];
                var chats = response.data.infos.chats;
                var room = response.data.infos.room; 
                for(i = 0; i < chats.length; i ++){
                    var dir = 0;
                    if((chats[i].receive_to_id == 0 && room.from_id == response.data.infos.employee.id) || (chats[i].receive_to_id == 1 && room.to_id == response.data.infos.employee.id)){
                        dir = 1;
                    }else dir = 0;
                    if(chats[i].chat){
                        temp.push({
                            type: 0,
                            receive_to_id: dir,
                            chat: chats[i].chat
                        });
                    }else if(chats[i].attach_file){
                        temp.push({
                            type: 1,
                            receive_to_id: dir,
                            chat: chats[i].attach_file,
                            url: chats[i].temp_attach_file
                        });
                        tempFiles.push({
                            receive_to_id: dir,
                            attach_file: chats[i].attach_file,
                            temp_attach_file: chats[i].temp_attach_file
                        });
                    }
                }
                commit("setTempFiles", tempFiles);
                commit("setchatData", temp);
                commit("setAttachFiles", tempFiles);
            }else{
                commit('setError', 428);
                commit('setIsWaiting', false);
            }
        },

        postchatFileData({ commit, state }, data) {
            commit('setError', 428);
            commit('setIsWaiting1', true);
            return messageApi.postchatFileData(data)
                .then(response => {
                    if (response.status === 200){
                        commit('setError', response.data.code);
                        commit('setIsWaiting1', false);
                        if(response.data.code == 200){
                            state.chatData.push({
                                type: 1,
                                receive_to_id: 1,
                                chat: response.data.message.attach_file,
                                url: response.data.message.temp_attach_file
                            });
                            state.attach_files.push({
                                receive_to_id: 1,
                                attach_file: response.data.message.attach_file,
                                temp_attach_file: response.data.message.temp_attach_file
                            });
                        }
                    }else{
                        commit('setError', 428);
                        commit('setIsWaiting1', false);
                    }
                })
                .catch((err) => {
                    commit('setError', 428);
                    commit('setIsWaiting1', false);
                });
        },
        postchatData({ commit }, data) {
            return messageApi.postchatData(data)
                .then(response => {
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        postKeyPressing({commit}, data){
            return messageApi.postKeyPressing(data)
            .then(response => {
            })
            .catch((err) => {
                console.log(err);
            });
        },
        async contactAdmin({commit}, data){
            commit('setError', 428);
            commit('setIsWaiting', true);
            try {
                const response = await messageApi.contactAdmin(data);
                commit('setError', response.status);
                commit('setIsWaiting', false);
                if (response.status === 200) {
                }else{
                }
              } catch (e) {
                commit('setError', 428);
                commit('setIsWaiting', false);
              }
        },
        async contactClient({commit}, data){
            commit('setError', 428);
            commit('setIsWaiting', true);
            try {
                const response = await messageApi.contactClient(data);
                commit('setError', response.status);
                commit('setIsWaiting', false);
                if (response.status === 200) {
                }else{
                }
              } catch (e) {
                commit('setError', 428);
                commit('setIsWaiting', false);
            }
        },
        
        async getQresponseData({ commit }) {
            var response = await messageApi.getQresponseData();
            if(response.status == 200) commit("setqresponseData", response.data.message);
            else commit("setqresponseData", []);
        },

        getQresponseOne({ commit }, id) {
            return messageApi.getQresponseOne(id)
                .then(response => {
                    commit("setqresponseOne", response.data.message);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async postQresponseData({ commit }, data) {
            try {
                var response = await messageApi.addResponse(data);
                if (response.status === 200) {
                    commit("setqresponseData", response.data.message);
                }else{
                }
              } catch (e) {
            }
        },

        async updateQresponseData({ commit }, data) {
            try {
                var response = await messageApi.updateResponse(data);
                if(response.status === 200){
                    commit("setqresponseData", response.data.message);
                }
            } catch (error) {
            }
        },

        // async copyQresponseData({ commit }, data) {
        //     try {
        //         var response = await messageApi.copyResponse(data);
        //         if(response.status === 200){
        //             commit("setqresponseData", response.data.message);
        //         }
        //     } catch (error) {
        //     }
        // },

        async delQresponseData({ commit }, data) {
            try {
                const response = await messageApi.deleteResponse(data);
                if(response.status == 200){
                    commit("setqresponseData", response.data.message);
                }
              } catch (e) {
            }
        },

        async transferChat({commit, state}, param){
            try {
                commit('setError', 428);
                commit('setIsWaiting3', true);
                const response = await messageApi.transferChat(param);
                if (response.status === 200){
                    commit('setError', response.data.code);
                    commit('setIsWaiting3', false);
                }else{
                    
                }
            } catch (e) {
                commit('setIsWaiting3', false);
            }
        }
    }
};