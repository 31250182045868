<template>
<div class="w-90 d-flex justify-content-center align-items-center flex-column mb-5">

    <div class="w-90 d-flex justify-content-end align-items-center pt-9">
      <button
        class="outline-none justify-content-center d-flex align-items-center"
        @click="ExportCSVOrder"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="mx-2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 12V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V12"
            stroke="#6E7F97"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 6L12 2L8 6"
            stroke="#6E7F97"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 2V15"
            stroke="#6E7F97"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="mb-0">{{$t('adminOrdersExportToCsv')}}</p>
      </button>
    </div>
    <Circle2 v-if="isWaiting" class="mt-8"></Circle2>
    <OrdersTable v-else
        :data="orders"
        :total-pages="Math.ceil(orders.length / perPage)"
        :total="orders.length"
        :per-page="perPage"
        :current-page="currentPage"
        @pagechanged="onPageChange"
        @perPageChanged="onPerPageChange"
        @searchChanged="onSearchChange"
        @filterChanged="onFilterChange"
        @sortMethodChanged="onSortMethodChanged"
    />
</div>
</template>
<script>
import OrdersTable from './ordersTable'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {Circle2} from 'vue-loading-spinner'
export default {
    name:"AdminOrders",
    components:{
        OrdersTable,
        Circle2
    },
    data(){
      return {
        currentPage : 1,
        perPage : 10,
        filters: ['accepted', 'accepted', 'accepted', 'accepted', 'accepted', 'accepted', 'accepted'],
        searchKey: ''
      }
    },
    mounted(){   
      var param = {
        'filterType' : localStorage.quickFilterType,
        'filterText' : localStorage.quickFilterText
      }
      this.getAllOrders(param);
    },
    computed:{
      ...mapGetters('order', ['selected', 'orders', 'totalOrders', 'filterType', 'filterText', 'isWaiting']),
    },
    methods: {
      ...mapActions('order', ['getAllOrders', 'searchOrders', 'exportCSV']),
      ...mapMutations('order', ['setOrders']),
      onPageChange(page){
        this.currentPage = page;
      },
      onPerPageChange(count){
        this.perPage = count;
        this.currentPage = 1;
      },
      onSearchChange(txt){
        this.searchKey = txt;
        this.filterValues();
      },
      onSortMethodChanged(index){
        if(index == 0){
          this.orders.sort(this.compare0);
          this.totalOrders.sort(this.compare0);
        }else if(index == 1){
          this.orders.sort(this.compare1);
          this.totalOrders.sort(this.compare1);
        }else if(index == 2){
          this.orders.sort(this.compare2);
          this.totalOrders.sort(this.compare2);
        }else if(index == 3){
          this.orders.sort(this.compare3);
          this.totalOrders.sort(this.compare3);
        }
      },
      onFilterChange(filters){
        this.filters = filters;
        this.filterValues();
      },
      filterValues(){
        // for(var i = 0; i < this.filters.length; i ++){
        //   if(this.filters[i] == 'accepted') break;
        //   if(i == this.filters.length - 1) {
        //     // this.setOrders(this.orders);
        //     return;
        //   }
        // }
        var temp = [];
        for(var i = 0; i < this.totalOrders.length; i ++){
          if(this.totalOrders[i].phone.toLowerCase().includes(this.searchKey) || this.totalOrders[i].country.toLowerCase().includes(this.searchKey) || this.totalOrders[i].companyname.toLowerCase().includes(this.searchKey) || this.totalOrders[i].ordernumber.toLowerCase().includes(this.searchKey)) {
            var st = this.totalOrders[i].status;
            if(this.filters[st - 1] == 'accepted') temp.push(this.totalOrders[i]);
          }
        }
        this.setOrders(temp);
      },
      ExportCSVOrder(e){
        this.exportCSV();
      },
      compare0( a, b ) {
        if ( a.id < b.id ){
          return 1;
        }
        if ( a.id > b.id ){
          return -1;
        }
        return 0;
      },
      compare1( a, b ) {
        if ( a.id < b.id ){
          return -1;
        }
        if ( a.id > b.id ){
          return 1;
        }
        return 0;
      },
      compare2( a, b ) {
        if ( a.ordernumber < b.ordernumber ){
          return -1;
        }
        if ( a.ordernumber > b.ordernumber ){
          return 1;
        }
        return 0;
      },
      compare3( a, b ) {
        if ( a.ordernumber < b.ordernumber ){
          return 1;
        }
        if ( a.ordernumber > b.ordernumber ){
          return -1;
        }
        return 0;
      },
    }
}
</script>