import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);
const messages = {
    'en':
    {
        login: "Login", signup: "Register", home: "Home", product: "Product", about: "About", contact: "Contact",adminOrdersDetailsWriteYourQuestion:"Write your question here",
        randomSmall: `Lorem ipsum dolor sit amet consectetur.`,adminOrdersDetailsYourEnquiry:"Your Enquiry here",
        randomLarge: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint unde inventore fuga.",
        tryForFree: "Try for Free!", catalogs: "Catalogs", services: "Our Services",
        randomHuge: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor inaute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu",
        gallery: "Gallery", galleryText: "We focus on ergonomics and meeting you where you work. It's only a keystroke away.",
        seeMore: "See More", partners: "Our partners",
        partnerText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnauptate velit esse cillum dolore eu",
        partnerBtn: "All Partners", clients: "What clients say",
        clientCard: "Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun t utamet, consectetur adipiscing elit, sed do eiusmod temporincididunt utLorem ipsum dolor sit amet, consectetur adipiscing elit, s",
        designer: "Designer",
        clientCardHuge: "Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun t utamet, consectetur adipiscing elit, sed do eiusmod temporincididunt utLorem ipsum dolor sit amet, consectetur adipiscing elit, sLorem ipsum dolor dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun t ut",
        containerOffer: "Have you tried our Container Offer?", address: "Guanghzou,China", contactNo: "1885265612",
        main: "Main", copyRight: "copyright Keerki 2020", resources: "Resources", freeCatalog: "Free Catalog", blog: "Blog",
        request: "Request", updates: "Updates",
        signUpHeading: "Register Your Account!",
        signUpdummy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed",
        yourName: "Your Name", fullName: "Full Name", email: "Email address*", enterEmail: "Enter email address", phone: "Phone Number*", phonePlaceholder: "Phone Number", createPassword: "Create Password*", enterPassword: "Enter Your Password",
        confirmPassword: "Confirm Password*", confirmPasswordPlaceholder: "Confirm Password"
        , termsAndConditions: "I agree to term & conditions", registerAccountBtn: "Register Account", or: "OR",
        registerWithGoogle: "Register With Google", registerWithFacebook: "Register With Facebook"
        , back: "Back", loginHeading: "Login to your account", loginDescription: "Login to your account to manage all your information and details", loginEmail: "Email", enterYourEmail: "Enter your email address",
        password: "Password*", forgotPassword: "Forgot Password", loginWithSocialMedia: "Login with social media", noAccount: "No account?Create an account now",
        forgotPasswordHeading: "Forgot your password?", forgotPasswordDescription: "Enter your email that you used to create your account and we will reset your passeord for you",
        requestNewPassword: "Request new password", changePassword: "Change Password", changePasswordDescription: "Password must contain atleast 8 characters and include atleast one:",
        lowercaseLetter: "-Lowercase letter", uppercaseLetter: "-Uppercase letter", number: "-Number", specialCharacter: "-Special Character", oldPassword: "Old password", newPassword: "New Password", confirmNewPassword: "Confirm password", changePasswordBtn: "Change password",
        activateYourAccount: "Activate your account", activateAccountDescription: "Please check the email send to your email to activate your account and start using your account. Thank you!", invalidEmail: "Invalid Email or password", contactKeerki: "Contact Keerki if you need help",
        sessionExpire: "Your session expired due to inactivity. Please log in again.", loginToOtherAccount: "You’ve been logged out because of log in on another device.",
        technicalDifficulty: " Technical difficulty. Sorry for the inconvenience; please try again.", logoutSuccessful: "Log out successful.", accountDeactivated: "You account has been deactivated.", accountSuspended: "You account has been suspended.",
        setNewPhoto: "Set new photo", accountName: "Name:", accountEmail: "EMAIL:", accountPhone: "PHONE:", accountPassword: "PASSWORD:",
        addCompanyInfo: "Add your company info", companyName: "Company Name", companyAddress: "Address", companyPhone: "Phone", companyPersonIncharge: "Person in Charge", companyCountry: "Country", companySave: "save", companyCancel: "cancel", cardClickHere: "Click here", overViewOrderHistory: "Order History",
        overViewNotification: "Notification", overViewAccount: "Account", overViewOrderForm: "Order Form", help: "Help", companyInfo: "Company info", companyInfoName: "Company Name", companyInfoCountry: "Country", companyInfoContactPerson: "Contact Person", companyInfoCity: "City", companyInfoProvince: "Province", companyInfoPostCode: "Post Code", companyInfoDist: "Dist&Street",
        companyInfoPhone: "Phone Number", companyInfoEmail: "Email", companyInfoAddLogo: "Add logo", companyInfoDropFile: "or drop it right here", shippingInfo: "Shipping Info", shippingInfoPort: "Port", shippingInfoCountry: "Country", currencySAR: "SAR", currencyAED: "AED", currencyUSD: "USD", currencyEURO: "EURO", aboutProducts: "About Products", productUnitPrice: "Unit Price",
        productQuantity: "Quantity", productItem: "Item", productDescription: "Description", remarks: "Remarks", remarksOther: "Other", remarksTradingTerm: "Trading Term", paymentTerm: "Payment Term", paymentTermLC: "LC", paymentTermTT: "TT", shippingMethod: "Shipping Method", shippingMethodBySea: "BY SEA", shippingMethodByAir: "BY AIR", deliveryTime: "Delivery Time",
        deliveryTime30Days: "30 DAYS", deliveryTime20Days: "20 DAYS", deliveryTime10Days: "10 DAYS", companyInfoOthers: "Others", companyInfoSave: "Save and Continue", companyInfoReset: "Reset", formHeaderAccountDetails: "Account Details", formPreviewEdit: "Edit", formPreviewView: "View"
        , formPreviewCancel: "Cancel", formPreviewSend: "Send", downloadYourCatalog: "Download your catalog", catalogName: "Name*", catalogEmail: "Email", catalogPhone: "Phone", catalogDownloadBtn: "Download", allMessages: "All Messages", newMessages: "New Messages", messagesUnReadBtn: "Unread", messagesReadBtn: "Read", messageOrderShipped: "Order Shipped", message1Minute: "1 minute ago", messageView: "View", messageDismiss: "DISMISS", messageNewHadi: "New Message From Hedi", messageOrderDelivered: "Order Delivered",
        allNotifications: "All Notifications", newNotifications: "New Orders", notificationReadBtn: "Read", notificationUnReadBtn: "Unread", notification1Minute: "1 minute ago", notificationView: "View", notificationDismiss: "DISMISS", notificationOrderShipped: "Order Shipped", notificationNewHedi: "New Message From Hedi", notificationOrderDelivered: "Order Delivered", quoteOrders: "Quote Orders", serviceOrders: "Service Orders", shippingOrders: "Shipping Orders", orderFormHistory: "Order forms history", orderSearchByNumber: "Search by order No.", orderSelectAll: "Select all", orderDownloadAll: "Download All", orderStatus: "Status", orderDate: "Date",
        orderPrice: "Price", orderCategories: "Categories", orderSomethingElse: "Something else", inProgressBtn: "IN PROGRESS", doneBtn: "DONE", waitingBtn: "WAITING", canceledBtn: "CANCELED", orderDownload: "Download", keerki: "Keerki", overview: "Overview", orderHistory: "Order History", account: "Account", notifications: "Notifications", messages: "Messages", settings: "Settings", logout: "Logout", continueSession: "Continue Session", logoutTime: "1 minute 52 seconds", automaticLogout: "You will be automatically logged out in", viewInTemplates: "View in templates", popupMessages: "Messages", popupDaysAgo: "2 days ago", popupNewMessages: "You have 3 new Messages", popupDismiss: "DISMISS", popupViewAll: "view all",
        popupNotifications: "Notifications", popupNewNotifications: "You have 3 new notifications",
        dashboardHeading: "Dashboard", dashboardDescription: "Get summary of your weekly online transactions here.", newUserWelcomeHeading: "Welcome to Keerki.", newUserWelcomeDescription: "A new experience designed to help you manage your orders faster!", newUserHistoryPageHeading: "View all orders in one place!", newUserHistoryPageDescription: "Check all  your orders and details in orders history page and check their status.", newUserOrderTemplateHeading: "View your orders in templates ", newUserOrderTemplateDescription: "Even better and faster you can view all your status and orders in templates", newUserAccountInformationHeading: "Manage your account information and add your company details",
        newUserAccountInformationDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ", newUserNotificationHeading: "Send messages and view notification within one screen", newUserNotificationDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo",
        newUserNext: "Next", newUserPrev: "Previous", newUserDone: "Done",
        notificationHeading: "Notifications", notificationDescription: "Manage your notifications", messagesHeading: "Messages", messagesDescription: "All messages history", orderHistoryHeading: "Order History", orderHistoryDescription: "Get summary of your weekly online transactions here.", accountHeading: "Account", accountDescription: "Change your account details", orderFormHeading: "Order Form", orderFormQuote: "Quote", orderFormService: "Service", orderFormShipping: "Shipping",
        language: "LANGUAGE:", rtlHelper: "help", functionalityError: "Functionality currently unavailable", sorryText: "Sorry for the inconvenience", contentError: "Content currently unavailable.", tryLater: "We’re sorry; please try again later.",
        ok: "Ok", selectLanguage: "Please Select a Language", ksa: "Saudia Arabia", uae: "United Arab Emirates", qatar: "Qatar", bahrain: "Bahrain", oman: "Oman", lebanon: "Lebanon", jordan: "Jordan", egypt: "Egypt", germany: "Germany", otherLanguages: "Others",
        setLanguage: "Language", shippingTo: "Ship To", serviceDetails: "Service Details", factoryContact: "Factory Contacts", whatGoodsShipping: "What goods are you shipping?", entryFactoryContact: "Enter factory contact", listGoods: "List all your goods",
        providePackingList: "Please provide the packing list or upload a file", height: "Height", width: "Width", weightKG: "Weight in KG", uploadPackingList: "Upload packing list", uploadInvoice: "Upload invoice", submit: "Submit",
        messageReply: "Reply", openMessages: "Open Messages", messagesOpen: "Open", messagesClosed: "Closed", messageSubject: "Message Subject", orderTypeNumber: "Order type - Order number",
        inboxAll: "All", inboxFile: "File", inboxImages: "Images", inboxSearch: "Quick Search", inboxMessage: "Message", inboxSend: "SEND", inboxRating: "Rate Your Chat Experience", inboxThanks: "Thank you ^.^", inboxImprove: "Please tell us what we can improve",
        servicesProducts: "Products", servicesConsultation: "Consultation", servicesInformation: "Information", orderProducts: "Product", orderDeselect: "Deselect all", orderNumber: "Order Number", dateUpdated: "Date Updated",
        //admin
        loginUserName: "USERNAME", loginUserPassword: "PASSWORD", loginBtn: "LOGIN", contactAdministrator: "Contact Administrator", quickSearch: "Quick Search", adminContact: "Contact", adminContactPopUpHeading: "Who would you like to send a message to?", adminContactPopUpAdministrators: "Administrators", adminContactPopUpClient: "Client",
        contactClient: "Contact a Client", searchForClientName: "Search for client name", clientMessageSubject: "Subject", clientMessage: "Write your message here", clientDragAndDrop: "Drag and drop or", clientBrowse: "browse", clientSubmit: "Submit",
        contactEmployAdministrator: "Contact an employee", searchForEmployName: "Search for Employee", selectDepartment: "Select Department", administratorOrderNo: "Order Number", administratorMessageSubject: "Subject", administratorMessage: "Write your message here", administratorDragAndDrop: "Drag and drop or", administratorBrowse: "browse", administratorUploadFileTypes: " Please upload a PDF, PNG, or JPG image with similar height and width that is less than 5mb.", administratorSubmit: "Submit", administratorUrgent: "Urgent",
        adminClients: "Clients", adminOrders: "Orders", adminRecent: "Recent", adminToday: "Today", adminYesterday: "Yesterday", clientsSearchFor: "Search for...", adminNoOfOrders: "No. of Orders", exportCsv: "Export CSV", allClients: "All Clients", clientsSort: "Sort", clientsFilter: "Filter", clientsAccountStatus: "Account Status", clientsPhone: "Phone", clientsDateJoined: "Date Joined", clientsCountry: "Country", clientsName: "Name", clientBtnActive: "ACTIVE", clientBtnInactive: "INACTIVE", clientEdit: "Edit", clientView: "View", clientDelete: "Delete", clientLoginAs: "Login as", clientsTableRowsPerPage: "Rows per page",
        clientDetailsName: "Name:", clientDetailsAddress: "Address:", clientDetailsCompanyName: "Company Name:", clientDetailsEmail: "Email:", clientDetailsPhone: "Phone:", clientDetailsWhatsapp: "Whatsapp:", clientDetailsBalance: "Balance:", clientDetailsMemberShip: "Membership:", clientMemberShipVip: "VIP", clientDetailsEdit: "Edit", clientDetailsExportToCsv: "Export CSV", clientDetailsAllOrders: "All Orders", clientDetailsSearchOrders: "Search Orders", clientDetailsOrdersFilter: "Filter", clientDetailsOrdersSort: "Sort", clientDetailsOrdersStatus: "Status", clientDetailsOrdersDate: "Date", clientDetailsOrdersPhoneNumber: "Phone Number", clientDetailsOrdersTotal: "Order Total", clientDetailsOrderNo: "Order Number", clientDetailsOrdersCountry: "Country",
        clientDetailsOrdersCompany: "Company", clientDetailsOrdersEdit: "Edit", clientDetailsOrdersCopy: "Copy", clientDetailsOrdersDelete: "Delete", clientDetailsOrdersInvoice: "Invoice", clientDetailsOrdersNewBtn: "NEW", clientDetailsOrdersDeliveredBtn: "DELIVERED", clientDetailsOrdersCancelledBtn: "CANCELLED", clientDetailsOrdersShippedBtn: "SHIPPED", clientDetailsOrdersInprogressBtn: "IN PROGRESS", clientDetailsOrdersROwsPerPage: "Rows per page",
        adminNotificationsAllNotifications: "All Notifications", adminNotificationsNewOrders: "New Orders", adminNotificationsUnRead: "UNREAD", adminNotificationsRead: "READ", adminNotificationsViewOrder: "View Order", adminNotificationsDismiss: "DISMISS", adminAccountSetNewPhoto: "Set new photo", adminAccountName: "Name:", adminAccountEmail: "Email:", adminAccountPhone: "Phone:", adminAccountPassword: "Password", adminAccountUserName: "Username", adminAccountCannotEdit: "CANNOT EDIT", adminAccountCancel: "cancel", adminAccountSave: "save", adminAccountOldPassword: "Old Password", adminAccountNewPassword: "New Password", adminAccountConfirmPassword: "Confirm Password", adminAccountFullName: "Full Name", adminAccountPhoneNumber: "Phone Number",
        adminOrdersAllOrders: "All Orders", adminOrdersExportToCsv: "Export CSV", adminOrdersSearchOrders: "Search Orders", adminOrdersFilter: "Filter", adminOrdersSort: "Sort", adminOrdersStatus: "Status", adminOrdersDate: "Date", adminOrdersPhone: "Phone Number", adminOrdersTotal: "Order Total", adminOrdersNumber: "Order Number", adminOrdersCountry: "Country", adminOrdersCompany: "Company", adminOrdersNewBtn: "NEW", adminOrdersDeliveredBtn: "DELIVERED", adminOrdersCancelledBtn: "CANCELLED", adminOrdersInprogressBtn: "IN PROGRESS", adminOrdersShippedBtn: "SHIPPED", adminOrdersRowsPerPage: "Rows per page", adminOrdersEdit: "Edit", adminOrdersCopy: "Copy", adminOrdersDelete: "Delete", adminOrdersInvoice: "Invoice", adminOrdersDetailsName: "Name:", adminOrdersDetailsAddress: "Address:", adminOrdersDetailsEmail: "Email:", adminOrdersDetailsCompanyName: "Company Name:", adminOrdersDetailsPhone: "Phone:", adminOrdersDetailsWhatsapp: "Whatsapp:", adminOrdersDetailsEdit: "Edit",
        adminOrdersDetailsInregardWithThisOrder: "In regard with this order:", adminOrdersDetailsAnyIssue: "If you have any issues regarding this issue. Please contact the admin and we will resolve the issue as soon as possible.", adminOrdersDetailsSendAMessage: "Send a message", adminOrdersDetailsReasonForCancellation: "Reason for cancellation", adminOrdersDetailsWriteQuestion: "Write your question here",
        adminOrdersDetailsOrderNumber: "Order Number:", adminOrdersDetailsStatus: "Status", adminOrdersDetailsTags: "Tags", adminOrdersDetailsRating: "Rating", adminOrdersDetailsDownload: "Download", adminOrdersDetailsView: "View", adminOrdersDetailsRecived: "Recived", adminOrdersDetailsTransfer: "Transfer", adminOrdersDetailsPayNow: "Pay Now", adminOrdersDetailsQuestion: "Question", adminOrdersDetailsDone: "Done", adminOrdersDetailsCancel: "cancel", adminOrdersDetailsSendSms: "Send SMS", adminOrdersDetailsWaiting: "Waiting", adminOrdersDetailsShipped: "Shipped", adminOrdersDetailsInprogress: "In Progress", adminOrdersDetailsReasonForCancelling: "Reason for cancellation*", adminOrdersDetailsMustProvideReason: "You must provide a reason to cancel", adminOrdersDetailsWriteYourText: "Write your text here", adminOrdersDetailsIgnore: "Ignore", adminOrdersDetailsSubmit: "Submit",
        adminMessagesOpen: "Open", adminMessagesPending: "Pending", adminMessagesResolve: "Resolve", adminMessagesClose: "Close",
        adminMessagesHeading: "Messages", adminMessagesSearch: "Search", adminMessagesOrderMessages: "ORDER MESSAGES", adminMessagesCompanyContact: "COMPANY CONTACTS", adminMessagesSubjects: "Message subject", adminMessagesVIp: "VIP", adminMessagesTransferTheChat: "Transfer the chat", adminMessagesMarkAsResolved: "Mark as resolved", adminMessagesPlaceholder: "Message", adminMessagesSend: "SEND", adminMessagesAll: "All", adminMessagesFile: "File", adminMessagesImages: "Images", adminMessagesOrders: "Orders", adminMessagesQuickSearch: "Quick Search",
        adminMessagesCustomQuickResponse: "Custom Quick Responses", adminMessagesEdit: "Edit", adminMessagesCopy: "Copy", adminMessagesDelete: "Delete", adminMessagesSelectAll: "Select All", adminMessagesDeselectAll: "Deselect All", adminMessagesAdd: "ADD",
        adminDashboardHeading: "Dashboard", adminDashboardUserAdmin: "Admin", adminDashboardKeerkiDashboard: "Keerki Dashboard", adminDashboardClientsBtn: "Clients", adminDashboardOrdersBtn: "Orders", adminDashboardContactBtn: "Contact", adminDashboardNotificationsBtn: "Notifications", adminDashboardMessagesBtn: "Messages", adminDashboardSettingsBtn: "Settings", adminDashboardLogoutBtn: "Logout", adminDashBoardQuickSearch: "Quick Search", adminDashboardNotificationNumberOfNotifications: "You have 3 new orders",
        adminDashboardMyaccount: "My Acccount", adminDashboardAccountDetails: "Account Details", adminDashboardChangePassword: "change Password",
        adminSettingsSessionTimeOut: "Session Time out", adminSettingsHours: "Hours", adminSettingsMinutes: "Minutes", adminSettingsAutoSendSms: "Auto send SMS reminders to clients", adminSettingsDays: "Days", adminSettingsDeactivateEmployee: "Deativate employee due to inactivity", adminSettingsDeactivateUser: "Deactivate user for short time", adminSettingsRemoveUser: "Remove user from Admin dashboard", adminSettingsEmployeeSetting: "Employees Settings", adminSettingsAddNewEmploy: "Add New Employee", adminSettingsRegisterEmployee: "Register an Employee", adminSettingsName: "*Name", adminSettingsFirstName: "First", adminSettingsLastName: "Last", adminSettingsUsername: "*Username", adminSettingsUsernamePlaceholder: "Username", adminSettingsPassword: "*Password", adminSettingsPassowordPlaceholder: "Password", adminSettingsConfirmPassword: "*Confirm Password", adminSettingsConfirmPasswordPlaceholder: "Confirm Password", adminSettingsEmail: "*Email", adminSettingsEmailPlaceholder: "Email", adminSettingsConfirmEmail: "*Confirm Email Address", adminSettingsConfirmEmailAddressPlaceholder: "Confirm Email Address",
        adminSettingsWorkPhone: "*Work Pone", adminSettingsEnterPhone: "Enter your phone Number", adminSettingsUserTimeZone: "*Users time zone", adminSettingsRole: "*Role", adminSettingsRoleAdmin: "Admin", adminSettingsCancel: "Cancel", adminSettingsSubmit: "Submit", adminSettingsPassowordDescription: "Password must be different from last 3 passwords. Password must be at least 8 characters and include at least one lowercase letter, uppercase letter, number, and special character.", adminSettingsEditEmployee: "Edit Employee", adminSettingsViewAllEmployees: "View All Employees", adminSettingsEditEmployeeName: "*Name", adminSettingsEditEmployeeFirst: "First", adminSettingsEditEmployeeLast: "Last", adminSettingsEditEmployeePassword: "Password:", adminSettingsEditEmployeeChangePassword: "Change Password", adminSettingsEditEmployeeChangePasswordDescription: "Password must be different from last 3 passwords. Password must be at least 8 characters and include at least one:", adminSettingsLastLogin: "Last login:", adminSettingsAccountCreated: "Account Created:", adminSettingsLastModified: "Last Modified:", adminSettingsEditEmployeeEmail: "*Email", adminSettingsEditEmployeeEmailPlaceHolder: "first.last@keerki.com", adminSettingsEditEmployeeWorkPone: "Work Phone",
        adminSettingsEditEmployeeEnterPhone: "Enter your phone number", adminSettingsEditEmployeeUserTimeZone: "*User's time zone", adminSettingsEditEmployeeRole: "*Role", adminSettingsEditEmployeeAccountStatus: "*Account Status", adminSettingsEditEmployeeActve: "Active", adminSettingsEditEmployeeInactive: "Inactive", adminSettingNotifyAllEMployees: "Notify All Employees", adminSettingClientSettings: "Clients Settings", adminSettingAddNewClient: "Add New Client", adminSettingsNofifyAllClients: "Notify all clients by notification", adminSettingsSendSms: " Send SMS to all clients", adminSettingActivateClient: "Activate Client Account", adminSettingSendEmail: "Send Email to All Clients", adminSettingSuspendAccount: "Suspend Client Account", adminAllKeerkiEmployees: "All Keerki Employees", adminEmployeeDate: "Date", adminEmployeeStatus: "Status", adminEmployeesReplies: "replies", AdminEmployeesEditAccountBtn: "Edit Account", AdminEmployeesActive: "Active",
        adminSendSms: "Send SMS", adminAllClientsSelected: "All clients Selected", adminAllClients: "All Clients", adminAllButExcluded: "All but Excluded", adminExclueName: "Exclude Client Name", adminVipClients: "VIP CLients", adminNoUsers: "New users-no orders", adminSearch: "Search", adminEnterYourSms: "Enter your sms here", adminSend: "Send", adminSendEmail: "Send Email",
        editPermissionsRoles: "Roles", editPermissionsAddNewRoles: "Add new role", editPermissionsDuplicateSelectedRoles: "Duplicate selected role", editPermissionsDeleteSelectedRole: "Delete select role", editPermissionsUsersAreMoved: "Users are moved to default role and remain active", editPermissionsTechEmployee: "Tech Employee", editPermissionsFinancialEmployee: "Financial Employee", editPermissionsCustomerService: "Customer Service", editPermissionsPermissions: "Permissions", editPermissionsSensitive: "Sensitive", editPermissionsClientDetails: "Client details", editPermissionsName: "Name", editPermissionsAddress: "Address", editPermissionsEmail: "Email Address", editPermissionsPhone: "Phone Number", editPermissionsBalance: "Balance", editPermissionsOrders: "Orders", editPermissionsViewOrders: "View Orders", editPermissionsSendSms: "Send SMS", editPermissionsDone: "Done", editPermissionsTransfer: "Transfer", editPermissionsCancel: "Cancel", editPermissionsMessage: "Messages", editPermissionsViewMessages: "View Messages", editPermissionsReplyToMessages: "Reply to Messages", editPermissionsNotifications: "Notifications", editPermissionsView: "View", editPermissionsClientStatus: "Client status", editPermissionsMoreDetails: "More details about it", editPermissionsAccountStatus: "Account Status",
        editPermissionsEmployeesAndUserManagment: "Employees and User Managment", editPermissionsCreateUser: "Create User", editPermissionsManageUser: "Manage User", editPermissionsActivate: "Activate, suspend, & delete clients users or edit their profile.", editPermissionsUsers: "Users", editPermissionsMoveUsers: "Move users to this role", editPermissionsMoveUser: "Move user(s) back to default role", editPermissionsSearch: "Search", editPermissionsFirstLast: "First Last Name",
        notifyNotifyAll: "Notify All Keerki Employees", notifyAllEmployeesSelected: "All employees selected", notifySelectedExcludedEmployee: "Selected excluded employee name", notifySearchDepartments: "Search Departments", notifySearchAndExcludeDepartments: "Search and Exclude Departments", notifyAllEmployees: "All Employees", notifyAllButExclude: "All but Exclude", notifyDepartment: "Department", notifyExcludeDepartment: "Exclude department", notifySearch: "Search", notifyExcludedEmployeeName: "Excluded Employee Name", notifySelectedExcludedDepartment: "Selected excluded department",
        notifySubject: "Subject", notifySendToAllEmployees: "Send to all employees a message", notifySend: "Send", permissionCanEdit: "Can View", permissionsCanEdit: "Can Edit", permissionsHide: "Hide"
        , sortNewToOld: "New To Old", sortOldToNew: "Old To New", sortHighToLow: "High To Low", sortLowToHigh: "Low To High", filterCancelled: "Cancelled", filterWaiting: "Waiting", filterDelivered: "Delivered", filterShipped: "Shipped", filterInProgress: "In Progress"
        , adminDashboardSearchByUserName: "Search By UserName", adminDashboardSearchByEmail: "Search By Email", adminDashboardSearchByDate: "Search By Date", adminDashboardSearchByOrderNumber: "Search By Order Number", adminDashboardSearchByProductName: "Search By Product Name", adminDashboardSearchByOrderStatus: "Search By Order Status", adminDashboardSearchByClientAddress: "Search By Client Address", adminDashboardSearchByClientPhone: "Search By Client Phone", adminDashboardSearchByClientCompanyName: "Search By Client CompanyName", adminDashboardSearchByClientMemberShip: "Search By Client MemberShip", adminDashboardSearchByFirstLastName: "Search By First & Last Name"
        , issueHasBeenResolved: "Issue has been Resloved", requestRatingFromClient: "Request Rating From Client",adminVIPClientsSelected:"Search VIP Clients", adminNewUserSelected:"New users-no orders"
        , adminMessageDeletePopup: "Are you sure you want to delete this response",adminMessageDeleteNo:"No",adminMessageDeleteYes:"Yes",signupLoginNow:"Got an account? Login now",adminSettingsDepartment:"*Department",orderSearchByName:"Search by name",orderSearchByDate:"Search by Date",
        serviceHistory:"Service Orders History",quotesHistory:"Quote Orders History",shippingHistory:"Shipping Orders History",
        adminOrdersEnquiry:"Your Enquiry here",intClient:"International Client",localClient:"Local Client",notifyAllClients:"Notify All Keerki Clients",notifyAllClientsSelected:"Notify All Clients",notifyNotifyAllClients:"All Clients",notifySelectedExcludedClient:"Selected excluded client name",notifyExcludedClientName:"Excluded Client Name",notifySendToAllClients:"Send to all clients a message",adminSettingsBalance:"Balance",adminSettingsBalancePlaceHolder:"Current Balance",adminSettingsAddress:"*Address",adminSettingsAddressPlaceHolder:"Address",adminSettingsPhone:"Phone",adminSettingsCompanyPlaceHolder:"Company Name",adminSettingsCompany:"*Company",adminSettingsMemberShip:"*Membership",adminSettingsRegisterClient:"Register a Client",
        Afghanistan:"Afghanistan",Albania:"Albania",Algeria:"Algeria",AmericanSamoa:"American Samoa",Andorra:"Andorra",Angola:"Angola",Anguilla:"Anguilla",Antarctica:"Antarctica",AntiguaBarbuda:"Antigua and Barbuda",Argentina:"Argentina",Armenia:"Armenia",Aruba:"Aruba",Australia:"Australia",Austria:"Austria",Azerbaijan:"Azerbaijan",Bahamas:"Bahamas",Bahrain:"Bahrain",Bangladesh:"Bangladesh",Barbados:"Barbados",Belarus:"Belarus",Belgium:"Belgium",Belize:"Belize",Benin:"Benin",Bermuda:"Bermuda",Bhutan:"Bhutan",Bolivia:"Bolivia",Bonaire:"Bonaire",Bosnia:"Bosnia",Botswana:"Botswana",Bouvet:"Bouvet",Brazil:"Brazil",BritishIndian:"BritishIndian",Brunei:"Brunei",Bulgaria:"Bulgaria",Burkina:"Burkina",
        BurundiThe:"Burundi The",Burundi:"Burundi",Cambodia:"Cambodia",Cameroon:"Cameroon",Canada:"Canada",Cayman:"Cayman",Africa:"Africa",Chad:"Chad",Chile:"Chile",China:"China",Christmas:"Christmas",Cocos:"Cocos",Colombia:"Colombia",Comoros:"Comoros",Congo:"Congo",CongoThe:"Congo The",Cook:"Cook",Costa:"Costa Rica",Croatia:"Croatia",Cuba:"Cuba",Curaçao:"Curaçao",Cyprus:"Cyprus",Czechia:"Czechia",
        Côte:"Côte De Ivora",CôteDE:"Côte",Djibouti:"Djibouti",Dominica:"Dominica",Dominican:"Dominican",Egypt:"Egypt",Salvador:"Salvador",Equatorial:"Equatorial",Eritrea:"Eritrea",Estonia:"Estonia",Eswatini:"Eswatini",Ethiopia:"Ethiopia",Falkland:"Falkland",Faroe:"Faroe",Fiji:"Fiji",
        resetForm:"Reset the form?",updateData:"Update Data?",adminOrdersWaitBtn:"WAITING",adminOrdersPaidBtn:"PAID",adminOrdersDoneBtn:"DONE",typing: "is typing...",
        filterNew:"New",filterPaid:"Paid",filterDone:"Done",filterInactive:"Inactive", filterActive:"Active", adminLoginAsClient:"Login as",adminRemindLoginEmail:"Send client login email",AdminEmployeeNeverLogedIn:"Never"
    },
    'ar':
    {
        login: "تسجيل الدخول", signup: "تسجيل", home: "الصفحة الرئيسية", product: "المنتج", about: "حول",
        contact: "اتصل", randomSmall: "الأميركية تحدي التي الأردن مخاوف",adminOrdersDetailsYourEnquiry:"استفسارك هنا",
        randomLarge: "الأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر",adminOrdersDetailsWriteYourQuestion:"اكتب سؤالك هنا",
        tryForFree: "حاول مجانا!", catalogs: "كتالوجات", services: "خدماتنا",
        randomHuge: "الأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر",
        gallery: "صالة عرض", galleryText: "نحن نركز على بيئة العمل ونلتقي بك في مكان عملك. انها مجرد ضغطة مفتاح.",
        seeMore: "شاهد المزيد", partners: "شركاؤنا", partnerText: "نحن نركز على بيئة العمل ونلتقي بك في مكان عملك. انها مجرد ضغطة مفتاح",
        partnerBtn: "كل الشركاء", clients: "العملاء", clientCard: "الأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر الأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر",
        designer: "مصمم",adminVIPClientsSelected:"البحث عن عملاء VIP", adminNewUserSelected:"المستخدمين الجدد - لا توجد أوامر",
        clientCardHuge: "الأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر الأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبالأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر الأ",
        containerOffer: "هل جربت عرض الحاويات الخاص بنا؟", address: "قوانغتشو، الصين", contactNo: "1885265612",
        main: "الأساسية", copyRight: "حقوق النشر كیرکی ٢٠٢٠", resources: "مصادر", freeCatalog: "كتالوج مجاني", blog: "مدونة",
        request: "طلب", updates: "التحديثات",
        signUpHeading: "سجل حسابك!",
        signUpdummy: "الأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر",
        yourName: "اسمك", fullName: "الاسم بالكامل", email: "*عنوان البريد الالكترونى", enterEmail: "أدخل عنوان البريد الالكتروني", phone: "*رقم الهاتف", phonePlaceholder: "رقم الهاتف", createPassword: "*أنشئ كلمة مرور", enterPassword: "ادخل رقمك السري",
        confirmPassword: "*تأكيد كلمة المرور", confirmPasswordPlaceholder: "تأكيد كلمة المرور"
        , termsAndConditions: "أوافق على الشروط والأحكام", registerAccountBtn: "تسجيل حساب", or: "أو",
        registerWithGoogle: "سجل مع جوجل", registerWithFacebook: "سجل مع الفيسبوك"
        , back: "عودة", loginHeading: "تسجيل الدخول إلى حسابك", loginDescription: "تسجيل الدخول إلى حسابك لإدارة جميع المعلومات والتفاصيل الخاصة بك", loginEmail: "البريد الإلكتروني", enterYourEmail: "أدخل عنوان بريدك الالكتروني",
        password: "*كلمه السر", forgotPassword: "هل نسيت كلمة المرور", loginWithSocialMedia: "تسجيل الدخول باستخدام وسائل التواصل الاجتماعي", noAccount: "ليس لديك حساب؟ إنشاء حساب الآن",
        forgotPasswordHeading: "نسيت رقمك السري؟", forgotPasswordDescription: "أدخل بريدك الإلكتروني الذي استخدمته لإنشاء حسابك وسنقوم بإعادة تعيين كلمة المرور الخاصة بك",
        requestNewPassword: "طلب كلمة مرور جديدة", changePassword: "غير كلمة السر", changePasswordDescription: ":يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل وتتضمن واحدًا على الأقل:",
        lowercaseLetter: "حرف صغير-", uppercaseLetter: "الحرف الكبير-", number: "رقم-", specialCharacter: "حرف خاص-", oldPassword: "كلمة سر قديمة", newPassword: "كلمة مرور جديدة", confirmNewPassword: "تأكيد كلمة المرور", changePasswordBtn: "غير كلمة السر",
        activateYourAccount: "فعل حسابك", activateAccountDescription: "!يرجى التحقق من البريد الإلكتروني المرسل إلى بريدك الإلكتروني لتفعيل حسابك والبدء في استخدام حسابك. شكرا لك", invalidEmail: "البريد الإلكتروني أو كلمة السر خاطئة", contactKeerki: "اتصل كیرکی إذا كنت بحاجة إلى مساعدة",
        sessionExpire: ".انتهت جلستك بسبب عدم النشاط. الرجاد الدخول على الحساب من جديد", loginToOtherAccount: ".لقد تم تسجيل خروجك بسبب تسجيل الدخول على جهاز آخر",
        technicalDifficulty: ".صعوبة فنية. آسف للإزعاج؛ حاول مرة اخرى", logoutSuccessful: ".تم تسجيل الخروج بنجاح", accountDeactivated: ".تم تعطيل حسابك", accountSuspended: ".تم تعليق حسابك",
        setNewPhoto: "تعيين صورة جديدة", accountName: ":اسم", accountEmail: ":البريد الإلكتروني", accountPhone: ":هاتف", accountPassword: ":كلمه السر",
        addCompanyInfo: "أضف معلومات شركتك", companyName: "اسم الشركة", companyAddress: "عنوان", companyPhone: "هاتف", companyPersonIncharge: "الشخص المسؤول", companyCountry: "بلد", companySave: "حفظ", companyCancel: "إلغاء", cardClickHere: "انقر هنا", overViewOrderHistory: "تاريخ الطلب",
        overViewNotification: "تنبيه", overViewAccount: "الحساب", overViewOrderForm: "نموذج الطلب", help: "مساعدة", companyInfo: "معلومات الشركة", companyInfoName: "اسم الشركة", companyInfoCountry: "بلد", companyInfoContactPerson: "الشخص الذي يمكن الاتصال به", companyInfoCity: "مدينة", companyInfoProvince: "المحافظة", companyInfoPostCode: "الرمز البريدي", companyInfoDist: "الحي والشارع",
        companyInfoPhone: "رقم الهاتف", companyInfoEmail: "البريد الإلكتروني", companyInfoAddLogo: "أضف الشعار", companyInfoDropFile: "أو أسقطها هنا", shippingInfo: "معلومات الشحن", shippingInfoPort: "ميناء", shippingInfoCountry: "بلد", currencySAR: "ريال سعودي", currencyAED: "درهم إماراتي", currencyUSD: "دولار أمريكي", currencyEURO: "اليورو", aboutProducts: "حول المنتجات", productUnitPrice: "سعر الوحدة",
        productQuantity: "كمية", productItem: "بند", productDescription: "وصف", remarks: "ملاحظات", remarksOther: "آخر", remarksTradingTerm: "مدة التداول", paymentTerm: "شروط الدفع", paymentTermLC: "ایل سی", paymentTermTT: "ٹی ٹی", shippingMethod: "طريقة الشحن", shippingMethodBySea: "عن طريق البحر", shippingMethodByAir: "عن طريق الجو", deliveryTime: "موعد التسليم",
        deliveryTime30Days: "٣٠ يوما", deliveryTime20Days: "٢٠ يوما", deliveryTime10Days: "١٠ يوما", companyInfoOthers: "الآخرين", companyInfoSave: "حفظ ومتابعة", companyInfoReset: "إعادة تعيين", formHeaderAccountDetails: "تفاصيل الحساب", formPreviewEdit: "تعديل", formPreviewView: "رأي"
        , formPreviewCancel: "إلغاء", formPreviewSend: "إرسال", downloadYourCatalog: "قم بتنزيل الكتالوج الخاص بك", catalogName: "*اسم", catalogEmail: "البريد الإلكتروني", catalogPhone: "هاتف", catalogDownloadBtn: "تحميل", allMessages: "جميع الرسائل", newMessages: "رسائل جديدة", messagesUnReadBtn: "غير مقروء", messagesReadBtn: "اقرأ", messageOrderShipped: "تم شحن الطلب", message1Minute: "١ منذ دقيقة", messageView: "رأي", messageDismiss: "نبذ", messageNewHadi: "Hedi رسالة جديدة من", messageOrderDelivered: "أجل تسليم",
        allNotifications: "كل الإخطارات", newNotifications: "طلبات جديدة", notificationReadBtn: "اقرأ", notificationUnReadBtn: "غير مقروء", notification1Minute: "١ منذ دقيقة", notificationView: "رأي", notificationDismiss: "نبذ", notificationOrderShipped: "تم شحن الطلب", notificationNewHedi: "Hedi رسالة جديدة من", notificationOrderDelivered: "أجل تسليم", quoteOrders: "أوامر الاقتباس", serviceOrders: "أوامر الخدمة", shippingOrders: "أوامر الشحن", orderFormHistory: "ترتيب النماذج التاريخ", orderSearchByNumber: ".البحث عن طريق رقم الطلب", orderSelectAll: "اختر الكل", orderDownloadAll: "تحميل الكل", orderStatus: "الحالة", orderDate: "تاريخ",
        orderPrice: "السعر", orderCategories: "التصنيفات", orderSomethingElse: "شيء آخر", inProgressBtn: "في تقدم", doneBtn: "منجز", waitingBtn: "انتظار", canceledBtn: "ألغيت", orderDownload: "تحميل", keerki: "كيركي", overview: "نظرة عامة", orderHistory: "تاريخ الطلب", account: "الحساب", notifications: "إشعارات", messages: "الرسائل", settings: "إعدادات", logout: "تسجيل خروج", continueSession: "تواصل الجلسة", logoutTime: "1 دقيقة و 52 ثانية", automaticLogout: "سيتم تسجيل خروجك تلقائيًا", viewInTemplates: "عرض في القوالب", popupMessages: "الرسائل", popupDaysAgo: "٢ منذ أيام", popupNewMessages: "لديك 3 رسائل جديدة", popupDismiss: "نبذ", popupViewAll: "مشاهدة الكل",
        popupNotifications: "إشعارات", popupNewNotifications: "لديك 3 إخطارات جديدة",
        dashboardHeading: "لوحة القيادة", dashboardDescription: ".احصل على ملخص للمعاملات الأسبوعية عبر الإنترنت هنا", newUserWelcomeHeading: "مرحبا بكم في كيركي.", newUserWelcomeDescription: "تجربة جديدة مصممة لمساعدتك على إدارة طلباتك بشكل أسرع!", newUserHistoryPageHeading: "عرض كل الطلبات في مكان واحد!", newUserHistoryPageDescription: "تحقق من جميع طلباتك وتفاصيلك في صفحة سجل الطلبات وتحقق من حالتها.", newUserOrderTemplateHeading: "عرض طلباتك في قوالب ", newUserOrderTemplateDescription: "أفضل وأسرع يمكنك عرض كل حالتك وأوامرك في قوالب", newUserAccountInformationHeading: "إدارة معلومات حسابك وإضافة تفاصيل شركتك",
        newUserAccountInformationDescription: "الأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر", newUserNotificationHeading: "إرسال الرسائل وعرض الإخطار في شاشة واحدة", newUserNotificationDescription: "الأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبرالأميركية تحدي التي الأردن مخاوف تتعاون في معها الأسلحة كما مصالح ويعبر"
        , newUserNext: "التالى", newUserPrev: "السابق", newUserDone: "منجز",
        notificationHeading: "إشعارات", notificationDescription: "إدارة الإخطارات الخاصة بك", messagesHeading: "الرسائل", messagesDescription: "إدارة رسائلك", orderHistoryHeading: "تاريخ الطلب", orderHistoryDescription: ".احصل على ملخص للمعاملات الأسبوعية عبر الإنترنت هنا", accountHeading: "الحساب", accountDescription: "تغيير تفاصيل حسابك", orderFormHeading: "نموذج الطلب", orderFormQuote: "اقتبس", orderFormService: "الخدمات", orderFormShipping: "الشحن",
        language: ":لغة", rtlHelper: "مرحبا", functionalityError: "الوظيفة غير متوفرة حاليًا", sorryText: "آسف للإزعاج", contentError: "المحتوى غير متوفر حاليا.", tryLater: "نحن آسفون؛ الرجاء معاودة المحاولة في وقت لاحق.", ok: "حسنا", selectLanguage: "يرجى تحديد لغة",
        ksa: "المملكة العربية السعودية", uae: "الإمارات العربية المتحدة", qatar: "الالمتحدة", bahrain: "البحرين", oman: "سلطنة عمان", lebanon: "لبنان", jordan: "الأردن", egypt: "مصر", germany: "ألمانيا", otherLanguages: "الآخرين",
        setLanguage: "لغة", shippingTo: "سافر على متن سفينة ل", serviceDetails: "تفاصيل الخدمة", factoryContact: "اتصالات المصنع", whatGoodsShipping: "ما هي البضائع التي تقوم بشحنها؟", entryFactoryContact: "أدخل جهة اتصال المصنع", listGoods: "قائمة بجميع البضائع الخاصة بك",
        providePackingList: "يرجى تقديم قائمة التعبئة أو تحميل ملف", height: "ارتفاع", width: "عرض", weightKG: "العرض بالكيلوغرام", uploadPackingList: "تحميل قائمة التعبئة", uploadInvoice: "تحميل الفاتورة", submit: "إرسال",
        messageReply: "الرد", openMessages: "افتح الرسائل", messagesOpen: "افتح", messagesClosed: "مغلق", messageSubject: "موضوع الرسالة", orderTypeNumber: "نوع الطلب - رقم الطلب",
        inboxAll: "الكل", inboxFile: "ملف", inboxImages: "الصور", inboxSearch: "بحث سريع", inboxMessage: "رسالة", inboxSend: "إرسال", inboxRating: "قيم تجربتك الدردشة", inboxThanks: "شكرا لك ^. ^", inboxImprove: "من فضلك قل لنا ما يمكننا تحسينه",
        servicesProducts: "منتجات", servicesConsultation: "التشاور", servicesInformation: "معلومات", orderProducts: "المنتج", orderDeselect: "الغاء تحديد", orderNumber: "رقم الأمر", dateUpdated: "تاريخ تحديث",
        //admin
        loginUserName: "اسم المستخدم", loginUserPassword: "كلمه السر", loginBtn: "تسجيل الدخول", contactAdministrator: "اتصل بالمسؤول", quickSearch: "بحث سريع", adminContact: "اتصل", adminContactPopUpHeading: "إلى من تريد إرسال رسالة؟", adminContactPopUpAdministrators: "المسؤولين", adminContactPopUpClient: "عميل",
        contactClient: "اتصل بالعميل", searchForClientName: "ابحث عن اسم العميل", clientMessageSubject: "موضوع", clientMessage: "اكتب رسالتك هنا", clientDragAndDrop: "السحب والإفلات أو", clientBrowse: "تصفح", clientSubmit: "إرسال",
        contactEmployAdministrator: "اتصل بأحد الموظفين", searchForEmployName: "ابحث عن موظف", selectDepartment: "حدد القسم", administratorOrderNo: "رقم الأمر", administratorMessageSubject: "موضوع", administratorMessage: "اكتب رسالتك هنا", administratorDragAndDrop: "السحب والإفلات أو", administratorBrowse: "تصفح", administratorUploadFileTypes: " يرجى تحميل صورة بتنسيق PDF أو PNG أو JPG بنفس الارتفاع والعرض الذي يقل عن 5 ميغا بايت.", administratorSubmit: "إرسال", administratorUrgent: "العاجلة",
        adminClients: "العملاء", adminOrders: "الطلب", adminRecent: "الأخيرة", adminToday: "اليوم", adminYesterday: "في الامس", clientsSearchFor: "البحث عن...", adminNoOfOrders: "عدد الطلبات", exportCsv: "تصدير CSV", allClients: "كل العملاء", clientsSort: "فرز", clientsFilter: "منقي", clientsAccountStatus: "حالة الحساب", clientsPhone: "هاتف", clientsDateJoined: "تاريخ الانضمام", clientsCountry: "بلد", clientsName: "اسم", clientBtnActive: "نشيط", clientBtnInactive: "غير نشط", clientEdit: "تعديل", clientView: "رأي", clientDelete: "حذف", clientLoginAs: "سجل دخول", clientsTableRowsPerPage: "عدد الصفوف في الصفحة",
        clientDetailsName: "اسم:", clientDetailsAddress: "عنوان:", clientDetailsCompanyName: "اسم الشركة:", clientDetailsEmail: "البريد الإلكتروني:", clientDetailsPhone: "هاتف:", clientDetailsWhatsapp: "وتس ايب:", clientDetailsBalance: "توازن:", clientDetailsMemberShip: "عضوية:", clientMemberShipVip: "كبار الشخصيات", clientDetailsEdit: "تعديل", clientDetailsExportToCsv: "تصدير CSV", clientDetailsAllOrders: "جميع الطلبات", clientDetailsSearchOrders: "تقسيمات البحث", clientDetailsOrdersFilter: "منقي", clientDetailsOrdersSort: "فرز", clientDetailsOrdersStatus: "الحالة", clientDetailsOrdersDate: "تاريخ", clientDetailsOrdersPhoneNumber: "رقم الهاتف", clientDetailsOrdersTotal: "الطلب الكلي", clientDetailsOrderNo: "رقم الأمر", clientDetailsOrdersCountry: "بلد",
        clientDetailsOrdersCompany: "شركة", clientDetailsOrdersEdit: "تعديل", clientDetailsOrdersCopy: "نسخ", clientDetailsOrdersDelete: "حذف", clientDetailsOrdersInvoice: "فاتورة", clientDetailsOrdersNewBtn: "جديد", clientDetailsOrdersDeliveredBtn: "تم التوصيل", clientDetailsOrdersCancelledBtn: "ألغيت", clientDetailsOrdersShippedBtn: "شحنها", clientDetailsOrdersInprogressBtn: "في تقدم", clientDetailsOrdersROwsPerPage: "عدد الصفوف في الصفحة",
        adminNotificationsAllNotifications: "جميع الإخطارات", adminNotificationsNewOrders: "طلبات جديدة", adminNotificationsUnRead: "غير مقروء", adminNotificationsRead: "اقرأ", adminNotificationsViewOrder: "مشاهدة الطلب", adminNotificationsDismiss: "نبذ", adminAccountSetNewPhoto: "تعيين صورة جديدة", adminAccountName: "اسم:", adminAccountEmail: "البريد الإلكتروني:", adminAccountPhone: "هاتف:", adminAccountPassword: "كلمه السر", adminAccountUserName: "اسم المستخدم", adminAccountCannotEdit: "لا يمكن التعديل", adminAccountCancel: "إلغاء", adminAccountSave: "حفظ", adminAccountOldPassword: "كلمة سر قديمة", adminAccountNewPassword: "كلمة مرور جديدة", adminAccountConfirmPassword: "تأكيد كلمة المرور", adminAccountFullName: "الاسم بالكامل", adminAccountPhoneNumber: "رقم الهاتف",
        adminOrdersAllOrders: "جميع الطلبات", adminOrdersExportToCsv: "تصدير CSV", adminOrdersSearchOrders: "تقسيمات البحث", adminOrdersFilter: "منقي", adminOrdersSort: "فرز", adminOrdersStatus: "الحالة", adminOrdersDate: "تاريخ", adminOrdersPhone: "رقم الهاتف", adminOrdersTotal: "الطلب الكلي", adminOrdersNumber: "رقم الأمر", adminOrdersCountry: "بلد", adminOrdersCompany: "شركة", adminOrdersNewBtn: "جديد", adminOrdersDeliveredBtn: "تم التوصيل", adminOrdersCancelledBtn: "ألغيت", adminOrdersInprogressBtn: "في تقدم", adminOrdersShippedBtn: "شحنها", adminOrdersRowsPerPage: "عدد الصفوف في الصفحة", adminOrdersEdit: "تعديل", adminOrdersCopy: "نسخ", adminOrdersDelete: "حذف", adminOrdersInvoice: "فاتورة", adminOrdersDetailsName: "اسم:", adminOrdersDetailsAddress: "عنوان:", adminOrdersDetailsEmail: "البريد الإلكتروني:",
        adminOrdersDetailsInregardWithThisOrder: "فيما يتعلق بهذا الأمر:", adminOrdersDetailsAnyIssue: "إذا كان لديك أي مشاكل بخصوص هذه المسألة. يرجى الاتصال بالمسؤول وسنحل المشكلة في أقرب وقت ممكن.", adminOrdersDetailsSendAMessage: "ارسل رسالة", adminOrdersDetailsReasonForCancellation: "سبب الالغاء", adminOrdersDetailsWriteQuestion: "اكتب سؤالك هنا",
        adminOrdersDetailsCompanyName: "اسم الشركة:", adminOrdersDetailsPhone: "هاتف:", adminOrdersDetailsWhatsapp: "وتس ايب:", adminOrdersDetailsEdit: "تعديل",
        adminOrdersDetailsOrderNumber: "رقم الأمر:", adminOrdersDetailsStatus: "الحالة", adminOrdersDetailsTags: "العلامات", adminOrdersDetailsRating: "تقييم", adminOrdersDetailsDownload: "تحميل", adminOrdersDetailsView: "رأي", adminOrdersDetailsRecived: "تم الاستلام", adminOrdersDetailsTransfer: "نقل", adminOrdersDetailsPayNow: "ادفع الآن", adminOrdersDetailsQuestion: "سؤال", adminOrdersDetailsDone: "منجز", adminOrdersDetailsCancel: "إلغاء", adminOrdersDetailsSendSms: "أرسل رسالة نصية قصيرة", adminOrdersDetailsWaiting: "انتظار", adminOrdersDetailsShipped: "شحنها", adminOrdersDetailsInprogress: "في تقدم", adminOrdersDetailsReasonForCancelling: "سبب الالغاء*", adminOrdersDetailsMustProvideReason: "يجب عليك تقديم سبب للإلغاء", adminOrdersDetailsWriteYourText: "اكتب نصك هنا", adminOrdersDetailsIgnore: "تجاهل", adminOrdersDetailsSubmit: "إرسال",
        adminMessagesOpen: "افتح", adminMessagesPending: "قيد الانتظار", adminMessagesResolve: "حل", adminMessagesClose: "قريب",
        adminMessagesHeading: "الرسائل", adminMessagesSearch: "بحث", adminMessagesOrderMessages: "رسائل النظام", adminMessagesCompanyContact: "اتصالات الشركة", adminMessagesSubjects: "موضوع الرسالة", adminMessagesVIp: "كبار الشخصيات", adminMessagesTransferTheChat: "انقل الدردشة", adminMessagesMarkAsResolved: "وضع علامة تم الحل", adminMessagesPlaceholder: "رسالة", adminMessagesSend: "إرسال", adminMessagesAll: "الكل", adminMessagesFile: "ملف", adminMessagesImages: "الصور", adminMessagesOrders: "الطلب", adminMessagesQuickSearch: "بحث سريع",
        adminMessagesCustomQuickResponse: "الردود السريعة المخصصة", adminMessagesEdit: "تعديل", adminMessagesCopy: "ينسخ", adminMessagesDelete: "حذف", adminMessagesSelectAll: "اختر الكل", adminMessagesDeselectAll: "الغاء تحديد الكل", adminMessagesAdd: "أضف",
        adminDashboardHeading: "لوحة القيادة", adminDashboardUserAdmin: "مشرف", adminDashboardKeerkiDashboard: "لوحة القيادة كيركى", adminDashboardClientsBtn: "العملاء", adminDashboardOrdersBtn: "الطلب", adminDashboardContactBtn: "اتصل", adminDashboardNotificationsBtn: "إشعارات", adminDashboardMessagesBtn: "الرسائل", adminDashboardSettingsBtn: "إعدادات", adminDashboardLogoutBtn: "تسجيل خروج", adminDashBoardQuickSearch: "بحث سريع", adminDashboardNotificationNumberOfNotifications: "لديك 3 طلبات جديدة",
        adminDashboardMyaccount: "حسابي", adminDashboardAccountDetails: "تفاصيل الحساب", adminDashboardChangePassword: "غير كلمة السر"
        , adminSettingsSessionTimeOut: "مهلة جلسة", adminSettingsHours: "ساعات", adminSettingsMinutes: "الدقائق", adminSettingsAutoSendSms: "إرسال رسائل تذكير تلقائية عبر الرسائل القصيرة للعملاء", adminSettingsDays: "أيام", adminSettingsDeactivateEmployee: "إعفاء الموظف بسبب عدم نشاطه", adminSettingsDeactivateUser: "قم بإلغاء تنشيط المستخدم لفترة قصيرة", adminSettingsRemoveUser: "إزالة المستخدم من لوحة تحكم المسؤول", adminSettingsEmployeeSetting: "إعدادات الموظفين", adminSettingsAddNewEmploy: "إضافة موظف جديد", adminSettingsRegisterEmployee: "سجل موظف", adminSettingsName: "*اسم", adminSettingsFirstName: "أول", adminSettingsLastName: "الاخير", adminSettingsUsername: "*اسم المستخدم", adminSettingsUsernamePlaceholder: "اسم المستخدم", adminSettingsPassword: "*كلمه السر", adminSettingsPassowordPlaceholder: "كلمه السر", adminSettingsConfirmPassword: "*تأكيد كلمة المرور", adminSettingsConfirmPasswordPlaceholder: "تأكيد كلمة المرور", adminSettingsEmail: "*البريد الإلكتروني", adminSettingsEmailPlaceholder: "البريد الإلكتروني", adminSettingsConfirmEmail: "*تأكيد عنوان البريد", adminSettingsConfirmEmailAddressPlaceholder: "تأكيد عنوان البريد",
        adminSettingsWorkPhone: "* عمل بوني", adminSettingsEnterPhone: "أدخل رقم هاتفك", adminSettingsUserTimeZone: "* المنطقة الزمنية للمستخدمين", adminSettingsRole: "*وظيفة", adminSettingsRoleAdmin: "مشرف", adminSettingsCancel: "إلغاء", adminSettingsSubmit: "إرسال", adminSettingsPassowordDescription: "يجب أن تكون كلمة المرور مختلفة عن آخر 3 كلمات مرور. يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وأن تتضمن حرفًا صغيرًا وحرفًا كبيرًا ورقمًا وحرفًا خاصًا على الأقل.", adminSettingsEditEmployee: "تحرير الموظف", adminSettingsViewAllEmployees: "عرض جميع الموظفين", adminSettingsEditEmployeeName: "*اسم", adminSettingsEditEmployeeFirst: "أول", adminSettingsEditEmployeeLast: "الاخير", adminSettingsEditEmployeePassword: "كلمه السر:", adminSettingsEditEmployeeChangePassword: "غير كلمة السر", adminSettingsEditEmployeeChangePasswordDescription: "يجب أن تكون كلمة المرور مختلفة عن آخر 3 كلمات مرور. يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وتتضمن واحدًا على الأقل:", adminSettingsLastLogin: "آخر تسجيل دخول:", adminSettingsAccountCreated: "تم إنشاء الحساب:", adminSettingsLastModified: "آخر تعديل:", adminSettingsEditEmployeeEmail: "*البريد الإلكتروني", adminSettingsEditEmployeeEmailPlaceHolder: "first.last@keerki.com", adminSettingsEditEmployeeWorkPone: "هاتف عمل",
        adminSettingsEditEmployeeEnterPhone: "أدخل رقم هاتفك", adminSettingsEditEmployeeUserTimeZone: "* المنطقة الزمنية للمستخدم", adminSettingsEditEmployeeRole: "*وظيفة", adminSettingsEditEmployeeAccountStatus: "*حالة الحساب", adminSettingsEditEmployeeActve: "نشيط", adminSettingsEditEmployeeInactive: "غير نشط", adminSettingNotifyAllEMployees: "إخطار جميع الموظفين", adminSettingClientSettings: "إعدادات العملاء", adminSettingAddNewClient: "إضافة عميل جديد", adminSettingsNofifyAllClients: "يخطر جميع العملاء عن طريق الإخطار", adminSettingsSendSms: " إرسال الرسائل القصيرة لجميع العملاء", adminSettingActivateClient: "تفعيل حساب العميل", adminSettingSendEmail: "إرسال بريد إلكتروني إلى جميع العملاء", adminSettingSuspendAccount: "تعليق حساب العميل", adminAllKeerkiEmployees: "جميع موظفي كيركي", adminEmployeeDate: "تاريخ", adminEmployeeStatus: "الحالة", adminEmployeesReplies: "الردود", AdminEmployeesEditAccountBtn: "تحرير الحساب", AdminEmployeesActive: "نشيط",
        adminSendSms: "أرسل رسالة نصية قصيرة", adminAllClientsSelected: "تم تحديد جميع العملاء", adminAllClients: "كل العملاء", adminAllButExcluded: "جميعها ما عدا مستثناة", adminExclueName: "استبعاد اسم العميل", adminVipClients: "عملاء VIP", adminNoUsers: "المستخدمين الجدد - لا توجد أوامر", adminSearch: "بحث", adminEnterYourSms: "أدخل الرسائل القصيرة الخاصة بك هنا", adminSend: "إرسال", adminSendEmail: "ارسل بريد الكتروني"
        , editPermissionsRoles: "Roles", editPermissionsAddNewRoles: "أضف دور جديد", editPermissionsDuplicateSelectedRoles: "الدور المحدد مكرر", editPermissionsDeleteSelectedRole: "حذف الدور المحدد", editPermissionsUsersAreMoved: "يتم نقل المستخدمين إلى الدور الافتراضي ويظلون نشطين", editPermissionsTechEmployee: "موظف تقني", editPermissionsFinancialEmployee: "موظف مالي", editPermissionsCustomerService: "خدمة العملاء", editPermissionsPermissions: "أذونات", editPermissionsSensitive: "حساس", editPermissionsClientDetails: "تفاصيل العميل", editPermissionsName: "اسم", editPermissionsAddress: "عنوان", editPermissionsEmail: "عنوان البريد الالكترونى", editPermissionsPhone: "رقم الهاتف", editPermissionsBalance: "توازن", editPermissionsOrders: "الطلب", editPermissionsViewOrders: "عرض الطلبات", editPermissionsSendSms: "أرسل رسالة نصية قصيرة", editPermissionsDone: "منجز", editPermissionsTransfer: "نقل", editPermissionsCancel: "إلغاء", editPermissionsMessage: "الرسائل", editPermissionsViewMessages: "عرض الرسائل", editPermissionsReplyToMessages: "الرد على الرسائل", editPermissionsNotifications: "إشعارات", editPermissionsView: "رأي", editPermissionsClientStatus: "حالة العميل", editPermissionsMoreDetails: "مزيد من التفاصيل حول هذا الموضوع", editPermissionsAccountStatus: "حالة الحساب",
        editPermissionsEmployeesAndUserManagment: "الموظفين وإدارة المستخدم", editPermissionsCreateUser: "إنشاء مستخدم", editPermissionsManageUser: "إدارة المستخدم", editPermissionsActivate: "تنشيط المستخدمين وتعليقهم وحذفهم أو تعديل ملفاتهم الشخصية.", editPermissionsUsers: "المستخدمون", editPermissionsMoveUsers: "انقل المستخدمين إلى هذا الدور", editPermissionsMoveUser: "إعادة المستخدم (المستخدمين) إلى الدور الافتراضي", editPermissionsSearch: "بحث", editPermissionsFirstLast: "اول آخر اسم",
        notifyNotifyAll: "إبلاغ جميع موظفي كيركي", notifyAllEmployeesSelected: "تم اختيار جميع الموظفين", notifySelectedExcludedEmployee: "تم تحديد اسم الموظف المستبعد", notifySearchDepartments: "أقسام البحث", notifySearchAndExcludeDepartments: "بحث واستبعاد الإدارات", notifyAllEmployees: "كل الموظفين", notifyAllButExclude: "الكل ما عدا الاستبعاد", notifyDepartment: "قسم", notifyExcludeDepartment: "استبعاد قسم", notifySearch: "بحث", notifyExcludedEmployeeName: "اسم الموظف المستبعد", notifySelectedExcludedDepartment: "القسم المستبعد المختار",
        notifySubject: "موضوع", notifySendToAllEmployees: "أرسل رسالة إلى جميع الموظفين", notifySend: "إرسال", permissionCanEdit: "يمكن أن يشاهد", permissionsCanEdit: "يمكن التحرير", permissionsHide: "إخفاء"
        , sortNewToOld: "جديد إلى قديم", sortOldToNew: "من القديم إلى الجديد", sortHighToLow: "من الأعلى إلى الأقل", sortLowToHigh: "من أسفل إلى أعلى", filterCancelled: "ألغيت", filterWaiting: "انتظار", filterDelivered: "تم التوصيل", filterShipped: "شحنها", filterInProgress: "In Progress"
        , adminDashboardSearchByUserName: "البحث عن طريق اسم المستخدم", adminDashboardSearchByEmail: "البحث عن طريق البريد الإلكتروني", adminDashboardSearchByDate: "البحث بالتاريخ", adminDashboardSearchByOrderNumber: "البحث عن طريق رقم الطلب", adminDashboardSearchByProductName: "البحث عن طريق اسم المنتج", adminDashboardSearchByOrderStatus: "البحث حسب حالة الطلب", adminDashboardSearchByClientAddress: "البحث عن طريق عنوان العميل", adminDashboardSearchByClientPhone: "البحث عن طريق هاتف العميل", adminDashboardSearchByClientCompanyName: "البحث حسب اسم الشركة العميل", adminDashboardSearchByClientMemberShip: "البحث عن طريق Client MemberShip", adminDashboardSearchByFirstLastName: "البحث بالاسم الأول والأخير"
        , issueHasBeenResolved: "تم حل المشكلة", requestRatingFromClient: "طلب التقييم من العميل"
        , adminMessageDeletePopup: "هل أنت متأكد أنك تريد حذف هذه الاستجابة"
        ,adminMessageDeleteNo:"لا",adminMessageDeleteYes:"نعم",signupLoginNow:"لديك حساب؟ تسجيل الدخول الآن",adminSettingsDepartment:"*قسم",
        orderSearchByName:"البحث عن طريق الإسم",orderSearchByDate:"البحث بالتاريخ",serviceHistory:"سجل أوامر الخدمة",quotesHistory:"تاريخ أوامر الاقتباس",shippingHistory:"سجل طلبات الشحن",adminOrdersEnquiry:"استفسارك هنا",
        intClient:"العميل الدولي",localClient:"العميل المحلي",
        Afghanistan:"أفغانستان",Albania:"ألبانيا",Algeria:"الجزائر",AmericanSamoa:"ساموا الأمريكية",Andorra:"أندورا",Angola:"أنغولا",Anguilla:"أنغيلا",Antarctica:"القارة القطبية الجنوبية",AntiguaBarbuda:"أنتيغوا وبربودا",Argentina:"الأرجنتين",Armenia:"أرمينيا",Aruba:"أروبا",Australia:"أستراليا",Austria:"النمسا",Azerbaijan:"أذربيجان",Bahamas:"جزر البهاما",Bahrain:"البحرين",Bangladesh:"بنغلاديش",Barbados:"بربادوس",Belarus:"بيلاروسيا",Belgium:"بلجيكا",Belize:"بليز",Benin:"بنين",Bermuda:"برمودا",Bhutan:"بوتان",Bolivia:"بوليفيا",Bonaire:"بونير",Bosnia:"البوسنة",Botswana:"بوتسوانا",Bouvet:"بوفيت",Brazil:"البرازيل",BritishIndian:"هندي بريطاني",Brunei:"بروناي",Bulgaria:"بلغاريا",Burkina:"بوركينا",
        BurundiThe:"بوروندي",Burundi:"بوروندي",Cambodia:"كمبوديا",Cameroon:"الكاميرون",Canada:"كندا",Cayman:"كايمان",Africa:"أفريقيا",Chad:"تشاد",Chile:"تشيلي",China:"الصين",Christmas:"عيد الميلاد",Cocos:"كوكوس",Colombia:"كولومبيا",Comoros:"جزر القمر",Congo:"الكونغو",CongoThe:"الكونغو",Cook:"يطبخ",Costa:"كوستا ريكا",Croatia:"كرواتيا",Cuba:"كوبا",Curaçao:"كوراساو",Cyprus:"قبرص",Czechia:"التشيك",
        Côte:"كوت دي إيفورا",CôteDE:"كوت",Djibouti:"جيبوتي",Dominica:"دومينيكا",Dominican:"الدومينيكان",Egypt:"مصر",Salvador:"سلفادور",Equatorial:"الاستوائية",Eritrea:"إريتريا",Estonia:"إستونيا",Eswatini:"إيسواتيني",Ethiopia:"أثيوبيا",Falkland:"فوكلاند",Faroe:"فارو",Fiji:"فيجي"
        ,resetForm:"إعادة تعيين النموذج؟",updateData:"تحديث البيانات؟",
        notifyAllClients:"إخطار جميع عملاء Keerki",notifyAllClientsSelected:"إخطار جميع العملاء",notifyNotifyAllClients:"كل العملاء",notifySelectedExcludedClient:"تم تحديد اسم العميل المستبعد",notifyExcludedClientName:"اسم العميل المستبعد",notifySendToAllClients:"أرسل رسالة إلى جميع العملاء",adminSettingsBalance:"توازن",adminSettingsBalancePlaceHolder:"الرصيد الحالي",adminSettingsAddress:"*عنوان",adminSettingsAddressPlaceHolder:"عنوان",adminSettingsPhone:"هاتف",adminSettingsCompanyPlaceHolder:"اسم الشركة",adminSettingsCompany:"*شركة",adminSettingsMemberShip:"*عضوية",adminSettingsRegisterClient:"قم بتسجيل عميل",
        adminOrdersWaitBtn:"انتظار",adminOrdersPaidBtn:"دفع",adminOrdersDoneBtn:"انتهى",filterNew:"جديد",filterPaid:"دفع",filterDone:"منتهي",filterInactive:"غير نشط", filterActive:"نشيط",
        adminLoginAsClient:"سجل دخول",adminRemindLoginEmail:"إرسال بريد إلكتروني لتسجيل دخول العميل",AdminEmployeeNeverLogedIn:"مطلقا",typing: "يكتب..."
    },
    'ch':
    {
        login: "登录", signup: "寄存器", home: "家", product: "产品", about: "关于", contact: "联系",adminOrdersDetailsYourEnquiry:"您的询问在这里",
        randomSmall: "常理態重社俊避続", randomLarge: "常理態重社俊避続常理態重社俊避続",adminOrdersDetailsWriteYourQuestion:"在这里写下您的问题",
        tryForFree: "免费试用！", catalogs: "目录", services: "我们的服务",
        randomHuge: "常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続", gallery: "画廊",
        galleryText: "我们专注于人体工程学，并在您的工作地点与您会面。这只是一个按键。",
        seeMore: "查看更多", partners: "我们的伙伴", partnerText: "我们专注于人体工程学，并在您的工作地点与您会面。这",
        partnerBtn: "所有合作伙伴", clients: "客户怎么说",
        clientCard: "常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続",
        designer: "设计师",
        clientCardHuge: "常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続",
        containerOffer: "您尝试过我们的集装箱优惠吗？", address: "中国广州", contactNo: "1885265612",
        main: "主要", copyRight: "版权所有Keerki 2020", resources: "资源", freeCatalog: "免费目录", blog: "博客", request: "请求", updates: "更新",
        signUpHeading: "注册您的帐户！",
        signUpdummy: "我们专注于人体工程学，并在您的工作地点与您会面。这只是一个按键。",
        yourName: "你的名字", fullName: "全名", email: "电子邮件地址*", enterEmail: "请输入电邮地址", phone: "电话号码*", phonePlaceholder: "电话号码", createPassword: "创建密码*", enterPassword: "输入密码",
        confirmPassword: "确认密码*", confirmPasswordPlaceholder: "确认密码"
        , termsAndConditions: "我同意条款和条件", registerAccountBtn: "注册账户", or: "要么",
        registerWithGoogle: "向Google注册", registerWithFacebook: "在Facebook上注册"
        , back: "背部", loginHeading: "登录到您的帐户", loginDescription: "登录到您的帐户以管理所有信息和详细信息", loginEmail: "电子邮件", enterYourEmail: "输入你的电子邮箱地址",
        password: "密码*", forgotPassword: "忘记密码", loginWithSocialMedia: "用社交媒体登录", noAccount: "没有帐户？立即创建一个帐户",
        forgotPasswordHeading: "忘记密码了吗？", forgotPasswordDescription: "输入您用于创建帐户的电子邮件，我们将为您重置密码",
        requestNewPassword: "需要新密码", changePassword: "更改密码", changePasswordDescription: "密码必须包含至少8个字符，并且至少包含1个字符：",
        lowercaseLetter: "-小写字母", uppercaseLetter: "-大写字母", number: "-数", specialCharacter: "-特殊字符", oldPassword: "旧密码", newPassword: "新密码", confirmNewPassword: "确认密码", changePasswordBtn: "更改密码",
        activateYourAccount: "激活你的账号", activateAccountDescription: "请检查发送到您的电子邮件的电子邮件以激活您的帐户并开始使用您的帐户。谢谢！", invalidEmail: "无效的电子邮件或密码", contactKeerki: "如果需要帮助，请联系Keerki",
        sessionExpire: "您的会话因不活动而过期。请重新登录。", loginToOtherAccount: "您已因在另一台设备上登录而被注销。",
        technicalDifficulty: "技术难度。抱歉给你带来不便;请再试一次。", logoutSuccessful: "Log out successful.", accountDeactivated: "注销成功。", accountSuspended: "您的帐户已被暂停。",
        setNewPhoto: "设置新照片", accountName: "名称:", accountEmail: "电子邮件:", accountPhone: "电话:", accountPassword: "密码:",
        addCompanyInfo: "添加公司信息", companyName: "公司名", companyAddress: "地址", companyPhone: "电话", companyPersonIncharge: "负责人", companyCountry: "国家", companySave: "保存", companyCancel: "取消", cardClickHere: "点击这里", overViewOrderHistory: "订单历史",
        overViewNotification: "通知", overViewAccount: "帐户", overViewOrderForm: "订单", help: "救命", companyInfo: "公司资料", companyInfoName: "公司名", companyInfoCountry: "国家", companyInfoContactPerson: "联系人", companyInfoCity: "市", companyInfoProvince: "省", companyInfoPostCode: "邮编", companyInfoDist: "街区",
        companyInfoPhone: "电话号码", companyInfoEmail: "电子邮件", companyInfoAddLogo: "添加徽标", companyInfoDropFile: "或放在这里", shippingInfo: "运输信息", shippingInfoPort: "港口", shippingInfoCountry: "国家", currencySAR: "SAR", currencyAED: "AED", currencyUSD: "USD", currencyEURO: "EURO", aboutProducts: "关于产品", productUnitPrice: "单价",
        productQuantity: "数量", productItem: "项目", productDescription: "描述", remarks: "备注", remarksOther: "其他", remarksTradingTerm: "交易期限", paymentTerm: "付款期限", paymentTermLC: "LC", paymentTermTT: "TT", shippingMethod: "邮寄方式", shippingMethodBySea: "按海", shippingMethodByAir: "空运", deliveryTime: "交货时间",
        deliveryTime30Days: "30天", deliveryTime20Days: "20天", deliveryTime10Days: "10天", companyInfoOthers: "其他", companyInfoSave: "保存并继续", companyInfoReset: "重启", formHeaderAccountDetails: "帐户详细资料", formPreviewEdit: "编辑", formPreviewView: "视图"
        , formPreviewCancel: "取消", formPreviewSend: "发送", downloadYourCatalog: "下载目录", catalogName: "Name*", catalogEmail: "电子邮件", catalogPhone: "电话", catalogDownloadBtn: "下载", allMessages: "所有讯息", newMessages: "新讯息", messagesUnReadBtn: "未读", messagesReadBtn: "读", messageOrderShipped: "订单出货", message1Minute: "1分钟前", messageView: "视图", messageDismiss: "解雇", messageNewHadi: "Hedi的新讯息", messageOrderDelivered: "订单已交付",
        allNotifications: "所有通知", newNotifications: "新订单", notificationReadBtn: "读", notificationUnReadBtn: "未读", notification1Minute: "1分钟前", notificationView: "视图", notificationDismiss: "解雇", notificationOrderShipped: "订单出货", notificationNewHedi: "Hedi的新讯息", notificationOrderDelivered: "订单已交付", quoteOrders: "报价单", serviceOrders: "服务订单", shippingOrders: "运输订单", orderFormHistory: "订单历史记录", orderSearchByNumber: "按订单号搜索", orderSelectAll: "全选", orderDownloadAll: "全部下载", orderStatus: "状态", orderDate: "日期",
        orderPrice: "价钱", orderCategories: "分类目录", orderSomethingElse: "还有别的", inProgressBtn: "进行中", doneBtn: "完成", waitingBtn: "等候", canceledBtn: "取消", orderDownload: "下载", keerki: "基尔基", overview: "总览", orderHistory: "订单历史", account: "帐户", notifications: "通知事项", messages: "留言内容", settings: "设定值", logout: "登出", continueSession: "继续会议", logoutTime: "1分52秒", automaticLogout: "您将自动注销", viewInTemplates: "在模板中查看", popupMessages: "留言内容", popupDaysAgo: "2天前", popupNewMessages: "您有3条新消息", popupDismiss: "解雇", popupViewAll: "查看全部",
        popupNotifications: "通知事项", popupNewNotifications: "您有3条新通知",
        dashboardHeading: "仪表板", dashboardDescription: "在此处获取每周在线交易的摘要。", newUserWelcomeHeading: "欢迎来到Keerki。", newUserWelcomeDescription: "旨在帮助您更快地管理订单的全新体验！", newUserHistoryPageHeading: "在一处查看所有订单！", newUserHistoryPageDescription: "在订单历史记录页面中检查所有订单和详细信息，并检查其状态。“，newUserOrderTemplateHeading：”在模板中查看订单“，newUserOrderTemplateDescription：”甚至更好更快地可以在模板中查看所有状态和订单", newUserAccountInformationHeading: "管理您的帐户信息并添加公司详细信息",
        newUserAccountInformationDescription: "常理態重社俊避続常理態重社俊避続常理態重社俊避続常理態重社俊避続"
        , newUserNext: "下一个", newUserPrev: "以前", newUserDone: "完成了",
        notificationHeading: "通知事项", notificationDescription: "管理您的通知", messagesHeading: "留言内容", messagesDescription: "管理您的讯息", orderHistoryHeading: "订单历史", orderHistoryDescription: "在这里获取每周在线交易的摘要。", accountHeading: "帐户", accountDescription: "更改您的帐户详细信息", orderFormHeading: "订单", orderFormQuote: "引用", orderFormService: "服务", orderFormShipping: "运输",
        language: "语言:", rtlHelper: "help", functionalityError: "当前不可用的功能", sorryText: "抱歉给你带来不便", contentError: "内容当前不可用。", tryLater: "我们很抱歉;请稍后再试。", ok: "好", selectLanguage: "请选择一种语言", listGoods: "列出所有商品",
        ksa: "沙特阿拉伯", uae: "阿拉伯联合酋长国", qatar: "卡塔尔", bahrain: "巴林", oman: "阿曼", lebanon: "黎巴嫩", jordan: "约旦", egypt: "埃及", germany: "德国", otherLanguages: "其他",
        setLanguage: "语言", shippingTo: "运送到", serviceDetails: "服务详情", factoryContact: "工厂联系方式", whatGoodsShipping: "您要运送什么商品？", entryFactoryContact: "输入工厂联系方式",
        providePackingList: "请提供装箱单或上传文件", height: "高度", width: "宽度", weightKG: "宽度（公斤）", uploadPackingList: "上传装箱单", uploadInvoice: "上传发票", submit: "提交",
        messageReply: "回复", openMessages: "公开留言", messagesOpen: "打开", messagesClosed: "关闭", messageSubject: "邮件主题", orderTypeNumber: "订单类型-订单号",
        inboxAll: "所", inboxFile: "文件", inboxImages: "图片", inboxSearch: "快速搜索", inboxMessage: "信息", inboxSend: "发送", inboxRating: "评价您的聊天体验", inboxThanks: "谢谢^。^", inboxImprove: "请告诉我们我们可以改善的地方",
        servicesProducts: "产品展示", servicesConsultation: "咨询服务", servicesInformation: "信息", orderProducts: "产品", orderDeselect: "全部取消选择", orderNumber: "订单号", dateUpdated: "更新日期",

        //admin
        loginUserName: "用户名", loginUserPassword: "密码", loginBtn: "登录", contactAdministrator: "联络管理员", quickSearch: "快速搜索", adminContact: "联系", adminContactPopUpHeading: "您想向谁发送消息？", adminContactPopUpAdministrators: "管理员", adminContactPopUpClient: "客户",
        contactClient: "联系客户", searchForClientName: "搜索客户名称", clientMessageSubject: "学科", clientMessage: "在这里写下您的讯息", clientDragAndDrop: "拖放或", clientBrowse: "浏览", clientSubmit: "提交",
        contactEmployAdministrator: "联系员工", searchForEmployName: "搜索员工", selectDepartment: "选择部门", administratorOrderNo: "订单号", administratorMessageSubject: "学科", administratorMessage: "在这里写下您的讯息", administratorDragAndDrop: "拖放或", administratorBrowse: "浏览", administratorUploadFileTypes: "请上传高度和宽度相近且小于5mb的PDF，PNG或JPG图片。", administratorSubmit: "提交", administratorUrgent: "紧急",
        adminClients: "客户群", adminOrders: "订单", adminRecent: "最近", adminToday: "今天", adminYesterday: "昨天", clientsSearchFor: "搜索...", adminNoOfOrders: "订单数", exportCsv: "导出CSV", allClients: "所有客户", clientsSort: "分类", clientsFilter: "过滤", clientsAccountStatus: "帐户状态", clientsPhone: "电话", clientsDateJoined: "加入日期", clientsCountry: "国家", clientsName: "名称", clientBtnActive: "活性", clientBtnInactive: "不活", clientEdit: "编辑", clientView: "视图", clientDelete: "删除", clientLoginAs: "登录为", clientsTableRowsPerPage: "每页行数",
        clientDetailsName: "名称:", clientDetailsAddress: "地址:", clientDetailsCompanyName: "公司名:", clientDetailsEmail: "电子邮件:", clientDetailsPhone: "电话:", clientDetailsWhatsapp: "Whatsapp的:", clientDetailsBalance: "平衡:", clientDetailsMemberShip: "会员资格:", clientMemberShipVip: "贵宾", clientDetailsEdit: "编辑", clientDetailsExportToCsv: "导出CSV", clientDetailsAllOrders: "所有订单", clientDetailsSearchOrders: "搜索订单", clientDetailsOrdersFilter: "过滤", clientDetailsOrdersSort: "分类", clientDetailsOrdersStatus: "状态", clientDetailsOrdersDate: "日期", clientDetailsOrdersPhoneNumber: "电话号码", clientDetailsOrdersTotal: "合计订单", clientDetailsOrderNo: "订单号", clientDetailsOrdersCountry: "国家",
        clientDetailsOrdersCompany: "公司", clientDetailsOrdersEdit: "编辑", clientDetailsOrdersCopy: "复制", clientDetailsOrdersDelete: "删除", clientDetailsOrdersInvoice: "发票", clientDetailsOrdersNewBtn: "新", clientDetailsOrdersDeliveredBtn: "已交付", clientDetailsOrdersCancelledBtn: "取消", clientDetailsOrdersShippedBtn: "已发货", clientDetailsOrdersInprogressBtn: "进行中", clientDetailsOrdersROwsPerPage: "每页行数",
        adminNotificationsAllNotifications: "所有通知", adminNotificationsNewOrders: "新订单", adminNotificationsUnRead: "未读", adminNotificationsRead: "读", adminNotificationsViewOrder: "查看订单", adminNotificationsDismiss: "解雇", adminAccountSetNewPhoto: "设置新照片", adminAccountName: "名称:", adminAccountEmail: "电子邮件:", adminAccountPhone: "电话:", adminAccountPassword: "密码", adminAccountUserName: "用户名", adminAccountCannotEdit: "无法编辑", adminAccountCancel: "取消", adminAccountSave: "保存", adminAccountOldPassword: "旧密码", adminAccountNewPassword: "新密码", adminAccountConfirmPassword: "确认密码", adminAccountFullName: "全名", adminAccountPhoneNumber: "电话号码",
        adminOrdersAllOrders: "所有订单", adminOrdersExportToCsv: "导出CSV", adminOrdersSearchOrders: "搜索订单", adminOrdersFilter: "过滤", adminOrdersSort: "分类", adminOrdersStatus: "状态", adminOrdersDate: "日期", adminOrdersPhone: "电话号码", adminOrdersTotal: "合计订单", adminOrdersNumber: "订单号", adminOrdersCountry: "国家", adminOrdersCompany: "公司", adminOrdersNewBtn: "新", adminOrdersDeliveredBtn: "已交付", adminOrdersCancelledBtn: "取消", adminOrdersInprogressBtn: "进行中", adminOrdersShippedBtn: "已发货", adminOrdersRowsPerPage: "每页行数", adminOrdersEdit: "编辑", adminOrdersCopy: "复制", adminOrdersDelete: "删除", adminOrdersInvoice: "发票", adminOrdersDetailsName: "名称:", adminOrdersDetailsAddress: "地址:", adminOrdersDetailsEmail: "电子邮件:", adminOrdersDetailsCompanyName: "公司名:", adminOrdersDetailsPhone: "电话:", adminOrdersDetailsWhatsapp: "Whatsapp的:", adminOrdersDetailsEdit: "编辑",
        adminOrdersDetailsInregardWithThisOrder: "关于此命令：", adminOrdersDetailsAnyIssue: "如果您对此问题有任何疑问。请联系管理员，我们将尽快解决问题。", adminOrdersDetailsSendAMessage: "发送消息", adminOrdersDetailsReasonForCancellation: "取消原因", adminOrdersDetailsWriteQuestion: "在这里写下您的问题",

        adminOrdersDetailsOrderNumber: "订单号:", adminOrdersDetailsStatus: "状态", adminOrdersDetailsTags: "标签", adminOrdersDetailsRating: "评分", adminOrdersDetailsDownload: "下载", adminOrdersDetailsView: "视图", adminOrdersDetailsRecived: "已收到", adminOrdersDetailsTransfer: "转让", adminOrdersDetailsPayNow: "现在付款", adminOrdersDetailsQuestion: "题", adminOrdersDetailsDone: "完成了", adminOrdersDetailsCancel: "取消", adminOrdersDetailsSendSms: "发送短信", adminOrdersDetailsWaiting: "等候", adminOrdersDetailsShipped: "已出货", adminOrdersDetailsInprogress: "进行中", adminOrdersDetailsReasonForCancelling: "取消原因*", adminOrdersDetailsMustProvideReason: "您必须提供取消理由", adminOrdersDetailsWriteYourText: "在这里写你的文字", adminOrdersDetailsIgnore: "忽视", adminOrdersDetailsSubmit: "提交",
        adminMessagesOpen: "打开", adminMessagesPending: "待定", adminMessagesResolve: "解决", adminMessagesClose: "关",
        adminMessagesHeading: "留言内容", adminMessagesSearch: "搜索", adminMessagesOrderMessages: "订单留言", adminMessagesCompanyContact: "公司联系方式", adminMessagesSubjects: "邮件主题", adminMessagesVIp: "贵宾", adminMessagesTransferTheChat: "转移聊天", adminMessagesMarkAsResolved: "标记为已解决", adminMessagesPlaceholder: "信息", adminMessagesSend: "发送", adminMessagesAll: "所有", adminMessagesFile: "文件", adminMessagesImages: "图片", adminMessagesOrders: "订单", adminMessagesQuickSearch: "快速搜索",
        adminMessagesCustomQuickResponse: "自定义快速响应", adminMessagesEdit: "编辑", adminMessagesCopy: "复制", adminMessagesDelete: "删除", adminMessagesSelectAll: "全选", adminMessagesDeselectAll: "全部取消选择", adminMessagesAdd: "加",
        adminDashboardHeading: "仪表板", adminDashboardUserAdmin: "管理员", adminDashboardKeerkiDashboard: "Keerki仪表板", adminDashboardClientsBtn: "客户群", adminDashboardOrdersBtn: "订单", adminDashboardContactBtn: "联系", adminDashboardNotificationsBtn: "通知事项", adminDashboardMessagesBtn: "留言内容", adminDashboardSettingsBtn: "设定值", adminDashboardLogoutBtn: "登出", adminDashBoardQuickSearch: "快速搜索", adminDashboardNotificationNumberOfNotifications: "您有3个新订单"
        , adminDashboardMyaccount: "我的帐户", adminDashboardAccountDetails: "帐户详细资料", adminDashboardChangePassword: "更改密码",
        adminSettingsSessionTimeOut: "会话超时", adminSettingsHours: "小时", adminSettingsMinutes: "分钟", adminSettingsAutoSendSms: "自动向客户发送短信提醒", adminSettingsDays: "天", adminSettingsDeactivateEmployee: "由于不活动而使员工迷惑", adminSettingsDeactivateUser: "短暂停用用户", adminSettingsRemoveUser: "从管理控制台中删除用户", adminSettingsEmployeeSetting: "员工设置", adminSettingsAddNewEmploy: "添加新员工", adminSettingsRegisterEmployee: "注册员工", adminSettingsName: "*名称", adminSettingsFirstName: "第一", adminSettingsLastName: "持续", adminSettingsUsername: "*用户名", adminSettingsUsernamePlaceholder: "用户名", adminSettingsPassword: "*密码", adminSettingsPassowordPlaceholder: "密码", adminSettingsConfirmPassword: "*确认密码", adminSettingsConfirmPasswordPlaceholder: "确认密码", adminSettingsEmail: "*电子邮件", adminSettingsEmailPlaceholder: "电子邮件", adminSettingsConfirmEmail: "*确认电邮地址", adminSettingsConfirmEmailAddressPlaceholder: "确认电邮地址",
        adminSettingsWorkPhone: "*工作压力", adminSettingsEnterPhone: "输入你的电话号码", adminSettingsUserTimeZone: "*用户时区", adminSettingsRole: "*角色", adminSettingsRoleAdmin: "管理员", adminSettingsCancel: "取消", adminSettingsSubmit: "提交", adminSettingsPassowordDescription: "密码必须与最后3个密码不同。密码必须至少包含8个字符，并且至少包含一个小写字母，大写字母，数字和特殊字符。", adminSettingsEditEmployee: "编辑员工", adminSettingsViewAllEmployees: "查看所有员工", adminSettingsEditEmployeeName: "*名称", adminSettingsEditEmployeeFirst: "第一", adminSettingsEditEmployeeLast: "持续", adminSettingsEditEmployeePassword: "密码:", adminSettingsEditEmployeeChangePassword: "更改密码", adminSettingsEditEmployeeChangePasswordDescription: "密码必须与最后3个密码不同。密码必须至少8个字符，并且至少包含一个:", adminSettingsLastLogin: "上次登录:", adminSettingsAccountCreated: "帐户已创建:", adminSettingsLastModified: "最后修改:", adminSettingsEditEmployeeEmail: "*电子邮件", adminSettingsEditEmployeeEmailPlaceHolder: "first.last@keerki.com", adminSettingsEditEmployeeWorkPone: "工作电话",
        adminSettingsEditEmployeeEnterPhone: "输入你的电话号码", adminSettingsEditEmployeeUserTimeZone: "*用户所在的时区", adminSettingsEditEmployeeRole: "*角色", adminSettingsEditEmployeeAccountStatus: "*帐户状态", adminSettingsEditEmployeeActve: "活性", adminSettingsEditEmployeeInactive: "不活跃", adminSettingNotifyAllEMployees: "通知所有员工", adminSettingClientSettings: "客户端设置", adminSettingAddNewClient: "添加新客户", adminSettingsNofifyAllClients: "通知所有客户", adminSettingsSendSms: " 发送短信给所有客户", adminSettingActivateClient: "激活客户账户", adminSettingSendEmail: "发送电子邮件给所有客户", adminSettingSuspendAccount: "暂停客户帐户", adminAllKeerkiEmployees: "所有Keerki员工", adminEmployeeDate: "日期", adminEmployeeStatus: "状态", adminEmployeesReplies: "回覆", AdminEmployeesEditAccountBtn: "编辑帐户", AdminEmployeesActive: "活性",
        adminSendSms: "发送短信", adminAllClientsSelected: "选择所有客户", adminAllClients: "所有客户", adminAllButExcluded: "除排除外", adminExclueName: "排除客户名称", adminVipClients: "VIP客户", adminNoUsers: "新用户-无订单", adminSearch: "搜索", adminEnterYourSms: "在这里输入您的短信", adminSend: "发送", adminSendEmail: "发电子邮件",
        editPermissionsRoles: "Roles", editPermissionsAddNewRoles: "新增角色", editPermissionsDuplicateSelectedRoles: "所选角色重复", editPermissionsDeleteSelectedRole: "删除选择角色", editPermissionsUsersAreMoved: "用户已移至默认角色并保持活动状态", editPermissionsTechEmployee: "技术员工", editPermissionsFinancialEmployee: "财务员工", editPermissionsCustomerService: "客户服务", editPermissionsPermissions: "权限", editPermissionsSensitive: "敏感", editPermissionsClientDetails: "客户资料", editPermissionsName: "名称", editPermissionsAddress: "地址", editPermissionsEmail: "电子邮件地址", editPermissionsPhone: "电话号码", editPermissionsBalance: "平衡", editPermissionsOrders: "订单", editPermissionsViewOrders: "查看订单", editPermissionsSendSms: "发送短信", editPermissionsDone: "完成了", editPermissionsTransfer: "转让", editPermissionsCancel: "取消", editPermissionsMessage: "留言内容", editPermissionsViewMessages: "查看讯息", editPermissionsReplyToMessages: "回复留言", editPermissionsNotifications: "通知事项", editPermissionsView: "视图", editPermissionsClientStatus: "客户状态", editPermissionsMoreDetails: "关于它的更多细节", editPermissionsAccountStatus: "帐户状态",
        editPermissionsEmployeesAndUserManagment: "员工和用户管理", editPermissionsCreateUser: "创建用户", editPermissionsManageUser: "管理用户", editPermissionsActivate: "激活，暂停和删除客户端用户或编辑其个人资料。", editPermissionsUsers: "用户数", editPermissionsMoveUsers: "将用户转移到该角色", editPermissionsMoveUser: "将用户移回默认角色", editPermissionsSearch: "搜索", editPermissionsFirstLast: "名和姓",
        notifyNotifyAll: "通知所有Keerki员工", notifyAllEmployeesSelected: "选择所有员工", notifySelectedExcludedEmployee: "选择排除的员工姓名", notifySearchDepartments: "搜索部门", notifySearchAndExcludeDepartments: "搜索和排除部门", notifyAllEmployees: "所有雇员", notifyAllButExclude: "除排除外", notifyDepartment: "部门", notifyExcludeDepartment: "排除部门", notifySearch: "搜索", notifyExcludedEmployeeName: "排除的员工姓名", notifySelectedExcludedDepartment: "选择排除的部门",
        notifySubject: "学科", notifySendToAllEmployees: "给所有员工留言", notifySend: "发送", permissionCanEdit: "可以查看", permissionsCanEdit: "可以编辑", permissionsHide: "隐藏"
        , sortNewToOld: "新到旧", sortOldToNew: "老到新", sortHighToLow: "高到低", sortLowToHigh: "从低到高", filterCancelled: "取消", filterWaiting: "等候", filterDelivered: "已交付", filterShipped: "已出货", filterInProgress: "进行中"
        , adminDashboardSearchByUserName: "按用户名搜索", adminDashboardSearchByEmail: "通过电子邮件搜索", adminDashboardSearchByDate: "按日期搜索", adminDashboardSearchByOrderNumber: "按订单号搜索", adminDashboardSearchByProductName: "按产品名称搜索", adminDashboardSearchByOrderStatus: "按订单状态搜索", adminDashboardSearchByClientAddress: "按客户地址搜索", adminDashboardSearchByClientPhone: "通过客户电话搜索", adminDashboardSearchByClientCompanyName: "按客户公司名称搜索", adminDashboardSearchByClientMemberShip: "按客户会员搜索", adminDashboardSearchByFirstLastName: "按名字和姓氏搜索"
        , issueHasBeenResolved: "问题已解决", requestRatingFromClient: "要求客户评分", adminVIPClientsSelected:"搜索VIP客户", adminNewUserSelected:"新用户-无订单"
        , adminMessageDeletePopup: "您确定要删除此回复吗"
        ,adminMessageDeleteNo:"没有",adminMessageDeleteYes:"是",signupLoginNow:"有帐号？现在登录",adminSettingsDepartment:"*部门",orderSearchByName:"依名称搜寻",orderSearchByDate:"按日期搜索",serviceHistory:"服务订单历史记录",quotesHistory:"报价单历史",shippingHistory:"装运订单历史记录",adminOrdersEnquiry:"您的询问在这里"
        ,intClient:"国际客户",localClient:"本地客户",
        Afghanistan:"阿富汗",Albania:"阿尔巴尼亚",Algeria:"阿尔及利亚",AmericanSamoa:"美属萨摩亚",Andorra:"安道尔",Angola:"安哥拉",Anguilla:"安圭拉岛",Antarctica:"南极洲",AntiguaBarbuda:"安提瓜和巴布达",Argentina:"阿根廷",Armenia:"亚美尼亚",Aruba:"阿鲁巴岛",Australia:"澳大利亚",Austria:"奥地利",Azerbaijan:"阿塞拜疆",Bahamas:"巴哈马",Bahrain:"巴林",Bangladesh:"孟加拉国",Barbados:"巴巴多斯",Belarus:"白俄罗斯",Belgium:"比利时",Belize:"伯利兹",Benin:"贝宁",Bermuda:"百慕大",Bhutan:"不丹",Bolivia:"玻利维亚",Bonaire:"博内尔",Bosnia:"波斯尼亚和黑塞哥维那",Botswana:"博茨瓦纳",Bouvet:"布维",Brazil:"巴西",BritishIndian:"英属印度人",Brunei:"文莱",Bulgaria:"保加利亚",Burkina:"布基纳",
        BurundiThe:"布隆迪",Burundi:"布隆迪",Cambodia:"柬埔寨",Cameroon:"喀麦隆",Canada:"加拿大",Cayman:"鳄鱼",Africa:"非洲",Chad:"乍得",Chile:"智利",China:"中国",Christmas:"圣诞",Cocos:"可可",Colombia:"哥伦比亚",Comoros:"科摩罗",Congo:"刚果",CongoThe:"刚果",Cook:"厨师",Costa:"哥斯达黎加",Croatia:"克罗地亚",Cuba:"古巴",Curaçao:"库拉索",Cyprus:"塞浦路斯",Czechia:"捷克语",
        Côte:"埃武拉山",CôteDE:"埃武拉山",Djibouti:"吉布地",Dominica:"多米尼加",Dominican:"多米尼加",Egypt:"埃及",Salvador:"萨尔瓦多",Equatorial:"赤道",Eritrea:"厄立特里亚",Estonia:"爱沙尼亚",Eswatini:"埃斯瓦蒂尼",Ethiopia:"埃塞俄比亚",Falkland:"福克兰",Faroe:"法罗",Fiji:"斐济"
        ,resetForm:"重置表格？",updateData:"更新数据？",
        notifyAllClients:"通知所有Keerki客户",notifyAllClientsSelected:"通知所有客户",notifyNotifyAllClients:"所有客户",notifySelectedExcludedClient:"选定的排除的客户名称",notifyExcludedClientName:"排除的客户名称",notifySendToAllClients:"向所有客户发送消息",adminSettingsBalance:"平衡",adminSettingsBalancePlaceHolder:"当前余额",adminSettingsAddress:"*地址",adminSettingsAddressPlaceHolder:"地址",adminSettingsPhone:"电话",adminSettingsCompanyPlaceHolder:"公司名",adminSettingsCompany:"*公司",adminSettingsMemberShip:"*会员",adminSettingsRegisterClient:"注册客户",
        adminOrdersWaitBtn:"等待",adminOrdersPaidBtn:"有薪酬的",adminOrdersDoneBtn:"完毕",filterNew:"新的",filterPaid:"有薪酬的",filterDone:"完毕",filterInactive:"不活跃", filterActive:"积极的",typing: "在打字...",
        adminLoginAsClient:"登錄為",adminRemindLoginEmail:"發送客戶登錄電子郵件",AdminEmployeeNeverLogedIn:"絕不"

    }
}
export default new VueI18n({ locale: localStorage.currentLanguage || 'ar', fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar', messages, });
